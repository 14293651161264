import React, { useCallback, useMemo, useRef, useState } from "react";
import SettingsModule, { SettingsModuleSection } from "./SettingsModule";

import {
	Asset,
	Button,
	ExternalLinkIcon,
	FONT_COLOR,
	FONT_SIZE,
	MailIcon,
	PaddedContainer,
	SPACING,
	TabSwitcher,
	Text,
	copyClipboard,
	LabeledInput,
	INPUT_TYPE,
} from "@disco/disco_core";
import useIdentity from "../../hooks/useIdentity";
import isShopifyShop from "../../utils/isShopifyShop";
import windowRedirect from "../../utils/windowRedirect";
import ExtensionSetupInstructions from "../ExtensionSetupInstructions";

import "./integrations-module.css";

const MAILTO_REPLACEABLE_VARS = {
	// MAILTO_URL GENERATED USING: https://mailtolinkgenerator.com/
	PUBLISHER_ID: "TARGET_PUBLISHER_ID",
	REDIRECT_URL: "TARGET_REDIRECT_URL",
};

export const SHOPIFY_EXTENSION_INTEGRATION_REDIRECT_URL =
	"https://docs.disconetwork.com/docs/introduction";

const TEXT_MAPPER = {
	INTEGRATED: {
		getContext: function () {
			return this;
		},

		HEADING: "Setting up DiscoFeed on your store",
		DESCRIPTION:
			"Stores using Shopify Checkout Extensions must complete additional steps in Shopify to set up DiscoFeed. If your store doesn't use Checkout Extensions, you can start publishing and advertising right away.",
		MAILTO_URL: `mailto:developer@example.com?subject=DiscoFeed%20WebSDK%20Integration%20Info&body=Hi%20%5BDeveloper's%20Name%5D%2C%20%20%0A%0ASince%20our%20Shopify%20store%20uses%20Checkout%20Extensions%2C%20we%20need%20to%20complete%20additional%20steps%20to%20set%20up%20DiscoFeed.%0A%20%20%0APlease%20follow%20this%20guide%20to%20configure%20it%3A%20TARGET_REDIRECT_URL%0A%0ALet%20me%20know%20if%20you%20need%20anything%20else.%20%20%0A%0AThanks%2C%20%0A%5BYour%20Name%5D`,
		REDIRECT_URL: SHOPIFY_EXTENSION_INTEGRATION_REDIRECT_URL,
	},
	NON_INTEGRATED: {
		getContext: function (isPublishing = false) {
			return this?.[isPublishing ? "PUBLISH" : "ADVERTISE"];
		},

		PUBLISH: {
			HEADING: "Publish DiscoFeed to your store",
			DESCRIPTION:
				"Follow our setup guide to integrate the Disco WebSDK. Use the Publisher ID below to ensure the correct setup.",
			MAILTO_URL: `mailto:developer@example.com?subject=DiscoFeed%20WebSDK%20Integration%20Info&body=Hi%20%5BDeveloper's%20Name%5D%2C%20%20%0A%0AI%20need%20your%20help%20to%20integrate%20the%20DiscoFeed%20WebSDK%20for%20our%20store.%20Here%E2%80%99s%20the%20essential%20information%20you%E2%80%99ll%20need%3A%0A%20%20%0APublisher%20ID%20%3D%3E%20TARGET_PUBLISHER_ID%0A%0AIntegration%20Code%20-%0AAvailable%20in%20our%20setup%20guide%20at%20the%20following%20link%3A%20TARGET_REDIRECT_URL%0A%0AThe%20setup%20guide%20provides%20the%20complete%20integration%20code%20and%20step-by-step%20instructions.%20Let%20me%20know%20if%20you%20need%20anything%20else!%0A%0AThanks%2C%20%0A%5BYour%20Name%5D`,
			REDIRECT_URL:
				"https://docs.disconetwork.com/docs/publish-discofeed",
		},
		ADVERTISE: {
			HEADING: "Advertise your products on other stores",
			DESCRIPTION:
				"Follow our setup guide to seamlessly integrate your data",
			MAILTO_URL: `mailto:developer@example.com?subject=DiscoFeed%20WebSDK%20Integration%20Info&body=Hi%20%5BDeveloper's%20Name%5D%2C%20%20%0A%0AI%20need%20your%20help%20to%20set%20up%20advertising%20for%20our%20products%20on%20DiscoFeed.%20Please%20follow%20the%20steps%20in%20the%20setup%20guide%20below%20to%20complete%20the%20integration%3A%0A%20%20%0ATARGET_REDIRECT_URL%0A%0ALet%20me%20know%20if%20you%20need%20anything%20else.%20%20%0A%0AThanks%2C%20%0A%5BYour%20Name%5D`,
			REDIRECT_URL:
				"https://docs.disconetwork.com/docs/advertiser-discofeed/advertise-discofeed",
		},
	},
};

const IntegrationModule = () => {
	const { user } = useIdentity();
	const [animateCopyButton, setAnimateCopyButton] = useState(false);
	const {
		publisher: { remote_id: publisherId },
	} = user;
	const isIntegrated = isShopifyShop(user);

	const [activeTab, setActiveTab] = useState(0);
	const isOnPublishTab = activeTab === 0;
	const tabData = useMemo(
		() => [
			{
				text: "Publish",
			},
			{
				text: "Advertise",
			},
		],
		[]
	);

	const {
		HEADING: heading,
		DESCRIPTION: description,
		MAILTO_URL: mailtoUrl,
		REDIRECT_URL: redirectUrl,
	} = TEXT_MAPPER[isIntegrated ? "INTEGRATED" : "NON_INTEGRATED"].getContext(
		isOnPublishTab
	);

	const getParsedMailtoUrl = () => {
		return mailtoUrl
			.replace(MAILTO_REPLACEABLE_VARS.PUBLISHER_ID, publisherId)
			.replace(MAILTO_REPLACEABLE_VARS.REDIRECT_URL, redirectUrl);
	};

	const handleTabChange = useCallback(
		({ target: { value } }) => setActiveTab(value),
		[]
	);
	const headerChildren = useMemo(
		() =>
			isIntegrated ? null : (
				<PaddedContainer
					className="integration-module-header-children"
					flexContent
				>
					<TabSwitcher
						options={tabData}
						curved
						dynamicWidth
						value={activeTab}
						onChange={handleTabChange}
						resizeOnMount
						small
					/>
				</PaddedContainer>
			),
		[activeTab, tabData, handleTabChange, isIntegrated]
	);

	const timerRef = useRef(null);
	const handleRemoteIdCopy = () => {
		if (timerRef.current) clearTimeout(timerRef.current);
		setAnimateCopyButton(true);
		copyClipboard(publisherId);
		timerRef.current = setTimeout(() => {
			timerRef.current = null;
			setAnimateCopyButton(false);
		}, 1500);
	};

	const handleSetupRedirect = () => windowRedirect(redirectUrl);
	const handleEmailRedirect = () => {
		window.location.href = getParsedMailtoUrl();
	};

	return (
		<>
			<SettingsModule>
				<SettingsModuleSection
					title={heading}
					tightTop
					headerChildren={headerChildren}
				>
					<Text
						marginTop={SPACING.REGULAR}
						size={FONT_SIZE.LG_BODY}
						color={FONT_COLOR.MID}
						style={{ maxWidth: "850px" }}
					>
						{description}
					</Text>
					{!isIntegrated && isOnPublishTab && (
						<PaddedContainer
							vPadding={SPACING.REGULAR}
							className="integration-module-remote-id-display"
						>
							<LabeledInput
								label="Publisher ID"
								inputType={INPUT_TYPE.ACTION}
								value={publisherId}
								buttonProps={{
									children: animateCopyButton
										? "Copied  ✅"
										: "Copy",
									onClick: handleRemoteIdCopy,
								}}
								disabled
							></LabeledInput>
						</PaddedContainer>
					)}
					<PaddedContainer marginTop={SPACING.REGULAR} flexContent>
						<Button flexContent onClick={handleSetupRedirect}>
							View Setup Guide{" "}
							<Asset marginLeft={SPACING.TINY} size={20}>
								<ExternalLinkIcon />
							</Asset>
						</Button>
						<Button
							secondary
							marginLeft={SPACING.SMALL}
							onClick={handleEmailRedirect}
						>
							Share with developer{" "}
							<Asset size={20} marginLeft={SPACING.TINY}>
								<MailIcon />
							</Asset>
						</Button>
					</PaddedContainer>
				</SettingsModuleSection>
			</SettingsModule>
			{isIntegrated && (
				<ExtensionSetupInstructions marginTop={SPACING.REGULAR} />
			)}
		</>
	);
};
export default IntegrationModule;
