import React, { memo, useMemo, useEffect, useCallback } from "react";
import {
	BRAND_INFO_URL,
	FETCH_EMAILS_CATEGORY_WHITELIST_URL,
	SAVE_EMAILS_BLACKLIST_URL,
	SAVE_EMAILS_CATEGORY_WHITELIST_URL,
	SAVE_EMAILS_SUB_CATEGORY_BLACKLIST_URL,
	TOGGLE_EMAILS_URL,
	TOGGLE_EMAIL_UPSELL_URL,
	FETCH_EMAILS_PREVIEW_URL,
	FETCH_TAGGED_SUB_CATEGORIES_BLACKLIST_URL,
	FETCH_BRANDS_URL,
	FETCH_EMAIL_BLACKLISTED_BRANDS_URL,
} from "../../conf";
import useInReview from "../../hooks/useInReview";
import BrandOptOutModalSelector from "../BrandOptOutModalSelector";
import CategoryOptInModal from "../CategoryOptInModalSelector";
import SubCategoryOptOutModalSelector from "../SubCategoryOptOutModalSelector";
import ToggleGroup from "../ToggleGroup";
import useResource from "../../hooks/useResource";
import {
	FormStatus,
	GenericProductCard,
	InlineHelperTooltip,
	Loader,
} from "../CoreUI";

import "./emails-core-ui.css";

export const EmailsSettingToggles = memo(
	({ user, setUser, inTutorial = false, ...props }) => {
		const handleTransactionEmailsToggle = useCallback(
			(_, data) => {
				setUser((user) => ({
					...user,
					publisher: {
						...user.publisher,
						rewards: data?.brand?.rewards,
					},
				}));
			},
			[setUser]
		);

		const toggles = useMemo(() => {
			const toggles = [
				{
					remoteKey: "shopify_emails_enabled",
					url: TOGGLE_EMAILS_URL,
					label: `Add ${
						user.isNewIa ? "DiscoFeed" : "Disco carousel"
					} to your emails`,
					subToggles: [
						{
							label: "Cross-sells are enabled",
							dummyToggle: true,
							tooltip: {
								heading: "Cross-sell on emails",
								text: "Display products from partner brand sites from across the Disco network.",
							},
						},
					],
				},
			];

			if (inTutorial) {
				return toggles;
			}

			toggles.push({
				url: BRAND_INFO_URL,
				remoteKey: "transactional_emails",
				label: "Get featured in Disco Cash emails",
				onEdit: handleTransactionEmailsToggle,
			});

			return toggles;
		}, [inTutorial, handleTransactionEmailsToggle, user.isNewIa]);

		return (
			<ToggleGroup
				toggles={toggles}
				setUser={setUser}
				user={user}
				{...props}
			/>
		);
	}
);

export const EmailsCategoryOptInModal = memo(({ user, setUser, ...props }) => {
	const { inReview: editMode } = useInReview(user);
	const customStepProps = useMemo(
		() => [
			{
				remoteKey: "email_category_whitelist",
				fetchUrl: FETCH_EMAILS_CATEGORY_WHITELIST_URL,
				saveUrl: SAVE_EMAILS_CATEGORY_WHITELIST_URL,
				mainFooter: () => (
					<>
						Shops in these categories will appear in your emails.
						<br />
						<br />
						Additionally, your products will appear in the emails of
						shops in these categories, assuming they have opted in
						to your shop's categories and have not explicitly opted
						out of your shop or your subcategories.
					</>
				),
			},
		],
		[]
	);

	return (
		<CategoryOptInModal
			user={user}
			setUser={setUser}
			customStepProps={customStepProps}
			editMode={editMode}
			className="emails-modal-select-input"
			{...props}
		/>
	);
});

export const EmailsSubCategoryOptOutModalSelector = memo(
	({ user, setUser, ...props }) => {
		const { inReview: editMode } = useInReview(user);
		const customStepProps = useMemo(
			() => [
				{
					dependantStates: {
						email_blacklist: "",
					},
					remoteKey: "email_subcategory_blacklist",
					saveUrl: SAVE_EMAILS_SUB_CATEGORY_BLACKLIST_URL,
					inputWrapperProps: { children: null },
					mainFooter: ({ value }) => {
						if (!Array.isArray(value)) {
							return null;
						}
						const num = value.length;
						return (
							<>
								{num > 0 && (
									<div
										style={{
											color: "var(--text)",
											fontWeight: 400,
										}}
									>
										Important — You are excluding{" "}
										<span style={{ color: "var(--red)" }}>
											{" "}
											{num}{" "}
											{num > 1
												? "subcategories"
												: "subcategory"}
										</span>
									</div>
								)}
								<br />
								You’ve chosen to exclude these subcategories.
								This means that any shop in these subcategories
								will never appear in your emails.
							</>
						);
					},
				},
			],
			[]
		);
		return (
			<SubCategoryOptOutModalSelector
				user={user}
				setUser={setUser}
				customStepProps={customStepProps}
				editMode={editMode}
				className="emails-modal-select-input"
				{...props}
			/>
		);
	}
);

export const EmailsBrandOptOutModalSelector = memo(
	({ user, setUser, ...props }) => {
		const { inReview: editMode } = useInReview(user);
		const customStepProps = useMemo(
			() => [
				{
					remoteKey: "email_blacklist",
					fetchValueUrl: FETCH_EMAIL_BLACKLISTED_BRANDS_URL,
					saveUrl: SAVE_EMAILS_BLACKLIST_URL,
					mainFooter: () => (
						<>
							Shops selected above will not appear in your emails.
							<br />
							<br />
							Note: this exclusion is one-way. You may still
							appear in these shops' emails.
						</>
					),
				},
			],
			[]
		);
		return (
			<BrandOptOutModalSelector
				user={user}
				setUser={setUser}
				editMode={editMode}
				customStepProps={customStepProps}
				className="emails-modal-select-input"
				{...props}
			/>
		);
	}
);

export const EmailsProductsPreview = memo(({ user, refresh, setRefresh }) => {
	const [{ loading, data, error }, updatePreview] = useResource({
		url: FETCH_EMAILS_PREVIEW_URL(user.publisher.remote_id).replace(
			"/api",
			""
		),
		method: "POST",
	});

	useEffect(() => {
		if (!refresh) {
			return;
		}
		updatePreview();
		if (typeof setRefresh !== "function") {
			return;
		}
		setRefresh(false);
	}, [refresh, updatePreview, setRefresh]);

	return (
		<section className="emails-products-preview-container">
			<h1>DiscoFeed Preview</h1>
			<section className="emails-products-preview">
				{loading && <Loader />}
				{error && (
					<FormStatus>
						Whoops! There was an error fetching your preview at the
						moment. Please try again later
					</FormStatus>
				)}

				{data && (
					<>
						{Array.isArray(data.products) &&
						data.products.length > 3 ? (
							data.products.map((product) => (
								<GenericProductCard
									product={product}
									key={product.remote_id}
								/>
							))
						) : (
							<InlineHelperTooltip open rounded>
								It looks like you have excluded too many
								subcategories and shops. Try adjusting your
								preferences to view a more optimized version of
								your DiscoFeed on Email
							</InlineHelperTooltip>
						)}
					</>
				)}
			</section>
		</section>
	);
});
