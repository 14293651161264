import React, {
	memo,
	useCallback,
	useState,
	forwardRef,
	useMemo,
	ReactElement,
	Fragment,
	ChangeEvent,
	FocusEvent,
	Dispatch,
	SetStateAction,
	ComponentPropsWithoutRef,
} from "react";
import useTheme from "../../hooks/useTheme";
import genPackageClassName from "../../utils/genPackageClassName";
import {
	StandardComponentWithChildren,
	GenericRef,
	ObjectValuesAsTypes,
	Extend,
} from "../../shared.types";
import {
	PaddedContainer,
	SPACING,
	PaddedContainerProps,
} from "../PaddedContainer";

import "./input.css";
import { Button } from "../Button";
import { Toggle } from "../Toggle";
import { FONT_COLOR, FONT_SIZE, Text } from "../Typography";
import { ButtonProps } from "../Button";
import { Label } from "../Label";
import { TextProps } from "../Typography";
import { NativeToggleProps } from "../Toggle";

export const INPUT_EL_POSITION = {
	LEFT: "left",
	RIGHT: "right",
} as const;

export type InputPositionType = ObjectValuesAsTypes<typeof INPUT_EL_POSITION>;

export type NativeInputContainerProps = {
	className?: string;
	readOnly?: boolean;
	disabled?: boolean;
	expandable?: boolean;
	flexWrap?: boolean;
	vPadded?: boolean;
};

export type InputContainerProps = Extend<
	PaddedContainerProps,
	NativeInputContainerProps
>;
type InputContainerComponent =
	StandardComponentWithChildren<InputContainerProps>;
export const InputContainer: InputContainerComponent = memo(
	forwardRef(
		(
			{
				children,
				className = "",
				readOnly = false,
				disabled = false,
				expandable = false,
				flexWrap = false,
				vPadded = false,
				...rest
			},
			ref?: GenericRef
		) => {
			return (
				<PaddedContainer
					className={genPackageClassName({
						base: "inp-container",
						conditionals: {
							"inp-container-read-only": readOnly,
							"inp-container-disabled": disabled,
							"inp-container-expandable": expandable,
							"inp-container-flex-wrap": flexWrap,
							"inp-container-v-padded": vPadded,
						},
						additional: className,
					})}
					ref={ref}
					{...rest}
				>
					{children}
				</PaddedContainer>
			);
		}
	)
);

export type NativeTextInputProps = {
	className?: string;
	containerProps?: InputContainerProps;
	type?: string;
	value?: string;
	textarea?: boolean;
	icon?: ReactElement;
	iconPosition?: InputPositionType;
	maxLength?: number;
	softMaxLength?: boolean;
	hideCounter?: boolean;
	childrenPosition?: InputPositionType;
	readOnly?: boolean;
	disabled?: boolean;
	onFocus?: (
		event?: FocusEvent<HTMLTextAreaElement | HTMLInputElement>
	) => void;
	onBlur?: (
		event?: FocusEvent<HTMLTextAreaElement | HTMLInputElement>
	) => void;
	onChange?: (
		event?: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
	) => void;
};

export type TextInputProps = Extend<
	ComponentPropsWithoutRef<"input" | "textarea">,
	NativeTextInputProps
>;
type TextInputComponent = StandardComponentWithChildren<
	TextInputProps & {
		ref?: GenericRef;
	}
>;
export const TextInput: TextInputComponent = memo(
	forwardRef(
		(
			{
				className = "",
				containerProps,

				type = "text",
				value,
				textarea = false,
				icon,
				iconPosition = INPUT_EL_POSITION.LEFT,
				maxLength,
				softMaxLength = false,
				hideCounter = false,

				children,
				childrenPosition = INPUT_EL_POSITION.RIGHT,
				readOnly = false,
				disabled = false,

				onFocus,
				onBlur,
				onChange,
				...props
			},
			ref?: GenericRef
		) => {
			const [showDateType, setShowDateType] = useState(false);
			const { theme } = useTheme();

			const handleFocus = useCallback(
				(e: FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => {
					if (type === "date") {
						setShowDateType(true);
					}
					if (typeof onFocus !== "function") {
						return;
					}
					onFocus(e);
				},
				[type, onFocus]
			);

			const handleBlur = useCallback(
				(e: FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => {
					if (type === "date") {
						setShowDateType(false);
					}
					if (typeof onBlur !== "function") {
						return;
					}
					onBlur(e);
				},
				[type, onBlur]
			);

			const inpProps = useMemo(
				() => ({
					ref,
					type:
						type === "date"
							? !showDateType && !value
								? "text"
								: "date"
							: type,
					className: genPackageClassName({
						theme,
						base: "text-inp",
						conditionals: { "text-inp-with-value": value !== null },
					}),
					maxLength: softMaxLength ? 999 : maxLength,
					readOnly,
					disabled,
					onFocus: handleFocus,
					onBlur: handleBlur,
					value,
					onChange,
					...props,
				}),
				[
					ref,
					disabled,
					type,
					showDateType,
					value,
					theme,
					maxLength,
					softMaxLength,
					readOnly,
					handleFocus,
					handleBlur,
					onChange,
					props,
				]
			);

			const renderIcon = useMemo(
				() =>
					icon ? (
						<span
							className={genPackageClassName({
								theme,
								base: "text-inp-icon",
							})}
						>
							{icon}
						</span>
					) : null,
				[theme, icon]
			);
			const renderChildren = useMemo(
				() =>
					children ? (
						<PaddedContainer
							className={genPackageClassName({
								base: "text-inp-children",
							})}
						>
							{children}
						</PaddedContainer>
					) : null,
				[children]
			);

			return (
				<InputContainer
					readOnly={readOnly}
					disabled={disabled}
					{...containerProps}
					className={genPackageClassName({
						base: "text-inp-container",
						conditionals: {
							"text-inp-container-has-counter":
								maxLength != null ? true : false,
							[`text-inp-container-has-icon-${
								iconPosition || INPUT_EL_POSITION.LEFT
							}`]: icon != null ? true : false,
							"text-inp-container-textarea": textarea || false,
							[`text-inp-container-has-children-${
								childrenPosition || INPUT_EL_POSITION.RIGHT
							}`]: children != null ? true : false,
						},
						additional:
							className || containerProps?.className || "",
					})}
				>
					{iconPosition === INPUT_EL_POSITION.LEFT && renderIcon}
					{childrenPosition === INPUT_EL_POSITION.LEFT &&
						renderChildren}
					{textarea ? (
						<textarea
							{...(inpProps as ComponentPropsWithoutRef<"textarea">)}
						/>
					) : (
						<input
							{...(inpProps as ComponentPropsWithoutRef<"input">)}
						/>
					)}
					{maxLength && !hideCounter && (
						<div
							className={genPackageClassName({
								theme,
								base: "text-inp-counter text-label",
							})}
						>
							<span
								className={genPackageClassName({
									base: "text-inp-container-counter",
									conditionals: {
										"text-inp-container-counter-warning":
											(value?.length || 0) > maxLength
												? true
												: false,
									},
								})}
							>
								{value?.length || 0}
							</span>{" "}
							/ {maxLength}
						</div>
					)}
					{iconPosition === INPUT_EL_POSITION.RIGHT && renderIcon}
					{childrenPosition === INPUT_EL_POSITION.RIGHT &&
						renderChildren}
				</InputContainer>
			);
		}
	)
);

export type NativeActionInputProps = {
	className?: string;
	buttonProps?: ButtonProps;
};

export type ActionInputProps = Extend<TextInputProps, NativeActionInputProps>;
type ActionInputComponent = StandardComponentWithChildren<ActionInputProps>;
export const ActionInput: ActionInputComponent = memo(
	forwardRef(({ buttonProps, className = "", ...rest }, ref: GenericRef) => {
		return (
			<TextInput
				ref={ref}
				className={genPackageClassName({
					base: "action-inp",
					additional: className,
				})}
				{...rest}
			>
				<Button rounded={false} {...buttonProps}></Button>
			</TextInput>
		);
	})
);

export type NativeToggleInputProps = {
	className?: string;
	toggle?: boolean;
	handleToggle?: Dispatch<SetStateAction<boolean>>;
	inverseToggle?: boolean;
	disabled?: boolean;
	hideFeedback?: boolean;
	feedback?: string;
	feedbackProps?: TextProps;
	inline?: boolean;
};

export type ToggleInputProps = Extend<
	NativeToggleProps,
	NativeToggleInputProps
>;
type ToggleInputComponent = StandardComponentWithChildren<ToggleInputProps>;
export const ToggleInput: ToggleInputComponent = memo(
	forwardRef(
		(
			{
				toggle = false,
				handleToggle,
				inverseToggle = false,
				disabled = false,
				hideFeedback = false,
				feedback = "",
				feedbackProps,
				className = "",
				inline = false,
				...props
			},
			ref: GenericRef
		) => {
			const parsedToggleValue = useMemo(
				() => (inverseToggle ? !toggle : toggle),
				[toggle, inverseToggle]
			);

			const parsedFeedback = useMemo(
				() =>
					feedback
						? feedback
						: parsedToggleValue
						? "Enabled"
						: "Disabled",
				[feedback, parsedToggleValue]
			);

			const renderToggle = useMemo(
				() => (
					<Toggle
						className={genPackageClassName({ base: "toggle-inp" })}
						toggle={toggle}
						setToggle={handleToggle}
						inverse={inverseToggle}
						disabled={disabled}
						{...props}
					/>
				),
				[toggle, handleToggle, inverseToggle, disabled, props]
			);

			if (inline) {
				return (
					<PaddedContainer
						ref={ref}
						className={genPackageClassName({
							base: "toggle-inp-container-inline",
							additional: className,
						})}
					>
						{!hideFeedback && (
							<Label {...feedbackProps}>{parsedFeedback}</Label>
						)}
						{renderToggle}
					</PaddedContainer>
				);
			}

			return (
				<InputContainer
					className={genPackageClassName({
						base: "toggle-inp-container",
						additional: className,
					})}
					disabled={disabled}
					ref={ref}
				>
					{renderToggle}
					{!hideFeedback && (
						<Text
							inline
							className={genPackageClassName({
								base: "toggle-inp-feedback-text",
							})}
							hPadding={SPACING.REGULAR}
							color={FONT_COLOR.DARK}
							size={FONT_SIZE.BODY}
							{...feedbackProps}
						>
							{parsedFeedback}
						</Text>
					)}
				</InputContainer>
			);
		}
	)
);

export const INPUT_TYPE = {
	TEXT: "TEXT",
	TOGGLE: "TOGGLE",
	RADIO: "RADIO",
	ACTION: "ACTION",
};

export type InputType = ObjectValuesAsTypes<typeof INPUT_TYPE>;

export type NativeInputProps = {
	inputType?: InputType;
};

export type InputProps = Extend<
	TextInputProps | ToggleInputProps,
	NativeInputProps
>;
type InputComponent = (
	props: InputProps & {
		ref?: GenericRef;
	}
) => React.ReactNode | null;
export const Input: InputComponent = memo(
	forwardRef(
		({ inputType = INPUT_TYPE.TEXT, ...props }, ref?: GenericRef) => {
			const InputElement = useMemo(() => {
				let InputElement = null;

				switch (inputType) {
					case INPUT_TYPE.TEXT:
						InputElement = TextInput;
						break;
					case INPUT_TYPE.TOGGLE:
						InputElement = ToggleInput;
						break;
					case INPUT_TYPE.RADIO:
						InputElement = Fragment;
						break;
					case INPUT_TYPE.ACTION:
						InputElement = ActionInput;
						break;
					default:
						InputElement = Fragment;
						break;
				}
				return InputElement;
			}, [inputType]);

			return <InputElement ref={ref} {...props} />;
		}
	)
);
