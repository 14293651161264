import shopify from "../img/icons/shopify-big.png";
import other from "../img/icons/other-big.png";
import salesforce from "../img/icons/salesforce-big.png";
import { PLATFORM_IDS } from "../conf";

const getPlatformList = () => {
	return [
		{ img: shopify, name: "Shopify", id: PLATFORM_IDS.shopify },
		{
			img: salesforce,
			name: "Salesforce",
			id: PLATFORM_IDS.salesforce,
		},
		{
			img: other,
			name: "Other",
			id: PLATFORM_IDS.other,
		},
	];
};

export default getPlatformList;
