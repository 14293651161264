import "@disco/disco_core/dist/index.css";
import "cropperjs/dist/cropper.css";
import { AnimatePresence, motion } from "framer-motion";
import React, {
	createContext,
	forwardRef,
	memo,
	useCallback,
	useEffect,
	useLayoutEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import { ChromePicker } from "react-color";
import Cropper from "react-cropper";
import { IconContext } from "react-icons";
import {
	BiMenu,
	BiCollapse as CollapseIcon,
	BiExpand as ExpandIcon,
} from "react-icons/bi";
import { BsCheckAll, BsSearch } from "react-icons/bs";
import {
	FiCheck,
	FiCheckCircle,
	FiChevronLeft,
	FiMenu,
	FiUpload,
} from "react-icons/fi";
import {
	IoIosTimer as ClockIcon,
	IoMdClose as CrossIcon,
	IoMdCloseCircle as CrossIconFilled,
	IoMdInformationCircleOutline as InfoIcon,
	IoMdRefresh as RefreshIcon,
} from "react-icons/io";
import { RiCloseLine } from "react-icons/ri";
import { useNavigate, useLocation } from "react-router-dom";
import ReactSlider from "react-slider";
import {
	DEFAULT_PRODUCT_IMG,
	DEFAULT_UNIVERSAL_PREVIEW_PROPS,
	FACEBOOK_APP_URL,
	MAX_IMG_SIZE,
	NAV_ERROR_MESSAGE,
	PREVIEW_TYPES_IDX,
	SHOPIFY_PAYMENT_URL,
	SLACK_URL,
	WIDGET_URL,
} from "../../conf";
import useResource from "../../hooks/useResource";
import colorLogo from "../../img/disco-logo-color.svg";
import slackIcon from "../../img/slack.svg";
import anim from "../../utils/anim";
import parseError from "../../utils/parseError";

import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import {
	FaChevronCircleLeft as BackFilledIcon,
	FaCheck,
	FaShopify,
	FaChevronCircleRight as ForwardFilledIcon,
} from "react-icons/fa";
import ImageModal from "../modals/ImageModal";

import {
	AiFillCheckCircle,
	AiOutlineEye as EyePreviewIcon,
	AiFillInfoCircle as InformationIcon,
	AiOutlinePlusCircle as PlusCircle,
	AiOutlinePlus as PlusIcon,
	AiOutlineQuestionCircle as QuestionIcon,
	AiFillQuestionCircle as QuestionIconFilled,
} from "react-icons/ai";
import { HiOutlineChevronDown as DownIcon, HiMinus } from "react-icons/hi";
import { VscListSelection } from "react-icons/vsc";
import SideBar from "../SideBar";
import EditSelectedModal from "../modals/EditSelectedModal";
import Modal from "../modals/Modal";

import {
	MdCheck,
	MdChevronLeft,
	MdChevronRight,
	MdClose,
	MdEdit,
} from "react-icons/md";
import checkIcon from "../../img/check-3d.png";
import { imageTypes, isImage } from "../../utils/fileTypes.js";
import SetView from "../SetView";
import "./core-ui.css";

import FacebookOfficialLight from "../../img/facebookOfficialLight.png";
import iPhoneFullFrame from "../../img/iphone-full-3.png";
import iPhoneFrame from "../../img/iphone.png";
import PHONE_FRAME_IMG from "../../img/phone-frame.png";
import comingSoon from "../../img/onboarding/coming-soon.png";

import useModal from "../../hooks/useModal";
import CrossSellIconImg from "../../img/crossSell.png";
import UpSellIconImg from "../../img/upSell.png";
import isShopifyShop from "../../utils/isShopifyShop";
// import CardModal from "../modals/CardModal";
import { ImPencil, ImStack } from "react-icons/im";
import showAlertBar from "../../utils/showAlertBar";
import showBodBanner from "../../utils/showBodBanner";
import AlertBar from "../AlertBar";

import {
	PLATFORM_BUILD_YOUR_PROFILE_CLICK_ON_CHANGE_LOGO,
	PLATFORM_PRIVACY_CLICK_ON_COPY,
	PLATFORM_REQUEST_MOBILE_CLICK,
} from "../../events";
import useDimensions from "../../hooks/useDimensions";
import useIdentity from "../../hooks/useIdentity";
import usePing, { PING_TYPES } from "../../hooks/usePing";
import usePreview from "../../hooks/usePreview";
import useTrack from "../../hooks/useTrack";
import calloutImage from "../../img/callout.png";
import MobilePage from "../../pages/MobilePage";
import extractFilenameFromReqHeaders from "../../utils/extractFilenameFromReqHeaders";
import getPreviewOptions from "../../utils/getPreviewOptions";
import isFunction from "../../utils/isFunction";
import { userUpdateZendesk } from "../../utils/zendesk";
import CompletionScreen from "../CompletionScreen";
import ScriptSetup from "../ScriptSetup";
import ModalSelect from "../modals/ModalSelect";
import ModalSheetHeader from "../modals/ModalSheetHeader";

import {
	Accordion as DiscoAccordion,
	Button as DiscoButton,
	Input as DiscoInput,
	Label as DiscoLabel,
	LabeledInput as DiscoLabeledInput,
	Radio as DiscoRadio,
	Select as DiscoSelect,
	FONT_COLOR,
	FONT_SIZE,
	InformationTooltip,
	InputContainer,
	PaddedContainer,
	SPACING,
	StatusLabel,
	TAG_TYPE,
	TOOLTIP_TYPE,
	Tag,
	Text,
	genClassName,
} from "@disco/disco_core";
import haus from "../../img/onboarding/brands/haus.png";
import lovevery from "../../img/onboarding/brands/lovevery.png";
import madeIn from "../../img/onboarding/brands/made-in.png";
import parade from "../../img/onboarding/brands/parade.png";
import rhone from "../../img/onboarding/brands/rhone.png";
import trueBotanicals from "../../img/onboarding/brands/true-botanicals.png";
import formatPrice from "../../utils/formatPrice";
import BoDBanner from "../BoDBanner";
import TOSUpdateModal from "../modals/TOSUpdateModal";
import useViewTrack from "../../hooks/useViewTrack.js";
import useMessage from "../../hooks/useMessage.js";
import getPlatformPath from "../../utils/getPlatformPath.js";
import hasMigratedToExtensions from "../../utils/hasMigratedToExtensions.js";
import isOtherShop from "../../utils/isOtherShop.js";

export const UniversalPreviewContext = createContext();
const InfoArea = memo(({ className = "", children, ...props }) => (
	<section className={`core-info-area ${className}`} {...props}>
		<span>
			<InfoIcon />
		</span>
		<div className="core-info-area-children">{children}</div>
	</section>
));

const Page = memo(
	forwardRef(
		(
			{ user, children, key, auth = false, className = "", ...rest },
			ref
		) => {
			const _showAlertBar = useMemo(() => showAlertBar(user), [user]);
			const _showBodBanner = useMemo(() => showBodBanner(user), [user]);

			return (
				<PaddedContainer
					motionElement
					className={genClassName({
						base: "page",
						conditionals: {
							"auth-page": auth,
							"page-show-alert": _showAlertBar,
							"page-show-bod-banner": _showBodBanner,
						},
						additional: className,
					})}
					ref={ref}
					variants={auth ? {} : anim.page}
					initial="initial"
					animate="animate"
					exit="exit"
					key={key}
					{...rest}
					Element="main"
				>
					{children}
				</PaddedContainer>
			);
		}
	)
);

const PageHeader = memo(
	({
		heading = "",
		className = "",
		tabOptions,
		handleTabChange,
		actionChildren,
		children,
		...rest
	}) => {
		return (
			<PaddedContainer
				Element="header"
				className={genClassName({
					base: "page-header",
					additional: className,
				})}
				{...rest}
			>
				<PaddedContainer className="page-header-half">
					<Text thick size={FONT_SIZE.SUB_TITLE}>
						{heading}
					</Text>
					{Array.isArray(tabOptions) && (
						<Tabs options={tabOptions} onChange={handleTabChange} />
					)}
				</PaddedContainer>
				<PaddedContainer className="page-header-half">
					{actionChildren}
				</PaddedContainer>
				{children}
			</PaddedContainer>
		);
	}
);

const AuthPage = memo(
	forwardRef(
		(
			{
				children,
				active,
				blue,
				flowRef,
				wrapClassName = false,
				viewEvent,
				mobileSupported = false,
				hideMobileHeader = false,
				mobileThreshold = 0,
				...rest
			},
			ref
		) => {
			const { user, setUser } = useIdentity();
			const navigate = useNavigate();
			const [universalPreviewProps, setUniversalPreviewProps] = useState(
				DEFAULT_UNIVERSAL_PREVIEW_PROPS
			);
			const cachedUniversalPreviewMessages = useRef({});

			const {
				open: sidebarOpen,
				handleOpen: openSidebar,
				handleClose: closeSidebar,
			} = useModal(false);
			const { isMobile, width } = useDimensions();

			useViewTrack(viewEvent);

			useEffect(() => {
				userUpdateZendesk(user);
			}, [user]);

			const redirectUrl =
				isShopifyShop(user) && !hasMigratedToExtensions(user)
					? getPlatformPath("discoFeed", user)
					: getPlatformPath("integrations", user);

			const handleCompletionScreen = useCallback(() => {
				setUser((user) => ({
					...user,
					firstTime: false,
					showChecklistTooltip: true,
				}));

				navigate(redirectUrl);
			}, [setUser, navigate, redirectUrl]);

			const _showAlertBar = useMemo(() => showAlertBar(user), [user]);
			const _showBodBanner = useMemo(() => showBodBanner(user), [user]);

			if (isMobile) {
				return <MobilePage />;
			}

			return (
				<UniversalPreviewContext.Provider
					value={{
						universalPreviewProps,
						setUniversalPreviewProps,
						cachedUniversalPreviewMessages,
					}}
				>
					<Page
						user={user}
						ref={ref}
						auth
						{...rest}
						data-testid="auth-page"
					>
						{(_showBodBanner || _showAlertBar) && (
							<PaddedContainer className="banner-container">
								{_showBodBanner && <BoDBanner />}
								{_showAlertBar && (
									<AlertBar user={user} setUser={setUser} />
								)}
							</PaddedContainer>
						)}
						<motion.div
							className={`page-wrap ${wrapClassName}`}
							animate={{ opacity: wrapClassName ? 1 : 0 }}
							initial={{ opacity: 0 }}
							transition={{
								type: "tween",
								duration: 0.2,
								ease: "easeOut",
							}}
						/>

						{sidebarOpen && (
							<motion.div
								className="sidebar-backdrop"
								animate={{ opacity: sidebarOpen ? 0.5 : 0 }}
								initial={{ opacity: 0 }}
								transition={{
									type: "tween",
									duration: 0.2,
									ease: "easeOut",
								}}
								onClick={closeSidebar}
							></motion.div>
						)}

						<SideBar
							active={active}
							user={user}
							setUser={setUser}
							flowRef={flowRef}
							blue={blue}
							hideChecklist={universalPreviewProps?.inFullScreen}
							open={sidebarOpen}
							onClose={closeSidebar}
						/>

						<AnimatePresence>
							{!!user.firstTime && (
								<>
									<section className="auth-page-cover"></section>
									<CompletionScreen
										onClose={handleCompletionScreen}
									/>
								</>
							)}
						</AnimatePresence>

						<AnimatePresence>
							{!user.isSuper &&
								!user.publisher
									.accepted_latest_privacy_tos_updates && (
									<TOSUpdateModal
										open={
											!user.isSuper &&
											!user.publisher
												.accepted_latest_privacy_tos_updates
										}
										// onClose={handleTOSClose}
									/>
								)}
						</AnimatePresence>

						<PageContent>
							{isMobile &&
								(!hideMobileHeader ? (
									<MobileHeader openSidebar={openSidebar} />
								) : null)}
							{isMobile || width < mobileThreshold ? (
								mobileSupported ? (
									<section className="auth-page-mobile-content">
										{children}
									</section>
								) : (
									<MobileComingSoonModal />
								)
							) : (
								children
							)}
						</PageContent>
					</Page>
				</UniversalPreviewContext.Provider>
			);
		}
	)
);

const EmbeddedPreviewPage = memo(
	({
		user,
		setUser,
		className = "",
		children,
		renderHeader,
		hideInitially = false,
		disablePreview = false,
	}) => {
		const [hidePreview, setHidePreview] = useState(hideInitially);
		const handlePreviewClose = useCallback(() => setHidePreview(true), []);
		const handlePreviewOpen = useCallback(() => setHidePreview(false), []);

		const previewOptions = useMemo(() => getPreviewOptions(), []);

		const headerProps = useMemo(
			() => ({
				user,
				setUser,
				hidePreview,
				handlePreviewOpen,
				previewOptions,
			}),
			[user, setUser, hidePreview, handlePreviewOpen, previewOptions]
		);

		return (
			<section className={`embedded-preview-page ${className}`}>
				<section className="embedded-preview-page-header">
					{typeof renderHeader === "function" &&
						renderHeader(headerProps)}
				</section>
				<motion.section className="embedded-preview-page-body">
					<motion.section className="embedded-preview-page-child">
						{children}
					</motion.section>
					<AnimatePresence>
						{!disablePreview && !hidePreview && (
							<UniversalPreview
								user={user}
								setUser={setUser}
								onClose={handlePreviewClose}
							/>
						)}
					</AnimatePresence>
				</motion.section>
			</section>
		);
	}
);

const PageContent = memo(
	forwardRef(({ children, key, className = "", ...rest }, ref) => {
		return (
			<PaddedContainer
				className={genClassName({
					base: "page-content",
					additional: className,
				})}
				Element="main"
				motionElement
				ref={ref}
				variants={anim.pageContent}
				hPadding={SPACING.LARGE}
				initial="initial"
				animate="animate"
				exit="exit"
				flatBottom
				key={key}
				{...rest}
			>
				{children}
			</PaddedContainer>
		);
	})
);

const DropDownOption = React.memo(
	({
		icon,
		iconLight,
		name,
		facebook,
		pulse,
		className = "",
		selected = undefined,
		...rest
	}) => {
		return (
			<motion.div
				layout
				className={`drop-down-option ${
					facebook ? "drop-down-option-facebook" : ""
				} ${pulse ? "drop-down-option-pulse" : ""} ${className}`}
				{...rest}
			>
				{selected !== undefined && (
					<DiscoRadio
						className="drop-down-option-radio"
						selected={selected}
					/>
				)}
				<span className={facebook ? "dropdown-facebook-icon-dark" : ""}>
					{icon}
				</span>
				{facebook && (
					<span className="dropdown-facebook-icon-light">
						{iconLight}
					</span>
				)}
				{name}
			</motion.div>
		);
	}
);

const ProductBadge = memo(({ name, image, active, ...rest }) => {
	return (
		<section
			className={`product-badge${active ? " product-badge-active" : ""}`}
			{...rest}
		>
			<img src={image || DEFAULT_PRODUCT_IMG} alt="" />
			<h3>{name}</h3>
		</section>
	);
});

const Table = memo(({ className = "", ...rest }) => {
	return (
		<BootstrapTable
			// className="products-view-table"
			// rowClassName="products-view-table-row"
			classes={`lib-table ${className}`}
			{...rest}
		/>
	);
});

const MobileHeader = memo(({ openSidebar }) => {
	return (
		<header className="mobile-header">
			<div className="mobile-header-left">
				<FiMenu
					className="mobile-header-burger"
					onClick={openSidebar}
				/>
			</div>
			<div>
				<img src={colorLogo} className="mobile-header-logo" alt="" />
			</div>
			<div className="mobile-header-right"></div>
		</header>
	);
});

const MobileNavHeader = memo(({ goBack, title, logo }) => {
	return (
		<header className="mobile-nav-header">
			<div className="mobile-nav-header-left">
				<FiChevronLeft
					className="mobile-nav-header-back"
					onClick={goBack}
				/>
			</div>
			<img src={logo} className="mobile-nav-header-logo" alt="" />
			<span className="mobile-nav-header-title">{title}</span>
		</header>
	);
});

const MobileNavHeaderSpecial = memo(
	({ className, goBack, title, subTitle, logo, description }) => {
		return (
			<header className={`mobile-nav-header-special ${className}`}>
				<div className="mobile-nav-header-special-background"></div>
				<div className="mobile-nav-header-special-content">
					<div className="mobile-nav-header-special-left">
						<FiChevronLeft
							className="mobile-nav-header-special-back"
							onClick={goBack}
						/>
					</div>
					<div className="mobile-nav-header-special-logo-container">
						{logo && (
							<img
								src={logo}
								className="mobile-nav-header-special-logo"
								alt=""
							/>
						)}
					</div>
					<div className="mobile-nav-header-special-texts">
						<div className="mobile-nav-header-special-title">
							{title}
						</div>
						{subTitle && (
							<div className="mobile-nav-header-special-subtitle">
								{subTitle}
							</div>
						)}
					</div>
				</div>
				{description && (
					<div className="mobile-nav-header-special-description">
						{description}
					</div>
				)}
			</header>
		);
	}
);

const Loader = memo(
	({
		small = false,
		small2 = false,
		center = false,
		light = false,
		dark = false,
		middle = false,
		className = "",
		...rest
	}) => (
		<div
			className={`loader ${center ? "loader-center" : ""} ${
				middle ? "loader-middle" : ""
			} ${small ? "loader-small" : ""} ${
				small2 ? "loader-small-2" : ""
			} ${light ? "loader-light" : ""} ${
				dark ? "loader-dark" : ""
			} ${className}`}
			{...rest}
		>
			<div className="loader-circle" />
		</div>
	)
);

const Product = memo(({ product, className = "", small = false }) => (
	<section className={`product ${small ? "product-small" : ""} ${className}`}>
		<img src={product.photo_url} alt="Product" />
		<section className="product-info">
			<h4>{product.name}</h4>
			<h5>$ {formatPrice(product.price || product.discounted_price)}</h5>
		</section>
	</section>
));

const FlexBr = memo(() => <div className="flex-br"> </div>);

const LargeListItem = memo(({ index, icon, text, heading }) => {
	return (
		<motion.section variants={anim.rowItem} className="large-list-item">
			<aside>
				<span className={icon ? "large-list-item-icon" : ""}>
					{icon ? icon : index}
				</span>
			</aside>
			{heading && <h5>{heading}</h5>}
			<main>{text}</main>
		</motion.section>
	);
});

const LargeList = memo(({ list, icon = null }) => {
	return (
		<motion.section
			variants={anim.rowItem}
			initial="initial"
			animate="animate"
			exit="exit"
			className="large-list"
		>
			{list.map((item, index) => (
				<LargeListItem
					text={item.text}
					index={index + 1}
					icon={item.icon || icon}
					heading={item.heading}
				/>
			))}
		</motion.section>
	);
});

// Text input
const Input = memo(
	React.forwardRef(function (
		{
			type = "text",
			className = "",
			textarea = false,
			containerClassName = "",
			value,
			maxLength,
			alignUnitLeft = false,
			minimalUnit = false,
			staticAfter = null,
			unit = null,
			children,
			...props
		},
		ref
	) {
		return textarea ? (
			<div className={`inp-container ${containerClassName}`}>
				<textarea
					ref={ref}
					type={type}
					value={value}
					className={`inp ${className}`}
					maxLength={maxLength}
					{...props}
				/>
				{maxLength && (
					<div className="inp-counter">
						<span>{value.length}</span> / {maxLength}
					</div>
				)}
			</div>
		) : (
			<div
				className={`inp-container  ${
					alignUnitLeft ? "inp-container-placeholder-left" : ""
				} ${
					minimalUnit ? "minimal-inp-after-placeholder-left" : ""
				} ${containerClassName}`}
			>
				<input
					ref={ref}
					type={type}
					value={value}
					className={`inp ${className}`}
					{...props}
				/>
				{children}
				{(staticAfter || unit) && (
					<div className={`inp-after-placeholder`}>
						{unit ? (
							<span
								className={`inp-unit ${
									minimalUnit ? "inp-unit-minimal" : ""
								}`}
							>
								{unit}
							</span>
						) : (
							staticAfter
						)}
					</div>
				)}
			</div>
		);
	})
);

const ActionInput = memo(
	React.forwardRef(
		({ className = "", buttonText, onClick, ...rest }, ref) => {
			return (
				<Input
					containerClassName={`action-inp-container ${className}`}
					ref={ref}
					{...rest}
				>
					<Button onClick={onClick}>{buttonText}</Button>
				</Input>
			);
		}
	)
);

const ProgressView = memo(({ progress = 0, className = "" }) => {
	return (
		<section className={`progress-view ${className}`}>
			<section
				className="progress-view-bar"
				style={{ width: progress * 100 + "%" }}
			/>
		</section>
	);
});

const ShopifyDot = memo(({ yellow }) => {
	return (
		<span className={`shopify-dot ${yellow ? "shopify-dot-yellow" : ""}`}>
			<FaShopify />
			<span className="dot dot-green" />
		</span>
	);
});

const RemoteInput = memo(
	({
		url,
		method,
		value,
		hideTillHover = false,
		dataMapper,
		stopPropagation,
		active,
		initialActive = false,
		onDone,
		className = "",
		tooltip = null,
		...rest
	}) => {
		const [payload, setPayload] = useState({});
		const [focus, setFocus] = useState(initialActive);
		const [hover, setHover] = useState(false);
		const inpRef = useRef();

		const focusRef = useRef(false);

		const [{ loading, data }, save, reset] = useResource(
			{ url, method, data: payload },
			false
		);

		const handleBlur = () => {
			if (url && value.trim().length) {
				setPayload(dataMapper(value));
				save();
			}
			setFocus(false);
		};

		const handleFocus = () => {
			setFocus(true);
			focusRef.current = true;
			if (inpRef.current) {
				inpRef.current.select();
			}
		};

		useEffect(() => {
			if (!data) {
				return;
			}
			if (typeof onDone === "function") {
				onDone();
			}
			reset();
		}, [data, reset, onDone]);

		const handleClick = (e) => {
			if (stopPropagation) {
				e.stopPropagation();
			}
		};

		return (
			<Input
				{...rest}
				className={`remote-inp ${className} `}
				containerClassName={`remote-inp-container ${
					focus || active ? "remote-inp-container-focus" : ""
				} ${hideTillHover ? "remote-inp-container-hidden" : ""}`}
				disabled={loading}
				value={value}
				onFocus={handleFocus}
				onBlur={handleBlur}
				ref={inpRef}
				onMouseEnter={() => setHover(true)}
				onMouseLeave={() => {
					setHover(false);
					if (!focusRef.current) {
						setFocus(false);
					}
				}}
				onClick={handleClick}
			>
				{tooltip && (
					<Tooltip
						open={hover}
						heading={tooltip?.heading}
						className="remote-inp-tooltip"
					>
						{tooltip?.text}
					</Tooltip>
				)}
				{loading && <Loader small />}
				<span>{focus ? <FaCheck /> : <ImPencil />}</span>
			</Input>
		);
	}
);

const Badge = memo(
	({
		lightTheme,
		light,
		icon,
		subtle,
		subtleLight,
		children,
		className = "",
		large = false,
		viewOnly,
		...rest
	}) => {
		return (
			<Button
				className={`badge ${subtleLight ? "badge-subtle-light" : ""} ${
					light || lightTheme ? "badge-light" : ""
				} ${large ? "badge-large" : ""} ${
					lightTheme ? "badge-light-theme" : ""
				} ${subtle ? "badge-subtle" : ""} ${
					viewOnly ? "badge-view-only" : ""
				} ${className}`}
				{...rest}
			>
				{icon && <span>{icon}</span>}
				{children}
			</Button>
		);
	}
);

export const Rectangle = memo(({ type = "", width = 100, className = "" }) => {
	return (
		<div
			className={`rectangle ${
				type ? `rectangle-${type}` : ""
			} ${className}`}
			style={{
				width: `${width}px`,
			}}
		/>
	);
});

const ListInput = memo(
	React.forwardRef(
		(
			{
				selected,
				className = "",
				fetchUrl,
				saveUrl,
				name,
				onSave,

				eventName = "",
				remoteKey,
				empty = false,
				preview = false,
				previewTitle = "",
				refresh = false,
				subCategory = false,
				onlyOne = false,
				override = false,
				setOverride,
				miniBadge = false,
			},
			ref
		) => {
			const [editOpen, setEditOpen] = useState(false);
			const handleClick = useCallback(() => {
				setEditOpen(true);
			}, [setEditOpen]);

			const handleClose = useCallback(() => {
				setEditOpen(false);
				if (setOverride && typeof setOverride === "function") {
					setOverride(false);
				}
			}, [setOverride]);

			const handleSave = useCallback(
				(draft) => {
					onSave(draft);
					setEditOpen(false);
				},
				[onSave]
			);

			useEffect(() => {
				setEditOpen(override);
			}, [override]);

			return (
				<>
					<section
						className={`list-inp-container ${className} ${
							miniBadge ? "list-inp-container-mini-badge" : ""
						}`}
						ref={ref}
					>
						<BadgeButton
							style={{
								width: "0px",
								padding: "0px",
								height: "0px",
								border: "0px",
							}}
						/>
						{Array.isArray(selected) &&
							selected.map((setItem) => {
								if (miniBadge) {
									return (
										<MiniBadge
											className="list-inp-badge list-inp-mini-badge"
											listItem
											key={setItem.remote_id}
											theme
										>
											{setItem.name}
										</MiniBadge>
									);
								}
								return (
									<BadgeButton
										className="list-inp-badge"
										listItem
										key={setItem.remote_id}
									>
										{setItem.name}
									</BadgeButton>
								);
							})}
						{miniBadge ? (
							<MiniBadge
								className="list-inp-badge list-inp-icon-badge list-inp-mini-badge"
								onClick={handleClick}
								listItem
							>
								<span>
									{Array.isArray(selected) &&
									selected.length > 0 ? (
										<MdEdit />
									) : (
										<PlusCircle />
									)}
								</span>
								{Array.isArray(selected) && selected.length > 0
									? "Edit"
									: "Add"}
							</MiniBadge>
						) : (
							<BadgeButton
								className="list-inp-badge list-inp-icon-badge"
								onClick={handleClick}
								listItem
							>
								<span>
									{Array.isArray(selected) &&
									selected.length > 0 ? (
										<MdEdit />
									) : (
										<PlusCircle />
									)}
								</span>
								{Array.isArray(selected) && selected.length > 0
									? "Edit"
									: "Add"}
							</BadgeButton>
						)}
					</section>
					<EditSelectedModal
						fetchUrl={fetchUrl}
						saveUrl={saveUrl}
						open={editOpen}
						eventName={eventName}
						empty={empty}
						selected={selected}
						heading={`Edit ${name}`}
						onClose={handleClose}
						onSave={handleSave}
						remoteKey={remoteKey}
						preview={preview}
						previewTitle={previewTitle}
						refresh={refresh}
						subCategory={subCategory}
						onlyOne={onlyOne}
					/>
				</>
			);
		}
	)
);

const CopyBlock = memo(({ children, heightParams, alwaysOpen = false }) => {
	const [open, setOpen] = useState(alwaysOpen);
	const textRef = useRef();

	const [copied, setCopied] = useState();
	const timerRef = useRef(null);

	useEffect(() => {
		if (!copied) return;
		if (timerRef !== null) clearTimeout(timerRef.current);
		timerRef.current = setTimeout(() => {
			timerRef.current = null;
			setCopied(false);
		}, 2000);
	}, [copied]);

	const handleCopy = () => {
		if (!textRef.current) return;
		textRef.current.select();
		document.execCommand("copy");
		setCopied(true);
	};

	return (
		<section className={`copy-block ${open ? "copy-block-open" : ""}`}>
			<motion.textarea
				readOnly
				animate={{
					height: open
						? heightParams?.open || "160px"
						: heightParams?.close || "80px",
				}}
				ref={textRef}
			>
				{children}
			</motion.textarea>
			<motion.div
				className="copy-block-shadow"
				animate={{ opacity: open ? 0 : 1 }}
			/>
			<span className="copy-block-copy" onClick={handleCopy}>
				{copied ? "✅ Copied" : "Copy"}
			</span>
			{!alwaysOpen && (
				<motion.section
					className="copy-block-toggle"
					onClick={() => !alwaysOpen && setOpen((open) => !open)}
					animate={{ rotate: open ? 180 : 0 }}
				>
					<span>
						<DownIcon />
					</span>
				</motion.section>
			)}
		</section>
	);
});

const ToggleInput = React.memo(
	React.forwardRef(
		(
			{
				toggleState = false,
				handleToggle,
				inverseToggle = false,
				disabled,
				hideDefaultFeedbackText = false,
				customFeedbackText = "",
				loading = false,
				dummy,
				beforeToggleInit,
				tooltip,
				...props
			},
			ref
		) => {
			return (
				<>
					<section
						className={`toggle-inp-container ${
							(inverseToggle ? !toggleState : toggleState)
								? "toggle-inp-container-activated"
								: ""
						} ${disabled ? "toggle-inp-container-disabled" : ""}`}
						ref={ref}
						{...props}
					>
						{loading ? (
							<Loader className="toggle-inp-loader" />
						) : (
							<Toggle
								className="toggle-inp"
								toggle={toggleState}
								setToggleState={handleToggle}
								inverseToggle={inverseToggle}
								disabled={disabled}
								beforeToggleInit={beforeToggleInit}
								dummy={dummy}
								tooltip={tooltip}
							/>
						)}
						{!hideDefaultFeedbackText && (
							<span className="toggle-inp-feedback-text">
								{(inverseToggle ? !toggleState : toggleState)
									? "Enabled"
									: "Disabled"}
							</span>
						)}
						{hideDefaultFeedbackText &&
							customFeedbackText !== "" && (
								<span className="toggle-inp-feedback-text">
									{customFeedbackText}
								</span>
							)}
					</section>
				</>
			);
		}
	)
);

const TitledCard = memo(
	forwardRef(
		(
			{
				icon,
				title,
				light = false,
				className = "",
				minimal = false,
				children,
				...rest
			},
			ref
		) => {
			return (
				<section
					className={`titled-card ${className} ${
						light ? "titled-card-light" : ""
					}`}
					ref={ref}
					{...rest}
				>
					<header>
						<span>{icon}</span>
						{title}
					</header>
					{!minimal && <main>{children}</main>}
				</section>
			);
		}
	)
);

const Radio = memo(({ selected }) => {
	return (
		<div className="radio-inp-option-circle">
			{selected && <div className="radio-inp-option-filler" />}
		</div>
	);
});

const RadioInput = memo(
	forwardRef(
		(
			{
				data,
				name,
				selected = 0,
				onChange,
				disabled = false,
				children,
				onFocus,
				type = "",
				className = "",
				testIdPrefix,
				...props
			},
			ref
		) => {
			return (
				<section
					className={`radio-inp-container ${
						type === "distinct-options"
							? "radio-inp-container-distinct-options"
							: ""
					} ${className || ""}`}
					ref={ref}
					{...props}
					onMouseEnter={() => {
						return typeof onFocus === "function"
							? onFocus({ target: { name } })
							: undefined;
					}}
				>
					{children}
					{data.map((dataItem, index) => {
						return (
							<div
								className={`radio-inp-option ${
									dataItem?.className
										? dataItem.className
										: ""
								}`}
								key={`${dataItem.name}_${index}`}
							>
								<div
									className={`radio-inp-option-circle ${
										index === selected
											? "radio-inp-option-circle-selected"
											: ""
									}`}
									onClick={() =>
										!disabled
											? onChange(index, name)
											: false
									}
								>
									<AnimatePresence>
										{index === selected && (
											<motion.div
												className="radio-inp-option-filler"
												variants={anim.circleOption}
												initial="initial"
												animate="animate"
												exit="exit"
											/>
										)}
									</AnimatePresence>
								</div>
								<div className="radio-inp-option-content">
									<div
										className="radio-inp-option-name"
										onClick={() =>
											!disabled
												? onChange(index, name)
												: false
										}
										{...(testIdPrefix
											? {
													"data-testid": `${testIdPrefix}${index}`,
											  }
											: {})}
									>
										{dataItem.name}
									</div>
									{index === selected &&
									dataItem.renderChildren ? (
										<motion.div
											className={`radio-inp-option-content-children`}
											variants={anim.circleOption}
											initial="initial"
											animate="animate"
											exit="exit"
											key={`render_child_${index}`}
										>
											{dataItem.renderChildren()}
										</motion.div>
									) : null}
								</div>
							</div>
						);
					})}
				</section>
			);
		}
	)
);

const MobileRadioInput = memo(
	forwardRef(
		(
			{
				data,
				name,
				label = "",
				selected = null,
				onChange,
				disabled = false,
				className = "",
				placeholder = "",
				...props
			},
			ref
		) => {
			const [open, setOpen] = useState(false);
			const [selectedIdx, setSelectedIdx] = useState(null);

			const handleChange = (index) => {
				setSelectedIdx(index);
			};

			const handleOpen = () => {
				setSelectedIdx(selected);
				setOpen(true);
			};

			const handleCancel = () => {
				setOpen(false);
			};

			const handleSave = () => {
				onChange(selectedIdx, name);
				setOpen(false);
			};

			return (
				<section
					className={`mobile-radio-inp-container ${className || ""}`}
					ref={ref}
					{...props}
				>
					<div className="inp-container" onClick={handleOpen}>
						<input
							type="text"
							className={`inp mobile-radio-inp ${className}`}
							value={
								selected !== null
									? data[selected].name
									: placeholder
							}
							disabled
						/>
						<motion.span
							className="mobile-radio-inp-icon"
							animate={{ rotate: open ? 180 : 0 }}
						>
							<DownIcon />
						</motion.span>
					</div>
					<Modal
						open={open}
						sheet
						closerHidden
						className="mobile-inp-modal mobile-radio-inp-modal"
						onClose={handleCancel}
					>
						<section>
							<ModalSheetHeader
								label={label}
								cancelLabel="Cancel"
								handleCancel={handleCancel}
							/>
							{data.map((dataItem, index) => {
								return (
									<div
										className={`radio-inp-option mobile ${
											dataItem?.className
												? dataItem.className
												: ""
										}`}
										key={`${dataItem.name}_${index}`}
									>
										<div
											className={`radio-inp-option-circle ${
												index === selectedIdx
													? "radio-inp-option-circle-selected"
													: ""
											}`}
											onClick={() =>
												!disabled
													? handleChange(index)
													: false
											}
										>
											<AnimatePresence>
												{index === selectedIdx && (
													<motion.div
														className="radio-inp-option-filler"
														variants={
															anim.circleOption
														}
														initial="initial"
														animate="animate"
														exit="exit"
													/>
												)}
											</AnimatePresence>
										</div>
										<div className="radio-inp-option-content">
											<div
												className="radio-inp-option-name"
												onClick={() =>
													!disabled
														? handleChange(index)
														: false
												}
											>
												{dataItem.name}
											</div>
											{index === selectedIdx &&
											dataItem.renderChildren ? (
												<motion.div
													className={`radio-inp-option-content-children`}
													variants={anim.circleOption}
													initial="initial"
													animate="animate"
													exit="exit"
													key={`render_child_${index}`}
												>
													{dataItem.renderChildren()}
												</motion.div>
											) : null}
										</div>
									</div>
								);
							})}
							<Button
								className="mobile-radio-inp-button"
								gradient
								large
								shadow
								disabled={selectedIdx === null}
								onClick={handleSave}
							>
								Done
							</Button>
						</section>
					</Modal>
				</section>
			);
		}
	)
);

export const CheckboxListInput = memo(
	({
		options,
		value,
		className = "",
		nameKey = "name",
		onChange,
		name,
		...rest
	}) => {
		const selectedSet = useMemo(
			() => (Array.isArray(value) ? new Set(value) : new Set()),
			[value]
		);

		const handleChange = ({ target: { checked, name: optionId } }) => {
			if (!isFunction(onChange)) return;
			optionId = Number(optionId);
			const newValue = checked
				? Array.isArray(value)
					? [...value, optionId]
					: [optionId]
				: value.filter((id) => id !== optionId);

			onChange({ target: { name, value: newValue } });
		};

		return (
			<section className={`checkbox-list-input ${className}`} {...rest}>
				{options.map((option) => (
					<section className="checkbox-list-input-row">
						<Checkbox
							checked={selectedSet.has(option.id)}
							name={option.id}
							onChange={handleChange}
						/>
						{option[nameKey]}
					</section>
				))}
			</section>
		);
	}
);

const LabeledInput = React.memo(
	React.forwardRef(function (
		{
			label = "",
			className = "",
			list = false,
			toggle = false,
			dummyToggle = false,
			radio = false,
			required,
			children,
			inDraft = false,
			explicitEdit = false,
			tooltipTitle,
			tooltipText = "",
			disabled,
			tooltip,
			...props
		},
		ref
	) {
		const [editable, setEditable] = useState(false);

		const handleChange = useCallback(({ target: { checked } }) => {
			setEditable(checked);
		}, []);

		return (
			<section className={`labeled-inp ${className}`}>
				<Label required={required} inDraft={inDraft}>
					{label}
					{tooltipText && (
						<QuestionTooltip
							className="labeled-inp-tooltip-icon"
							tooltipClassName="labeled-inp-tooltip"
							heading={tooltipTitle}
						>
							{tooltipText}
						</QuestionTooltip>
					)}
					{explicitEdit && (
						<section className="labeled-inp-edit">
							( Edit
							<Checkbox
								value={editable}
								onChange={handleChange}
							/>
							)
						</section>
					)}
					{!list && !toggle && !radio && (
						<Input
							disabled={explicitEdit ? !editable : disabled}
							ref={ref}
							required={required}
							{...props}
						/>
					)}
					{list && <ListInput ref={ref} {...props} />}
					{toggle &&
						(dummyToggle ? (
							<ToggleInput
								hideDefaultFeedbackText
								toggleState={true}
								dummy={dummyToggle}
								ref={ref}
								tooltip={tooltip}
							/>
						) : (
							<ToggleInput ref={ref} {...props} />
						))}

					{radio && <RadioInput ref={ref} {...props} />}
				</Label>
				{children}
			</section>
		);
	})
);

const ColorInput = memo(
	forwardRef(
		({ value, onChange, onFocus, onBlur, name, label, ...rest }, ref) => {
			const [open, setOpen] = useState(false);
			const handleChange = (color) =>
				typeof onChange === "function"
					? onChange({ target: { name, value: color.hex } })
					: null;

			const handleDirectChange = ({ target: { value } }) =>
				typeof onChange === "function"
					? onChange({ target: { name, value: value } })
					: null;

			const handleFocus = () => {
				setOpen(true);
				return typeof onFocus === "function"
					? onFocus({ target: { name } })
					: null;
			};

			const handleBlur = () => {
				setOpen(false);
				return typeof onBlur === "function"
					? onBlur({ target: { name } })
					: null;
			};

			return (
				<section
					className="color-inp"
					tabIndex={0}
					onFocus={handleFocus}
					onBlur={handleBlur}
					ref={ref}
				>
					<DiscoLabeledInput
						value={value}
						{...rest}
						type="text"
						onChange={handleDirectChange}
						label={label}
					>
						<section
							className="color-inp-preview"
							style={{ background: value || "#fff" }}
						></section>
					</DiscoLabeledInput>
					{open && (
						<ChromePicker
							color={value}
							onChange={handleChange}
							className="color-inp-color"
							disableAlpha
							width={"100%"}
							styles={{ height: "100px" }}
						/>
					)}
				</section>
			);
		}
	)
);

const DropDown = memo(
	forwardRef(({ className = "", children, open, ...rest }, ref) => {
		return (
			<AnimatePresence>
				{open && (
					<motion.section
						variants={anim.dialog}
						className={`drop-down ${className}`}
						initial="initial"
						animate="animate"
						exit="exit"
						ref={ref}
						{...rest}
					>
						{children}
					</motion.section>
				)}
			</AnimatePresence>
		);
	})
);

const PublishDropDownOption = memo(({ heading, text, ...rest }) => {
	return (
		<section className="publish-drop-down-row">
			<Checkbox {...rest} />
			<main>
				<h3>{heading}</h3>
				<p>{text}</p>
			</main>
		</section>
	);
});

const stopPropagation = (e) => {
	if (e) {
		e.stopPropagation();
	}
};

const PublishButton = memo(
	({
		className,
		options,
		children,
		onPublish,
		successKey,
		saveUrl,
		method,
		setUser,
		...rest
	}) => {
		const {
			open: publishOpen,
			handleToggle: handlePublishToggle,
			handleClose: handlePublishClose,
		} = useModal();
		const [checkedOptions, setCheckedOptions] = useState({});

		const [success, setSuccess] = useState(false);

		const [{ loading, error, data }, save, reset] = useResource(
			{
				url: saveUrl,
				method: method ?? "PUT",
				data: options
					? Object.keys(options).reduce(
							(data, key) => ({
								...data,
								[key]: checkedOptions[key] ?? false,
							}),
							{}
					  )
					: {},
			},
			false
		);

		useEffect(() => {
			setCheckedOptions(
				options
					? Object.entries(options).reduce(
							(data, [key, option]) => ({
								...data,
								[key]: option.checked ?? false,
							}),
							{}
					  )
					: {}
			);
		}, [options]);

		const handleChange = useCallback(({ target: { checked, name } }) => {
			setCheckedOptions((options) => ({ ...options, [name]: checked }));
		}, []);

		useEffect(() => {
			if (!data) return;
			handlePublishClose();
			if (typeof onPublish === "function") {
				onPublish(checkedOptions);
			}
			if (typeof setUser === "function") {
				setUser((user) => ({
					...user,
					publisher: {
						...user.publisher,
						...data,
					},
				}));
			}
			reset();
			setSuccess(data[successKey]);

			setTimeout(() => {
				setSuccess(false);
			}, 2000);
		}, [
			data,
			reset,
			onPublish,
			handlePublishClose,
			checkedOptions,
			setUser,
			successKey,
		]);

		return (
			<Button
				onClick={handlePublishToggle}
				className={`publish-btn ${
					success ? "publish-btn-success" : ""
				} ${className}`}
				{...rest}
			>
				{success && (
					<span className="publish-btn-success-icon">
						<MdCheck />
					</span>
				)}
				{success ? "Published" : "Publish"}
				<span className="publish-btn-success-icon publish-btn-success-down">
					<DownIcon />
				</span>

				<DropDown
					open={publishOpen}
					className="publish-drop-down"
					onClick={stopPropagation}
				>
					{options &&
						Object.entries(options).map(([key, option]) => (
							<PublishDropDownOption
								{...option}
								checked={checkedOptions[key] ?? false}
								onChange={handleChange}
								name={key}
								key={key}
								disabled={loading || option?.disableCheck}
							/>
						))}

					<section className="publish-drop-down-row">
						{loading ? (
							<Loader small2 />
						) : (
							<>
								<Button
									light
									onClick={handlePublishToggle}
									className="publish-drop-down-close"
								>
									Close
								</Button>
								<Button gradient onClick={save}>
									Publish
								</Button>
							</>
						)}
					</section>

					{error && <FormStatus>{parseError(error)}</FormStatus>}
				</DropDown>
			</Button>
		);
	}
);

const PreviewButton = memo(({ options, handlePreviewType, ...props }) => {
	const [showDropDown, setShowDropDown] = useState(false);

	const handleOpenClick = useCallback(
		() => setShowDropDown((showDropDown) => !showDropDown),
		[]
	);

	const handleOptionClick = useCallback(
		(value) =>
			typeof handlePreviewType === "function"
				? handlePreviewType(value)
				: null,
		[handlePreviewType]
	);

	return (
		<Button onClick={handleOpenClick} className="preview-btn" {...props}>
			Preview
			<span className="publish-btn-success-icon publish-btn-success-down">
				<DownIcon />
			</span>
			<DropDown className="preview-btn-drop-down" open={showDropDown}>
				{options.map((option) => (
					<DropDownOption
						name={option.title}
						onClick={() => handleOptionClick(option.type)}
						key={option.type}
					></DropDownOption>
				))}
			</DropDown>
		</Button>
	);
});

// Select Dropdown

const SelectOptions = memo(
	({
		open,
		setOpen,
		label,
		cancelLabel,
		icon,
		expandable,
		loading,
		options,
		searchable,
		searchPlaceholder = "Start typing to search",
		searchClassName,
		searchRef,
		search,
		setSearch,
		nested,
		renderNestedOptions,
		renderOptions,
		emptyMessage,
		independantExpandable,
		renderExpandableArea,
		mapExpandableAreaProps,
		data,
		highlightedKey,
		optionsTestIdKeyPrefix,
	}) => {
		const { isMobile } = useDimensions();

		const handleCancel = useCallback(() => setOpen(false), [setOpen]);
		const children = (
			<>
				<div className="select-options-wrapper">
					{loading && <Loader middle />}
					{!loading &&
						(Object.entries(options).length > 0 ? (
							<>
								{searchable && (
									<SearchInput
										ref={searchRef}
										value={search}
										placeholder={searchPlaceholder}
										onChange={(e) =>
											setSearch(e.target.value)
										}
										className={searchClassName}
									/>
								)}
								{nested
									? renderNestedOptions(
											options,
											search,
											optionsTestIdKeyPrefix
									  )
									: renderOptions(
											options,
											search,
											"",
											optionsTestIdKeyPrefix
									  )}
							</>
						) : (
							<section className="select-no-options">
								{emptyMessage}
							</section>
						))}
				</div>
				{expandable && (
					<section
						className={`select-options-expandable-area ${
							independantExpandable
								? "select-options-expandable-independant-area"
								: ""
						}`}
					>
						{!independantExpandable && (
							<>
								<h2 className="select-options-expandable-area-title">
									Preview Title
								</h2>
								<div className="select-options-expandable-area-body" />
							</>
						)}
						{independantExpandable &&
							renderExpandableArea(
								mapExpandableAreaProps(
									data.current,
									highlightedKey
								)
							)}
					</section>
				)}
			</>
		);

		if (isMobile) {
			return (
				<Modal
					open={open}
					sheet
					closerHidden
					className="mobile-inp-modal"
					onClose={handleCancel}
				>
					<section className="select-options-mobile">
						<ModalSheetHeader
							label={label}
							cancelLabel={cancelLabel}
							handleCancel={handleCancel}
							icon={icon}
						/>
						{children}
					</section>
				</Modal>
			);
		}
		return (
			<AnimatePresence>
				{open && (
					<motion.section
						className={`select-options ${
							expandable ? "select-options-expandable" : ""
						}`}
						variants={anim.select}
						initial="initial"
						animate="animate"
						exit="exit"
					>
						{children}
					</motion.section>
				)}
			</AnimatePresence>
		);
	}
);
const Select = memo(
	React.forwardRef(
		(
			{
				data,
				options,
				value,
				onChange,
				name,
				onFocus,
				onBlur,
				onOpen,
				onClose,
				emptyMessage,
				required,
				placeholder = "",
				containerClassName = "",
				className = "",
				style,
				searchable = false,
				searchOnly = false,
				searchPlaceholder,
				searchClassName = "",
				onMouseEnter,
				onMouseLeave,
				multiple,
				loading,
				nested = false,
				expandable = false,
				independantExpandable = false,
				mapExpandableAreaProps,
				renderExpandableArea,
				empty = true,
				disabled = false,
				onHover,
				label,
				miniBadge = false,
				padded = false,
				minimal = false,
				inDraft = false,
				tooltipTitle = "What does this mean?",
				tooltipText = "",
				renderInlineValue,
				openEvent,
				changeEvent,
				sheetLabel,
				sheetCancelLabel = "Cancel",
				sheetIcon,
				highlightSelected = false,
				itemClassName = "",
				noStyle = false,
				showCheck = false,
				inline = false,
				optionsTestIdKeyPrefix = null,
				"data-testid": testId,
				...rest
			},
			ref
		) => {
			const [open, setOpen] = useState(false);
			const [highlightedKey, setHighlightedKey] = useState(-1);
			const selectRef = useRef(null);
			const searchRef = useRef(null);
			const [search, setSearch] = useState("");
			const [error, setError] = useState("");

			const uid = useRef("select-" + new Date().getTime()).current;
			const track = useTrack();

			const { isMobile } = useDimensions();

			const handleChange = (value) => {
				if (changeEvent) {
					track(changeEvent, {
						name,
						value,
					});
				}
				onChange({ target: { name, value } });
				setOpen(multiple);
				if (!multiple) {
					setError("");
				}
			};

			const flatOptions = useMemo(
				() =>
					nested
						? Object.entries(options).reduce(
								(flat, [, sub]) => ({ ...flat, ...sub }),
								{}
						  )
						: options,
				[options, nested]
			);

			const canClose = useCallback(() => {
				if (!nested || !required) {
					return empty || (value && value.length > 0);
				}

				const done = Object.entries(options).reduce(
					(doneMap, [key]) => ({ ...doneMap, [key]: false }),
					{}
				);

				value.forEach((id) => {
					const parKey = Object.entries(options).find(
						([, val]) =>
							Object.entries(val).findIndex(
								([innerId]) => innerId === id
							) !== -1
					);
					if (!parKey) {
						return;
					}
					done[parKey[0]] = true;
				});

				return Object.entries(done).reduce(
					(ret, [, val]) => ret && val,
					true
				);
			}, [nested, options, value, empty, required]);

			const handleToggle = (e, force) => {
				if (disabled) {
					return;
				}
				// if (e) {
				// 	e.stopPropagation();
				// }

				setOpen((open) => {
					const newOpen = disabled
						? false
						: force !== undefined
						? force
						: !open;
					if (newOpen) {
						if (onOpen && typeof onOpen === "function") {
							onOpen({ target: { name } });
						}
						if (searchRef.current) {
							searchRef.current.focus();
						}
						if (openEvent) {
							track(openEvent, { name });
						}
					} else if (!newOpen) {
						if (!canClose()) {
							setError(
								nested
									? "Please select at least one subcategory from each category"
									: "Please select at least one"
							);
							return true;
						}
						if (onClose && typeof onClose === "function") {
							onClose({ target: { name, away: false } });
						}
						setSearch("");
						setError("");
					}
					return newOpen;
				});
			};

			useEffect(() => {
				if (isMobile) {
					return;
				}
				const handleClickAway = (e) => {
					if (
						!document.body.contains(e.target) ||
						e.target.closest("#" + uid)
					) {
						return e.preventDefault();
					}
					if (open) {
						if (onClose && typeof onClose === "function") {
							onClose({ target: { name, away: false } });
						}
						e.preventDefault();
						return setOpen(false);
					}
				};
				document.addEventListener("click", handleClickAway);
				return () =>
					document.removeEventListener("click", handleClickAway);
			}, [open, uid, onClose, name, isMobile]);

			const handleOptionMouseEnter = useCallback(
				(key) => {
					if (onHover && typeof onHover === "function") {
						onHover(data.current, key);
					}
					setHighlightedKey(key);
				},
				[data, onHover]
			);
			const renderOptions = (
				options,
				search,
				extraClass = "",
				optionsTestIdKeyPrefix
			) => {
				return Object.entries(options)
					.filter(([key, text]) =>
						search.trim()
							? text
									.trim()
									.toLowerCase()
									.indexOf(search.toLowerCase()) !== -1
							: !searchOnly
					)
					.map(([key, text]) => {
						return (
							<section
								className={`select-option ${
									multiple ? "select-option-multiple" : ""
								} ${itemClassName} ${
									`${key}` === `${value}` && highlightSelected
										? "select-option-highlighted"
										: ""
								} ${extraClass}`}
								onClick={() => handleChange(key)}
								onMouseEnter={() => handleOptionMouseEnter(key)}
								key={key}
								{...(optionsTestIdKeyPrefix
									? {
											"data-testid": `${optionsTestIdKeyPrefix}${key}`,
									  }
									: {})}
							>
								{text}
								{showCheck && <FiCheck />}
								{multiple && (
									<Checkbox
										checked={value.indexOf(key) !== -1}
									/>
								)}
							</section>
						);
					});
			};

			const [catOpen, setCatOpen] = useState([]);

			useEffect(() => {
				setCatOpen((catOpen) => {
					if (catOpen.length !== Object.entries(options).length) {
						let arr = [];

						for (
							let i = 0;
							i < Object.entries(options).length;
							i++
						) {
							arr.push(false);
						}
						return arr;
					}
					return catOpen;
				});
			}, [options]);

			const handleCatOpen = useCallback((fn, i) => {
				setCatOpen((catOpen) => {
					const newCatOpen = [
						...catOpen.slice(0, i),
						fn(catOpen[i]),
						...catOpen.slice(i + 1),
					];
					return newCatOpen;
				});
			}, []);

			const renderNestedOptions = (
				options,
				search,
				optionsTestIdKeyPrefix
			) => {
				return Object.entries(options).map(([key, subOptions], i) => (
					<Collapsable
						// variants={anim.selectOption}
						className="select-option-group"
						key={key}
						open={catOpen[i]}
						setOpen={(fn) => handleCatOpen(fn, i)}
						heading={
							<>
								{Object.entries(subOptions).reduce(
									(found, [opt]) => {
										return (
											found || value.indexOf(opt) !== -1
										);
									},
									false
								) && (
									<span className="select-option-group-icon">
										<MdCheck />
									</span>
								)}
								{key}
							</>
						}
					>
						{renderOptions(
							subOptions,
							search,
							optionsTestIdKeyPrefix
						)}
					</Collapsable>
				));
			};

			const handleFocus = () => {
				if (typeof onFocus === "function") {
					onFocus({ target: { name } });
				}
			};

			const handleMouseEnter = () => {
				if (typeof onMouseEnter === "function") {
					onMouseEnter({ target: { name } });
				}
			};

			const handleMouseLeave = () => {
				if (typeof onMouseLeave === "function") {
					onMouseLeave({ target: { name } });
				}
			};

			const [showValue, hasValue] = useMemo(() => {
				let selected = 0;
				const res =
					multiple && value.length > 0
						? // ? !inDraft || !liveValue || !Array.isArray(liveValue)
						  value
								.filter((key) => !!flatOptions[key])
								.map((key) => {
									selected++;
									if (miniBadge) {
										return (
											<MiniBadge key={flatOptions[key]}>
												{flatOptions[key]}
											</MiniBadge>
										);
									}
									return (
										<BadgeButton
											key={flatOptions[key]}
											listItem
										>
											{flatOptions[key]}
										</BadgeButton>
									);
								})
						: flatOptions[value];
				return [res, multiple ? selected : flatOptions[value]];
			}, [value, multiple, flatOptions, miniBadge]);

			const Container = useMemo(
				() => (inline ? "span" : "section"),
				[inline]
			);

			return (
				<Container
					className={`select-container ${containerClassName}`}
					{...rest}
				>
					{label && (
						<section className="select-label-container">
							<Label
								required={required}
								className="select-label"
								inDraft={inDraft}
							>
								{label}
							</Label>

							{tooltipText && (
								<QuestionTooltip
									className="select-tooltip-icon"
									tooltipClassName="select-tooltip"
									heading={tooltipTitle}
								>
									{tooltipText}
								</QuestionTooltip>
							)}
						</section>
					)}
					{error && open && (
						<FormStatus className="select-error">
							{error}{" "}
						</FormStatus>
					)}
					<Container
						ref={selectRef}
						id={uid}
						className={`select ${minimal ? "select-minimal" : ""} ${
							padded ? "select-padded" : ""
						} ${
							options[value] || value?.length
								? ""
								: "select-empty"
						} ${expandable ? "select-expandable" : ""} ${
							noStyle ? "select-no-style" : ""
						} ${className}`}
						style={{
							zIndex: open ? 17 : 15,
							width: expandable && open ? "200%" : "",
							...style,
						}}
						tabIndex={0}
						onFocus={handleFocus}
						onBlur={() =>
							typeof onBlur === "function"
								? onBlur({ target: { name } })
								: null
						}
						onMouseEnter={handleMouseEnter}
						onMouseLeave={handleMouseLeave}
					>
						<Container
							className={`select-selected ${
								miniBadge ? "mini-badge-select-selected" : ""
							}`}
							ref={ref}
							onClick={handleToggle}
							data-testid={testId}
						>
							{loading && <Loader small />}
							{hasValue
								? !isFunction(renderInlineValue)
									? showValue
									: renderInlineValue({ value: showValue })
								: placeholder}
						</Container>
						{!disabled && (
							<motion.span
								className={`select-icon${
									noStyle ? " select-icon-no-style" : ""
								}`}
								animate={{ rotate: open ? 180 : 0 }}
								onClick={handleToggle}
							>
								<DownIcon />
							</motion.span>
						)}
						<SelectOptions
							open={open}
							setOpen={setOpen}
							label={sheetLabel}
							cancelLabel={sheetCancelLabel}
							icon={sheetIcon}
							expandable={expandable}
							loading={loading}
							options={options}
							searchable={searchable}
							searchPlaceholder={searchPlaceholder}
							searchClassName={searchClassName}
							searchRef={searchRef}
							search={search}
							setSearch={setSearch}
							nested={nested}
							renderNestedOptions={renderNestedOptions}
							renderOptions={renderOptions}
							emptyMessage={emptyMessage}
							independantExpandable={independantExpandable}
							renderExpandableArea={renderExpandableArea}
							mapExpandableAreaProps={mapExpandableAreaProps}
							data={data}
							highlightedKey={highlightedKey}
							showCheck={showCheck}
							optionsTestIdKeyPrefix={optionsTestIdKeyPrefix}
						/>
					</Container>
				</Container>
			);
		}
	)
);

const DropdownSelect = memo(
	({ options, gradient = false, className = "", ...rest }) => {
		const parsedOptions = useMemo(() => {
			return Object.entries(options).reduce((acc, [key, value]) => {
				return { ...acc, [key]: value.text };
			}, {});
		}, [options]);

		const handleChange = useCallback(
			({ target: { value } }) => {
				if (typeof options[value].action === "function")
					options[value].action();
			},
			[options]
		);

		return (
			<Select
				className={`dropdown-select ${
					gradient ? "dropdown-select-gradient" : ""
				} ${className}`}
				options={parsedOptions}
				onChange={handleChange}
				padded
				{...rest}
			/>
		);
	}
);

// Higher level Remote data MultiSelect -> Builds on top of <Select>
// [NOTE] <Select> Should be used for static data, even for multi-selects!

const MultiSelect = memo(
	React.forwardRef(
		(
			{
				fetchUrl = false,
				saveUrl = false,
				remoteKey,
				mapper,
				customMapper,
				localOptions,
				dataFilter,
				reload,
				value,
				onChange,
				draftable = false,
				setUser,
				loggedOut = false,
				name,
				multiple = true,
				onOpen,
				onClose,
				onSave,
				className = "",
				...props
			},
			ref
		) => {
			const allData = useRef();
			const [options, setOptions] = useState(localOptions ?? {});

			const [{ loading, data, error }, load, reset] = useResource(
				{
					url: fetchUrl,
					method: "GET",
				},
				true,
				!loggedOut
			);

			const [payload, setPayload] = useState({
				url: saveUrl,
				method: "PUT",
				data: [],
			});
			const [
				{ data: saveData, error: saveError, loading: saveLoading },
				save,
				resetSave,
			] = useResource(payload, false);

			const handleOpen = useCallback(() => {
				if (loading) {
					return;
				}
				if (onOpen && typeof onOpen === "function") {
					onOpen();
				}
				if (fetchUrl) {
					load();
				}
			}, [loading, load, onOpen, fetchUrl]);

			const handleClose = useCallback(
				({ target: { away } }) => {
					if (onClose && typeof onClose === "function") {
						onClose();
					}
					if (!saveUrl) {
						return;
					}
					setPayload((payload) => ({
						...payload,
						data: { [remoteKey]: value },
					}));
					save();
				},
				[saveUrl, save, value, remoteKey, onClose]
			);

			useEffect(() => {
				if (!saveData) {
					return;
				}

				if (typeof setUser === "function") {
					setUser((user) => {
						const newUser = {
							...user,
							[draftable ? "draft" : "publisher"]: {
								...user[draftable ? "draft" : "publisher"],
								...saveData,
							},
						};
						if (saveData.under_review) {
							newUser.publisher.under_review = true;
						}
						return newUser;
					});
				}
				if (typeof onSave === "function") {
					onSave({ target: { name, value: saveData } });
				}
				resetSave();
			}, [saveData, resetSave, setUser, draftable, onSave, name, value]);

			useEffect(() => {
				if (!data) {
					return;
				}
				let processingData = data;
				if (dataFilter && typeof dataFilter === "function") {
					processingData = dataFilter(data);
				}
				allData.current = processingData;

				setOptions(mapper(processingData));
				reset();
			}, [data, dataFilter, mapper, reset]);

			const handleChange = useCallback(
				({ target: { value: newVal, name } }) => {
					if (!multiple) {
						if (onClose && typeof onClose === "function") {
							onClose();
						}
						if (
							customMapper &&
							typeof customMapper === "function"
						) {
							return onChange({
								target: {
									value: newVal,
									name,
									customData: customMapper(
										allData.current,
										newVal
									),
								},
							});
						}

						// exlicitly for single dropdown select save
						if (saveUrl) {
							setPayload((payload) => ({
								...payload,
								data: { [remoteKey]: newVal },
							}));
							save();
						}
						return onChange({
							target: { value: newVal, name },
						});
					}
					const idx = value.indexOf(newVal);
					if (idx === -1) {
						onChange({
							target: { value: [...value, newVal], name },
						});
					} else {
						onChange({
							target: {
								value: [
									...value.slice(0, idx),
									...value.slice(idx + 1),
								],
								name,
							},
						});
					}
				},
				[
					value,
					onChange,
					onClose,
					multiple,
					customMapper,
					saveUrl,
					remoteKey,
					save,
				]
			);

			return (
				<DiscoSelect
					data={allData}
					multiple={multiple}
					onOpen={handleOpen}
					loading={loading || saveLoading}
					onChange={handleChange}
					value={value}
					onClose={handleClose}
					error={saveError ? parseError(saveError) : false}
					options={options}
					searchable
					name={name}
					className={`multi-select ${className}`}
					{...props}
					ref={ref}
				/>
			);
		}
	)
);

const OtherSelect = memo(
	forwardRef(({ onChange, name, ...props }, ref) => {
		const [custom, setCustom] = useState(false);

		const handleCustomChange = useCallback(
			({ target: { checked } }) => {
				if (!checked) onChange({ target: { name, value: "" } });
				setCustom(checked);
			},
			[name, onChange]
		);

		return (
			<>
				{custom ? (
					<LabeledInput
						onChange={onChange}
						name={name}
						ref={ref}
						{...props}
					/>
				) : (
					<Select
						onChange={onChange}
						name={name}
						ref={ref}
						{...props}
					/>
				)}
				<Label className="custom-select-label">
					Use custom value
					<Checkbox checked={custom} onChange={handleCustomChange} />
				</Label>
			</>
		);
	})
);

const CopyPasteBlock = memo(({ children, actionLink = false }) => {
	const ref = useRef();
	if (!children) {
		return null;
	}
	return (
		<section className="copy-paste-block">
			{/* <img src={template.preview_image} alt="" /> */}
			<Input textarea readOnly ref={ref} value={children} />
			<section className="copy-paste-block-action">
				<FilledButton
					onClick={() => {
						ref.current.select();
						document.execCommand("copy");
					}}
				>
					Copy Template
				</FilledButton>
				{actionLink && (
					<a
						href={actionLink}
						target="_blank"
						rel="noopener noreferrer"
					>
						<FilledButton light forward>
							Open Shopify Settings
						</FilledButton>
					</a>
				)}
			</section>
		</section>
	);
});

// Toggle
const Toggle = memo(
	({
		toggle,
		disabled,
		setToggleState,
		stopPropagation,
		className,
		beforeToggleInit,
		dummy = false,
		inverseToggle = false,
		trafficLight = false,
		tooltip,
	}) => {
		const toggleSwitch = (e) => {
			if (dummy) return;
			if (beforeToggleInit && typeof beforeToggleInit === "function") {
				const resp = beforeToggleInit();
				if (!resp) {
					return;
				}
			}
			if (!stopPropagation) {
				e.stopPropagation();
			}
			if (!disabled) {
				setToggleState(!toggle);
			}
		};

		const [open, setOpen] = useState(false);

		return (
			<div
				className={`toggle ${
					dummy ? "toggle-dummy" : ""
				} ${className} ${trafficLight ? "toggle-traffic-light" : ""}`}
				data-toggle={inverseToggle ? !toggle : toggle}
				onClick={toggleSwitch}
				onMouseEnter={
					dummy
						? () => {
								setOpen(true);
						  }
						: null
				}
				onMouseLeave={
					dummy
						? () => {
								setOpen(false);
						  }
						: null
				}
			>
				{dummy ? (toggle ? "on" : "off") : null}
				<motion.div
					className="toggle-handle"
					animate={{
						right: (inverseToggle ? !toggle : toggle)
							? 2
							: "initial",
						left: (inverseToggle ? toggle : !toggle)
							? 2
							: "initial",
					}}
					transition={{
						type: "spring",
						stiffness: 700,
						damping: 30,
					}}
				>
					{dummy ? (
						"?"
					) : trafficLight ? (
						<span>{toggle ? <MdCheck /> : <MdClose />}</span>
					) : null}
				</motion.div>
				{dummy && (
					<Tooltip heading={tooltip?.heading} open={open}>
						{tooltip?.text}
					</Tooltip>
				)}
			</div>
		);
	}
);

// Button
const Button = memo(
	React.forwardRef(function (
		{
			children,
			type = "submit",
			light,
			small,
			dark,
			grayStroke,
			rounded,
			gradient = false,
			skinny = false,
			discoGradient = false,
			grayGradient = false,
			large,
			xLarge,
			gray = false,
			icon = false,
			shadow = false,
			className = "",
			...props
		},
		ref
	) {
		return (
			<button
				type={type}
				ref={ref}
				className={`btn ${className} ${rounded ? "btn-rounded" : ""} ${
					light ? "btn-light" : ""
				} ${gray ? "btn-gray" : ""}  ${dark ? "btn-dark" : ""} ${
					small ? "btn-small" : ""
				} ${large ? "btn-large" : ""} ${xLarge ? "btn-x-large" : ""} ${
					discoGradient ? "btn-disco-gradient" : ""
				} ${grayStroke ? "btn-gray-stroke" : ""} ${
					grayGradient ? "btn-gray-gradient" : ""
				} ${gradient ? "btn-gradient" : ""} ${
					icon ? "btn-with-icon" : ""
				} ${shadow ? "btn-shadow" : ""} ${skinny ? "btn-skinny" : ""}`}
				{...props}
			>
				{children}
			</button>
		);
	})
);

const IconButton = memo(
	React.forwardRef(
		(
			{
				icon,
				iconClassName = "",
				children,
				darkText,
				rounded,
				className = "",
				...rest
			},
			ref
		) => {
			return (
				<Button
					light
					ref={ref}
					className={`icon-btn ${rounded ? "icon-btn-rounded" : ""} ${
						darkText ? "icon-btn-dark-text" : ""
					} ${!children ? "icon-btn-empty" : ""} ${className}`}
					{...rest}
				>
					<span className={iconClassName}>{icon}</span>
					{children}
				</Button>
			);
		}
	)
);

const FacebookButton = React.memo(({ ...rest }) => {
	return (
		<Button
			type="button"
			className="facebook-auth-btn"
			onClick={() => {
				window.location.href = FACEBOOK_APP_URL;
			}}
			{...rest}
		>
			<img src={FacebookOfficialLight} alt="Facebook logo" />
			Continue with Facebook
		</Button>
	);
});

const StatusToggle = memo(function ({
	className = "",
	children,
	heading,
	text,
	url,
	onUpdate,
	user,
	setUser,
	dataSelector,
	remoteKey,
}) {
	const [payload, setPayload] = useState({});

	const [{ data, loading, error }, toggle, reset] = useResource(
		{
			url,
			method: "PUT",
			data: payload,
		},
		false
	);

	useEffect(() => {
		if (data) {
			const newVal =
				typeof data[remoteKey] !== "undefined"
					? data[remoteKey]
					: data.brand[remoteKey];
			setUser((user) => {
				const newUser = {
					...user,
					publisher: {
						...user.publisher,
						[remoteKey]: newVal,
					},
				};

				return newUser;
			});
			if (typeof onUpdate === "function") {
				onUpdate(data[remoteKey]);
			}
			reset();
		}
	}, [data, reset, setUser, onUpdate, remoteKey, dataSelector]);

	const handleToggle = useCallback(
		(status) => {
			setPayload({ [remoteKey]: status });
			toggle();
		},
		[toggle, remoteKey]
	);

	const value = user.publisher[remoteKey];

	const ToggleSection = memo(() => {
		return (
			<section className="status-toggle-main">
				{loading && <Loader small />}
				<Toggle
					toggle={value}
					disable={loading}
					setToggleState={handleToggle}
				/>
			</section>
		);
	});

	return (
		<section className={`status-toggle ${className}`}>
			{heading ? (
				<>
					<ToggleSection />
					<section className="status-toggle-text">
						<h3>{heading}</h3>
						<p>{text}</p>
					</section>
				</>
			) : (
				<>
					<h4>{children}</h4> <ToggleSection />{" "}
				</>
			)}

			{error && <FormStatus>{parseError(error)}</FormStatus>}
		</section>
	);
});

// Form status
const FormStatus = memo(function ({
	children,
	type = "error",
	className = "",
	outlined = false,
	...props
}) {
	return (
		<motion.div
			className={`form-status ${
				type === "success"
					? "form-status-success"
					: type === "theme"
					? "form-status-theme"
					: type === "yellow"
					? "form-status-yellow"
					: ""
			} ${className} ${outlined ? "form-status-outlined" : ""}`}
			{...props}
		>
			{children}
		</motion.div>
	);
});

const TagButton = memo(
	React.forwardRef(function (
		{
			children,
			icon = null,
			type = "button",

			light = false,
			dark = false,
			red = false,
			green = false,

			className = "",
			...props
		},
		ref
	) {
		return (
			<button
				type={type}
				ref={ref}
				className={`btn tag-btn
					${light ? "tag-btn-light" : ""} 
					${dark ? "tag-btn-dark" : ""} 
					${green ? "tag-btn-green" : ""}  
					${red ? "tag-btn-red" : ""} 
					${!children ? "tag-btn-empty" : ""} 
					
					${className}
				
					`}
				{...props}
			>
				{icon && <span className="tag-btn-icon">{icon}</span>}
				{children}
			</button>
		);
	})
);

// Badge Button
const BadgeButton = memo(
	React.forwardRef(function (
		{
			children,
			small = false,
			type = "button",
			light = false,
			dark = false,
			listItem = false,
			disabled = false,
			className = "",
			...props
		},
		ref
	) {
		return (
			<button
				type={type}
				ref={ref}
				disabled={disabled}
				className={`btn badge-btn ${light ? "badge-btn-light" : ""} ${
					dark ? "badge-btn-dark" : ""
				} ${small ? "badge-btn-small" : ""}  ${
					listItem ? "badge-btn-list-item badge-btn-small" : ""
				} ${className} ${disabled ? "badge-btn-disabled" : ""}`}
				{...props}
			>
				{children}
			</button>
		);
	})
);

const ToggleButton = memo(function ({
	className = "",
	children,
	setUser,
	toggleUrl,
	user,
	remoteKey,
	enabledText = "Enabled",
	enableText = "Enable",
	onChange,
	hidden = false,
	beforeToggle,
	triggerToggle,
	setTriggerToggle,
	...props
}) {
	const [payload, setPayload] = useState({});
	const enabled = user.publisher[remoteKey];

	const [{ data, loading, error }, toggle, reset] = useResource(
		{
			url: toggleUrl,
			method: "PUT",
			data: payload,
		},
		false
	);

	useEffect(() => {
		if (data) {
			const newVal =
				typeof data[remoteKey] === "undefined"
					? data.brand[remoteKey]
					: data[remoteKey];

			setUser((user) => {
				const newUser = {
					...user,
					publisher: {
						...user.publisher,
						[remoteKey]: newVal,
					},
				};
				return newUser;
			});
			if (setTriggerToggle && typeof setTriggerToggle === "function") {
				setTriggerToggle(false);
			}
			if (typeof onChange === "function") {
				onChange(newVal, data);
			}
			reset();
		}
	}, [data, reset, setUser, remoteKey, onChange, setTriggerToggle]);

	useEffect(() => {
		if (!error) {
			return;
		}
		if (setTriggerToggle && typeof setTriggerToggle === "function") {
			setTriggerToggle(false);
		}
	}, [error, setTriggerToggle]);

	const handleToggle = useCallback(
		(e) => {
			if (e) {
				e.stopPropagation();
			}
			if (typeof beforeToggle === "function") {
				const canToggle = beforeToggle();
				if (!canToggle) {
					return;
				}
			}
			setPayload({ [remoteKey]: !user.publisher[remoteKey] });
			toggle();
		},
		[toggle, remoteKey, user, beforeToggle]
	);

	useEffect(() => {
		if (!triggerToggle) {
			return;
		}
		handleToggle();
	}, [triggerToggle, handleToggle]);

	if (hidden) {
		return null;
	}

	return (
		<RemoteButton
			className={`toggle-btn`}
			onClick={handleToggle}
			loading={loading}
			enabled={enabled}
			enableText={enableText}
			enabledText={enabledText}
			{...props}
		/>
	);
});

const ShopifyPayButton = memo(({ enabled }) => {
	const [{ loading, error, data }, pay, reset] = useResource(
		{ url: SHOPIFY_PAYMENT_URL, method: "POST" },
		false
	);

	const [redirect, setRedirect] = useState(false);

	useEffect(() => {
		if (!data) {
			return;
		}

		if (data.url) {
			setRedirect(true);
			window.location.href = data.url;
		}
		reset();
	}, [data, reset]);

	const handlePay = useCallback(() => {
		if (!enabled) {
			pay();
		}
	}, [pay, enabled]);

	return (
		<RemoteButton
			onClick={handlePay}
			loading={loading || redirect}
			enabled={enabled}
			enableText="Set up payments here"
			enabledText="Payments set up"
		/>
	);
});

const RemoteButton = memo(
	({
		loading,
		enabled,
		className = "",
		enableText,
		enabledText,
		children,
		filled = false,
		light = false,
		...rest
	}) => {
		const renderParent = useCallback(
			(children, props) =>
				filled ? (
					<FilledButton {...props}>{children}</FilledButton>
				) : (
					<Button {...props}>{children}</Button>
				),
			[filled]
		);

		const renderChildren = useMemo(
			() => (
				<>
					{loading && <Loader small light={filled && !light} />}
					{!loading && (
						<>
							{enabled ? (
								<>
									<span>
										<FiCheck />
									</span>
									{enabledText}
								</>
							) : (
								enableText
							)}
						</>
					)}
				</>
			),
			[enabledText, enableText, enabled, loading, filled, light]
		);
		return renderParent(renderChildren, {
			light: !filled || light,
			className: `remote-btn ${enabled ? "remote-btn-enabled" : ""} ${
				filled ? "remote-btn-filled" : ""
			} ${
				loading && filled ? "remote-btn-filled-loading" : ""
			} ${className}`,
			...rest,
		});
	}
);

// Button
const SlackButton = memo(function ({
	children,
	type = "button",
	className = "",
	filled = false,
	...props
}) {
	return (
		<a href={SLACK_URL} target="_blank" rel="noopener noreferrer">
			{!filled ? (
				<BadgeButton
					className={`slack-btn ${className} ${
						filled ? "slack-btn-dark" : ""
					}`}
				>
					<img src={slackIcon} alt="Slack icon" />
					Open Slack Channel
				</BadgeButton>
			) : (
				<Button className={`slack-btn-filled ${className}`}>
					<img src={slackIcon} alt="Slack icon" />
					Join the Slack Community
				</Button>
			)}
		</a>
	);
});

export const GUIDED_TOOLTIP_TYPES = {
	REVIEW: "review",
	DARK: "dark",
};

const BaseTooltip = memo(
	forwardRef(({ children, type, className = "", ...rest }, ref) => {
		return (
			<motion.section
				className={`base-tooltip ${
					type ? `base-tooltip-${type}` : ""
				} ${className}`}
				ref={ref}
				{...rest}
			>
				{children}
			</motion.section>
		);
	})
);

const GuidedTooltip = memo(
	({
		children,
		highlighted,
		active = false,
		highlightedProps = {},
		relativeProps = { y: 0, widthScale: 1, x: 0 },
		className = "",
		leftAlign,
		preventOverflow = false,
		persistent = false,
		type = "", // review, dark
		initialPosition,
		...rest
	}) => {
		const tooltipContRef = useRef();
		const [internalOpen, setInternalOpen] = useState(false);
		const [openTop, setOpenTop] = useState(0);

		useLayoutEffect(() => {
			if (!active) {
				return;
			}
			const selfHeight =
				tooltipContRef.current?.getBoundingClientRect?.()?.height ?? 0;
			let ans =
				(highlighted?.current?.getBoundingClientRect?.()?.y ?? 0) +
				(relativeProps?.y ?? 0);

			if (ans + selfHeight > window.innerHeight && preventOverflow) {
				ans = window.innerHeight - selfHeight;
			}
			setOpenTop(ans);
		}, [relativeProps, highlighted, preventOverflow, active]);

		return (
			<AnimatePresence>
				{(active || (persistent && internalOpen)) && (
					<BaseTooltip
						initial={
							initialPosition &&
							highlighted &&
							highlighted.current
								? {
										opacity: 0,
										top:
											highlighted.current.getBoundingClientRect()
												.y + initialPosition.y,
										left:
											highlighted.current.getBoundingClientRect()
												.x +
											initialPosition.x +
											highlighted.current.getBoundingClientRect()
												.width,
								  }
								: {
										top: "50%",
										left: "50%",
										opacity: 0,
								  }
						}
						animate={{
							opacity: 1,
							top: openTop,
							left:
								highlighted && highlighted.current
									? (highlighted?.current?.getBoundingClientRect?.()
											?.x ?? 0) +
									  relativeProps.x +
									  (leftAlign
											? -(
													tooltipContRef?.current?.getBoundingClientRect?.()
														?.width ?? 0
											  ) - 30
											: highlighted.current.getBoundingClientRect()
													.width /
											  relativeProps.widthScale)
									: highlightedProps.x +
											highlightedProps.width || 0,
							transition: {
								type: "spring",
								stiffness: 2000,
								mass: 4,
								damping: 140,
							},
						}}
						onMouseEnter={() => setInternalOpen(true)}
						onMouseLeave={() => setInternalOpen(false)}
						exit={
							initialPosition &&
							highlighted &&
							highlighted.current
								? {
										opacity: 0,
										top:
											highlighted.current.getBoundingClientRect()
												.y + initialPosition.y,
										left:
											highlighted.current.getBoundingClientRect()
												.x +
											initialPosition.x +
											highlighted.current.getBoundingClientRect()
												.width,
								  }
								: {
										top: "100%",
										left: "50%",
										opacity: 0,
								  }
						}
						ref={tooltipContRef}
						className={`guided-tooltip ${className}`}
						type={type}
					>
						{children}
					</BaseTooltip>
				)}
			</AnimatePresence>
		);
	}
);

const parseTooltip = (data, array = false) => {
	if (array) {
		return data;
	}

	const tooltip = data?.tooltip;

	if (!tooltip) {
		return null;
	}

	if (!tooltip.rules) {
		return tooltip;
	}

	const text = (
		<ul>
			{tooltip.rules.map((rule) => (
				<li>
					<span
						className={`guided-tooltip-indicator ${
							rule.validator(data.props.value)
								? "guided-tooltip-indicator-green"
								: "guided-tooltip-indicator-red"
						}`}
					>
						{rule.validator(data.props.value) ? (
							<MdCheck />
						) : (
							<MdClose />
						)}{" "}
					</span>
					{rule.text}
				</li>
			))}
		</ul>
	);

	return { ...data.tooltip, text };
};

const GuidedViewTooltip = memo(
	({ className, heading, text, error, ...rest }) => {
		return (
			<motion.section
				className={`guided-view-tooltip ${
					error ? "guided-view-tooltip-error" : ""
				} ${className || ""}`}
				layout
				{...rest}
			>
				<h2>{heading}</h2>
				<p>{text}</p>
			</motion.section>
		);
	}
);

const GuidedView = memo(
	({
		children,
		highlighted,
		tooltip = {},
		persistentTooltip = false,
		hideTooltip = false,
	}) => {
		const tooltipContRef = useRef();

		const guidedTooltip = useCallback(
			(highlighted, tooltip) => (
				<GuidedViewTooltip
					animate={{
						top:
							highlighted &&
							highlighted.current &&
							tooltipContRef.current
								? highlighted.current.getBoundingClientRect()
										.y -
								  tooltipContRef.current.getBoundingClientRect()
										.y
								: 0,
					}}
					className={tooltip?.className}
					heading={tooltip && tooltip.heading}
					text={tooltip && tooltip.text}
					error={!!tooltip?.error}
				/>
			),
			[]
		);

		return (
			<main
				className={`guided-view ${
					hideTooltip ? "guided-view-wo-tooltip" : ""
				}`}
			>
				<section className="guided-view-content">{children}</section>
				<section
					className="guided-view-tooltip-cont"
					ref={tooltipContRef}
				>
					{persistentTooltip &&
						persistentTooltip.map((persistentTooltipData) =>
							guidedTooltip(
								persistentTooltipData.ref,
								persistentTooltipData.persistentData
							)
						)}
					{!hideTooltip && guidedTooltip(highlighted, tooltip)}
				</section>
			</main>
		);
	}
);

const Form = memo(
	({
		data,
		setData,
		className = "",
		children,
		user,
		setUser,
		setHighlighted,
		setSubHighlight,
		highlighted,
		initialHighlight,
		setFormError,
		resetHighlight,
	}) => {
		const handleChange = useCallback(
			(e) => {
				const {
					target: { name, value },
				} = e;
				setData((data) => {
					return {
						...data,
						[name]: {
							...data[name],
							props: { ...data[name].props, value },
						},
					};
				});
			},
			[setData]
		);

		const handleFocus = useCallback(
			(e) => {
				if (typeof setFormError === "function") {
					setFormError({ error: "", formError: null });
				}

				if (e.target.name) {
					return setHighlighted(e.target.name);
				}
				if (e.target.dataset) {
					setHighlighted(e.target.dataset.name);
					if (typeof setSubHighlight === "function")
						setSubHighlight(e.target.dataset.customid);
					return;
				}
			},
			[setFormError, setHighlighted, setSubHighlight]
		);

		const handleBlur = useCallback(
			(e) => {
				if (e.target.name === highlighted) {
					return setHighlighted(null);
				}
				if (e.target.dataset && e.target.dataset.name === highlighted) {
					setHighlighted(null);
					if (typeof setSubHighlight === "function")
						setSubHighlight(null);
					return;
				}
			},
			[highlighted, setHighlighted, setSubHighlight]
		);

		const handleSave = useCallback(
			({ target: { name, value } }) => {
				if (
					data[name].updateOnChange &&
					typeof data[name].getUpdatedOnChange === "function"
				) {
					setData((data) => ({
						...data,
						[data[name].updateOnChange]: {
							...data[data[name].updateOnChange],
							props: {
								...data[data[name].updateOnChange].props,
								value: data[name].getUpdatedOnChange(value),
							},
						},
					}));
				}
			},
			[data, setData]
		);

		useEffect(() => {
			if (!highlighted) {
				setHighlighted(initialHighlight);
			}
		}, [initialHighlight, highlighted, setHighlighted]);

		useEffect(() => {
			if (!resetHighlight) {
				return;
			}
			setHighlighted(initialHighlight);
		}, [initialHighlight, resetHighlight, setHighlighted]);

		return (
			<BaseForm className={`form ${className}`}>
				{data
					? Object.entries(data)
							.filter(([, inp]) => !inp.hidden)
							.map(([name, inp]) =>
								inp.props.type === "custom" &&
								inp.props.customComponent ? (
									inp.props.customComponent(
										user,
										setUser,
										inp.props.ref,
										handleFocus,
										handleBlur
									)
								) : inp.props.type === "multiSelect" ? (
									<MultiSelect
										{...inp.props}
										name={name}
										key={name}
										onChange={handleChange}
										setUser={setUser}
										onSave={handleSave}
										onBlur={handleBlur}
										onFocus={handleFocus}
										onMouseEnter={handleFocus}
										onMouseLeave={handleBlur}
									/>
								) : inp.props.type === "otherSelect" ? (
									<OtherSelect
										{...inp.props}
										name={name}
										key={name}
										onChange={handleChange}
										onBlur={handleBlur}
										onFocus={handleFocus}
										onMouseEnter={handleFocus}
										onMouseLeave={handleBlur}
									/>
								) : inp.props.type === "select" ? (
									<DiscoSelect
										{...inp.props}
										name={name}
										key={name}
										onChange={handleChange}
										onBlur={handleBlur}
										onFocus={handleFocus}
										onMouseEnter={handleFocus}
										onMouseLeave={handleBlur}
									/>
								) : inp.props.type === "color" ? (
									<ColorInput
										{...inp.props}
										name={name}
										key={name}
										onChange={handleChange}
										onBlur={handleBlur}
										onFocus={handleFocus}
									/>
								) : (
									<DiscoLabeledInput
										{...inp.props}
										name={name}
										key={name}
										onChange={handleChange}
										onFocus={handleFocus}
										onBlur={handleBlur}
									/>
								)
							)
					: null}
				{children}
			</BaseForm>
		);
	}
);

export const TargetTooltip = memo(
	({
		target,
		children,
		open,
		margin,
		leftAlign,
		movement,
		className = "",
		width,
		...rest
	}) => {
		const self = useRef();

		const targetBox = target?.current
			? target.current.getBoundingClientRect()
			: { x: window.innerWidth / 2, y: window.innerHeight / 2 };

		const x =
			targetBox.x +
			(leftAlign ? -width : targetBox.width) +
			(margin?.x ?? 0) * (leftAlign ? -1 : 1);
		const y = targetBox.y + (margin?.y ?? 0);

		return (
			open && (
				<BaseTooltip
					ref={self}
					style={{ width: `${width}px` }}
					animate={{
						opacity: open ? 1 : 0,
						...(open ? { left: `${x}px`, top: `${y}px` } : {}),
					}}
					initial={{
						opacity: 0,
						left: `${x - (movement?.x ?? 20)}px`,
						top: `${y - (movement?.y ?? 20)}px`,
					}}
					className={`target-tooltip ${
						!open ? "target-tooltip-closed" : ""
					} ${className}`}
				>
					{children}
				</BaseTooltip>
			)
		);
	}
);

const gridFormTooltipMargin = { x: 20 };

const GridForm = memo(
	({
		data,
		setData,
		className = "",
		user,
		setUser,
		formError,
		setFormError,
		resetHighlight = false,
		children,
		tooltipMargin,
	}) => {
		const [highlighted, setHighlighted] = useState(null);

		const leftAlign = useMemo(() => {
			if (!formError?.ref && !data?.[highlighted]?.props?.ref?.current)
				return false;
			const box = (
				formError?.ref?.current
					? formError.ref.current
					: data[highlighted].props.ref.current
			).getBoundingClientRect();
			return box.x > window.innerWidth * 0.7;
		}, [highlighted, data, formError]);

		const lastRef = useRef();

		useEffect(() => {
			lastRef.current =
				(formError?.ref?.current
					? formError.ref.current
					: data?.[highlighted]?.props?.ref?.current
					? data[highlighted].props.ref.current
					: null) || lastRef.current;
		}, [formError, data, highlighted]);

		const highlightedRef =
			(formError?.ref?.current
				? formError.ref
				: data?.[highlighted]?.props?.ref?.current
				? data[highlighted].props.ref
				: null) || lastRef;

		return (
			<>
				<Form
					className={`grid-form ${className}`}
					data={data}
					setData={setData}
					user={user}
					setUser={setUser}
					setHighlighted={setHighlighted}
					highlighted={highlighted}
					setFormError={setFormError}
					resetHighlight={resetHighlight}
					listenBlur
				>
					{children}
				</Form>
				<TargetTooltip
					open={!!formError?.error || !!highlighted}
					target={highlightedRef}
					className={`grid-form-tooltip ${
						formError?.error ? "grid-form-tooltip-error" : ""
					}`}
					leftAlign={leftAlign}
					width={350}
					margin={tooltipMargin ?? gridFormTooltipMargin}
				>
					<h3>
						{formError?.error
							? formError.error.heading
							: data?.[highlighted]?.tooltip?.heading}
					</h3>
					<p>
						{formError?.error
							? formError.error.text
							: data?.[highlighted]?.tooltip?.text}
					</p>
				</TargetTooltip>
			</>
		);
	}
);

const GuidedForm = memo(
	({
		data,
		setData,
		className = "",
		user,
		setUser,
		formError,
		setFormError,
		initialHighlight,
		resetHighlight = false,
		children,
		...rest
	}) => {
		const [highlighted, setHighlighted] = useState(initialHighlight);
		const [subHighlight, setSubHighlight] = useState(0);

		return (
			<GuidedView
				tooltip={
					formError && formError.error
						? { ...formError.error, error: true }
						: data[highlighted]
						? Array.isArray(data[highlighted].tooltip)
							? parseTooltip(
									data[highlighted].tooltip[subHighlight],
									true
							  )
							: parseTooltip(data[highlighted])
						: {}
				}
				highlighted={
					formError && formError.ref
						? formError.ref
						: data[highlighted]
						? Array.isArray(data[highlighted].props.ref)
							? data[highlighted].props.ref[subHighlight]
							: data[highlighted].props.ref
						: null
				}
				{...rest}
			>
				<Form
					className={`guided-form ${className}`}
					data={data}
					setData={setData}
					user={user}
					setUser={setUser}
					setHighlighted={setHighlighted}
					setSubHighlight={setSubHighlight}
					highlighted={highlighted}
					initialHighlight={initialHighlight}
					setFormError={setFormError}
					resetHighlight={resetHighlight}
				>
					{children}
				</Form>
			</GuidedView>
		);
	}
);

const Card = memo(({ className = "", children, ...props }) => (
	<motion.section className={`card ${className}`} {...props}>
		{children}{" "}
	</motion.section>
));

const FullCard = memo(({ className = "", children, ...props }) => (
	<section className={`full-card ${className}`} {...props}>
		{children}
	</section>
));

const FloatingCard = memo(({ className = "", children, ...props }) => (
	<motion.section className={`floating-card ${className}`} {...props}>
		{children}
	</motion.section>
));

export const SquareImgTile = memo(
	({
		className = "",
		width,
		borderLess,
		circular = false,
		sharp,
		src,
		children,
		animationProps,
		...props
	}) => (
		<motion.section
			className={`square-img-tile  ${
				circular ? "square-img-tile-circular" : ""
			} ${sharp ? "square-img-tile-sharp" : ""} ${
				borderLess ? "square-img-tile-border-less" : ""
			} ${className}`}
			style={width ? { width: width } : {}}
			{...animationProps}
		>
			{children}
			{src ? <img alt={props?.alt || ""} {...props} src={src} /> : null}
		</motion.section>
	)
);

const GradientCard = memo(
	({
		className = "",
		children,
		type,
		heading,
		subheading,
		contentOnly,
		...props
	}) => {
		return (
			<FullCard
				className={`gradient-card ${className} ${
					type ? `gradient-card-${type}` : ""
				}`}
				{...props}
			>
				{!contentOnly && (
					<>
						<h2 className="gradient-card-heading">{heading}</h2>
						<h2 className="gradient-card-subheading">
							{subheading}
						</h2>
					</>
				)}
				<section className="gradient-card-content">{children}</section>
			</FullCard>
		);
	}
);

const Label = memo(
	({
		className = "",
		children,
		required,
		inDraft = false,
		// tooltipText = "",
		// tooltipTitle = "What does this mean?",
		...props
	}) => (
		<label className={`label ${className}`}>
			{inDraft && <span className="label-draft-badge" />}
			{required && !inDraft && <span className="label-required">* </span>}
			{/* {tooltipText && (
				<QuestionTooltip
					className="core-label-tooltip-icon"
					tooltipClassName="core-label-tooltip"
					heading={tooltipTitle}
				>
					{tooltipText}
				</QuestionTooltip>
			)} */}
			{children}
		</label>
	)
);

const MotionLabel = memo(({ className = "", children, ...props }) => (
	<motion.label className={`label ${className}`} {...props}>
		{" "}
		{children}{" "}
	</motion.label>
));

const MotionCard = memo(
	React.forwardRef(({ className = "", children, ...props }, ref) => (
		<motion.section ref={ref} className={`card ${className}`} {...props}>
			{" "}
			{children}{" "}
		</motion.section>
	))
);

// Form
const BaseForm = memo(function ({
	children,
	heading = false,
	className = "",
	...props
}) {
	return (
		<form className={`base-form ${className}`} {...props}>
			{heading && <h3 className="base-form-head">{heading}</h3>}
			{children}
		</form>
	);
});

const Boolean = memo(({ value, className }) => {
	return (
		<>
			<IconContext.Provider
				value={{
					className: `${className} ${
						!value ? `${className}-red` : ""
					}`,
				}}
			>
				{value ? <BsCheckAll /> : <RiCloseLine />}
			</IconContext.Provider>
			{value ? "Yes" : "No"}
		</>
	);
});

const Checkbox = memo(({ className, indeterminate, onClick, ...rest }) => {
	return (
		<label className={`checkbox ${className}`} onClick={onClick}>
			<input type="checkbox" {...rest} />
			<div className="checkbox-checkmark">
				{indeterminate ? <HiMinus /> : <FiCheck />}
			</div>
		</label>
	);
});

const Tabs = memo(({ className, options, onChange, ...rest }) => {
	const handleClick = (e) => {
		return isFunction(onChange)
			? onChange(e.target.dataset.name, e.target?.dataset?.idx || -1)
			: null;
	};

	return (
		<PaddedContainer
			className={genClassName({ base: "tabs", additional: className })}
		>
			{options.map((option, idx) => {
				const {
					active,
					className,
					name,
					"data-testid": testId,
				} = option;
				return (
					<Text
						color={FONT_COLOR.DARK}
						size={FONT_SIZE.BODY}
						vPadding={SPACING.TINY}
						className={genClassName({
							base: "tab",
							conditionals: {
								"tab-active": active,
							},
							additional: className || "",
						})}
						key={name}
						data-name={name}
						data-idx={idx}
						onClick={handleClick}
						data-testid={testId}
					>
						{name}
					</Text>
				);
			})}
		</PaddedContainer>
	);
});

const Widget = memo(
	({
		user,
		refresh = false,
		onRefresh = false,
		className = "",
		self = false,
		live = false,
		previewID = false,
		onboarding = false,
		type = "",
	}) => {
		const iframeRef = useRef(null);

		useEffect(() => {
			if (refresh) {
				if (iframeRef.current) {
					const url = iframeRef.current.src;
					iframeRef.current.src = url;
				}
				if (typeof onRefresh === "function") {
					onRefresh();
				}
			}
		}, [refresh, onRefresh]);

		useEffect(() => {
			const setDims = ({ data: newHeight } = {}) => {
				if (
					newHeight &&
					!isNaN(newHeight) &&
					iframeRef.current !== null
				) {
					iframeRef.current.style.height = newHeight + "px";
				}
			};
			window.addEventListener("message", setDims);
			return () => {
				window.removeEventListener("message", setDims);
			};
		}, []);

		const src = useMemo(
			() =>
				WIDGET_URL({
					publisher: user.publisher.remote_id,
					self,
					type,
					live,
					previewID,
					onboarding,
				}),
			[live, onboarding, type, previewID, self, user.publisher.remote_id]
		);

		const disableWidget = useMemo(
			() =>
				(type === PREVIEW_TYPES_IDX.ALL_SURVEY &&
					!user.publisher.has_active_survey) ||
				(type === PREVIEW_TYPES_IDX.UPSELL &&
					!user.publisher.has_upsell_products),
			[type, user]
		);

		return (
			<>
				<iframe
					ref={iframeRef}
					className={genClassName({
						base: "widget",
						additional: className,
					})}
					src={src}
					title="Disco Widget"
					style={{
						display: disableWidget ? "none" : "block",
					}}
					frameBorder="0"
				/>

				{disableWidget && (
					<section className="widget widget-disabled-status">
						<FormStatus type="theme">
							{type === PREVIEW_TYPES_IDX.ALL_SURVEY
								? "Please activate a survey question to see its preview"
								: type === PREVIEW_TYPES_IDX.UPSELL
								? "Please activate 'upsell' for a product to see a preview"
								: "Unable to load preview"}
						</FormStatus>
					</section>
				)}
			</>
		);
	}
);

const PhoneSkeleton = ({ children, className = "", ...props }) => {
	return (
		<section
			className={genClassName({
				base: "universal-preview-phone",
				additional: className,
			})}
			{...props}
		>
			{children}
			<img
				src={PHONE_FRAME_IMG}
				alt=""
				className={`universal-preview-phone-img`}
			/>
		</section>
	);
};
const UniversalPreview = memo(({ className = "" }) => {
	const { user, setUser } = useIdentity();
	const explicitPreviewTypeRef = useRef(null);

	const {
		universalPreviewProps: {
			type,

			refresh,
			// this is used when we want to show and give priority to a single type of preview regardless of mini or full preview
			customPreviewData,
			// these are used to regulate general preview data
			customPreviewType,
			customPreviewID,
			disableNav,
		},
		triggerRefresh,
		onRefresh,
	} = usePreview();

	const [activePreview, setActivePreview] = useState(0);

	const previewData = useMemo(() => getPreviewOptions(), []);

	/* TODO-EASTER-EGG: hide close functionality */
	// const handleClose = useCallback(() => {
	// 	if (!isFunction(onClose)) {
	// 		return;
	// 	}
	// 	onClose();
	// }, [onClose]);

	const handleDotSwitcher = useCallback((value) => {
		setActivePreview(value);
	}, []);

	useEffect(() => {
		if (!type) {
			explicitPreviewTypeRef.current = null;
			return;
		}
		let reqPreviewIdx = previewData.findIndex(
			(previewDataInstance) => previewDataInstance.type === type
		);
		if (reqPreviewIdx < 0) {
			explicitPreviewTypeRef.current = null;
			return;
		}
		explicitPreviewTypeRef.current = previewData[reqPreviewIdx];
		setActivePreview(reqPreviewIdx);
		return;
	}, [type, previewData, customPreviewType]);

	useEffect(() => {
		triggerRefresh();
	}, [triggerRefresh]);

	const navigate = useNavigate();
	const handleRedirectMessage = ({ name, queryParams }) => {
		navigate({
			pathname: getPlatformPath(name, user),
			search: queryParams,
		});
	};
	useMessage("PLATFORM_INTERNAL_REDIRECT", handleRedirectMessage);

	return (
		<motion.section
			className={genClassName({
				base: "universal-preview-container",
				additional: className,
			})}
			variants={anim.preview}
			initial="initial"
			animate="animate"
			layout
		>
			<motion.header className="universal-preview-header" layout>
				<motion.h3>
					{customPreviewData?.title ||
						(explicitPreviewTypeRef.current && customPreviewType
							? explicitPreviewTypeRef.current.title
							: previewData[activePreview].title)}
				</motion.h3>
				<div className={`universal-preview-header-utils`}>
					<span
						onClick={triggerRefresh}
						style={{
							fontSize: "1.6rem",
						}}
					>
						<RefreshIcon />
					</span>
					{/* TODO-EASTER-EGG: hide close functionality */}
					{/* {onClose && (
							<span onClick={handleClose}>
								<CrossIconFilled />
							</span>
						)} */}
				</div>
			</motion.header>
			<motion.section className="universal-preview-body" layout>
				<motion.section
					className="universal-preview-phone-container"
					layout
				>
					<PhoneSkeleton>
						{previewData?.[activePreview]?.type === "img" ? (
							<img
								src={previewData?.[activePreview]?.imgUrl}
								alt=""
								key={previewData?.[activePreview]?.imgUrl}
								className="universal-preview-content-img"
							/>
						) : (
							<section className="universal-preview-widget-wrapper">
								<Widget
									className="universal-preview-widget"
									type={
										customPreviewData?.type ||
										(!customPreviewType
											? previewData[activePreview].type
											: customPreviewType)
									}
									user={user}
									setUser={setUser}
									refresh={refresh}
									onRefresh={onRefresh}
									previewID={
										customPreviewData?.id ||
										(!customPreviewType || !customPreviewID
											? false
											: customPreviewID)
									}
									live
								></Widget>
							</section>
						)}
					</PhoneSkeleton>
					<section className="universal-preview-dot-switcher">
						{!customPreviewType && !disableNav && (
							<DotSwitcher
								dots={previewData.length}
								activeDot={activePreview}
								setActiveDot={handleDotSwitcher}
							/>
						)}
					</section>
				</motion.section>
			</motion.section>
		</motion.section>
	);
});

const SetupSteps = memo(({ user }) => {
	const [stepsOpen, setStepsOpen] = useState(false);
	return (
		!user.shopify_shop &&
		user.shopify_shop && (
			<>
				<SetView
					name="Script Setup"
					editText={"See Setup Steps"}
					editIcon={<VscListSelection />}
					direct={true}
					onEdit={() => setStepsOpen(true)}
					data={
						user.publisher.visible_as_publisher
							? user.publisher.verified
								? "Live"
								: ""
							: "Disabled"
					}
				/>
				<Modal
					heading="Script Setup"
					open={stepsOpen}
					className="profile-card-steps-modal"
					onClose={() => setStepsOpen(false)}
					customWidth="70%"
				>
					<ScriptSetup user={user} />
					<Button onClick={() => setStepsOpen(false)}>Done</Button>
				</Modal>
			</>
		)
	);
});

const FileInput = memo(({ onChange = false, className = "", ...rest }) => {
	const [file, setFile] = useState(null);
	const handleChange = (e) => {
		setFile(e.target.files[0]);
		if (typeof onChange === "function") {
			onChange(e);
		}
	};
	return (
		<section className={`file-input ${className}`}>
			<h3 className="file-input-selection">
				Selected <span> {file ? file.name : "None"} </span>
			</h3>
			<label>
				<input
					type="file"
					multiple={false}
					onChange={handleChange}
					{...rest}
				/>
				Select a {file && "different"} file
			</label>
		</section>
	);
});

const ImageInput = memo(
	({
		onChange = false,
		aspect = 1,
		value = false,
		newValue,
		setNewValue,
		...rest
	}) => {
		const [error, setError] = useState("");
		const [imageURL, setImageURL] = useState(value);
		const [imageName, setImageName] = useState("");
		const [isGIF, setIsGIF] = useState(false);
		const cropRef = useRef(null);

		useEffect(() => {
			if (newValue) {
				setImageURL(newValue);
				setNewValue(false);
			}
		}, [newValue, setNewValue]);

		useEffect(
			() => () => {
				return imageURL ? URL.revokeObjectURL(imageURL) : null;
			},
			[imageURL]
		);

		const handleChange = (image) => {
			if (!image) {
				return setImageURL(null);
			}
			if (!isImage(image)) {
				return setError("Please select an image");
			}
			if (image.size / 10e5 > MAX_IMG_SIZE) {
				return setError(
					`The image can not be larger than ${MAX_IMG_SIZE} MB`
				);
			}
			// disabling cropper for GIF
			const { type: imageType, name } = image;
			setIsGIF(imageType === "image/gif");
			//--------
			setImageURL(URL.createObjectURL(image));
			setImageName(name);
			if (typeof onChange === "function") {
				onChange(image, name);
			}
		};

		const handleImageUpdate = () => {
			if (!cropRef.current) {
				return;
			}
			cropRef.current
				.getCroppedCanvas()
				.toBlob(
					(file) =>
						typeof onChange === "function"
							? onChange(file, imageName)
							: null,
					"image/jpg"
				);
		};

		const initCrop = (crop) => {
			cropRef.current = crop;
			crop.element.addEventListener("cropend", handleImageUpdate);
		};

		return (
			<section
				className="image-input"
				onDrop={(e) => {
					e.preventDefault();
					handleChange(e.dataTransfer.files[0]);
				}}
				onDragOver={(e) => e.preventDefault()}
			>
				<FileInput
					{...rest}
					accept={imageTypes.map((type) => `image/${type}`).join(",")}
					onChange={(e) => handleChange(e.target.files[0])}
				/>

				{imageURL && !isGIF && (
					<Cropper
						src={imageURL}
						style={{ height: 400, width: "100%" }}
						aspectRatio={aspect}
						guides={true}
						autoCropArea={1}
						rotatable={true}
						zoomOnWheel={false}
						onInitialized={initCrop}
						minCropBoxWidth={100}
						minCropBoxHeight={100}
					/>
				)}

				<section className="image-input-desc">
					{aspect === 1 && (
						<>
							For best results, attach a square image [1:1]
							<br />
						</>
					)}
					Supported formats: <span>PNG, JPEG/JPG & GIF</span>
					<br />
					Max file size: <span>10MB</span>
				</section>

				{imageURL && isGIF && (
					<>
						<img
							src={imageURL}
							alt="Product GIF"
							style={{ maxWidth: "80%" }}
						/>
						<Label className="label-center">
							This file can not be cropped as it is a GIF
						</Label>
					</>
				)}
				{error && <FormStatus>{error}</FormStatus>}
			</section>
		);
	}
);

const CreditsView = memo(
	({
		credits,
		user,
		heading = "Balance",
		onClick,
		showUnit = true,
		action = false,
		blue = false,
		showFreeOrders = false,
		className = "",
	}) => (
		<section
			className={`credits-view ${className} ${
				blue ? "credits-view-blue" : ""
			}`}
			onClick={onClick}
		>
			<h3>{heading}</h3>
			<section className="credits-view-value">
				{Math.round(credits)} {showUnit && " credits"}
			</section>
			{/* {user && isShopifyShop(user) && showFreeOrders && (
				<>
					<h3 style={{ marginTop: "10px" }}>Free Orders </h3>
					<section className="credits-view-value">42</section>
				</>
			)} */}
			{action && <Button light>{action}</Button>}
		</section>
	)
);

const EditImage = memo(
	React.forwardRef(
		(
			{
				user,
				selector,
				draftSelector,
				underReview = false,
				name,
				className = "",
				editText = false,
				defaultValue,
				remoteKey,
				onMouseEnter,
				customRenderer,
				onDone,
				...rest
			},
			ref
		) => {
			const [editOpen, setEditOpen] = useState(false);
			const [showDraft, setShowDraft] = useState(false);
			const [fileName, setFileName] = useState("");
			const handleClose = useCallback(() => {
				setEditOpen(false);
			}, []);
			const handleDone = useCallback(
				(image, name) => {
					setFileName(name);
					if (typeof onDone === "function") onDone(image, name);
				},
				[onDone]
			);

			const handleMouseEnter = useCallback(() => {
				if (typeof onMouseEnter === "function") {
					onMouseEnter(remoteKey);
				}
			}, [onMouseEnter, remoteKey]);

			const value = underReview
				? (draftSelector && draftSelector(user)) ||
				  selector(user) ||
				  defaultValue
				: selector(user) || defaultValue;

			const handleOpen = () => setEditOpen(true);

			return (
				<>
					{typeof customRenderer === "function" ? (
						customRenderer({
							value: value,
							onOpen: handleOpen,
							ref,
							underReview: underReview,
							fileName,
							label: editText,
						})
					) : (
						<div
							className={`edit-image ${className}`}
							ref={ref}
							onMouseEnter={handleMouseEnter}
						>
							<img src={value} alt={name} />
							<div
								className="edit-image-open"
								onClick={handleOpen}
							>
								<span>
									<FiUpload />
								</span>
								{editText || "Change photo"}
							</div>
							{underReview && (
								<>
									<div
										className="edit-image-review"
										onMouseEnter={() => setShowDraft(true)}
										onMouseLeave={() => {
											setShowDraft(false);
										}}
									>
										<ClockIcon />
										<span>View Live Image</span>
									</div>
									<Tooltip
										open={showDraft}
										className="edit-image-preview"
										variants={anim.productOverlay}
									>
										<img
											src={selector(user) || defaultValue}
											alt="Current Shop Logo"
										/>
									</Tooltip>
								</>
							)}
						</div>
					)}
					<ImageModal
						open={editOpen}
						user={user}
						selector={selector}
						defaultValue={defaultValue}
						onClose={handleClose}
						remoteKey={remoteKey}
						onDone={handleDone}
						{...rest}
					/>
				</>
			);
		}
	)
);

const BadgeImageInput = memo(
	forwardRef(({ value, fileName, onOpen, defaultName, label }, ref) => {
		const track = useTrack();

		return (
			<>
				{label && <DiscoLabel>{label}</DiscoLabel>}
				<section
					className="badge-image-inp"
					ref={ref}
					onClick={(e) => {
						e.preventDefault();
						track(
							PLATFORM_BUILD_YOUR_PROFILE_CLICK_ON_CHANGE_LOGO,
							{}
						);
						onOpen();
					}}
				>
					<section className="badge-image-inp-hero">
						<img
							src={value}
							alt=""
							className="badge-image-inp-preview"
						/>
						<Badge className="badge-image-inp-name" subtle>
							{fileName || defaultName}
						</Badge>
					</section>
					<DiscoButton
						small
						secondary
						rounded
						className="badge-image-inp-open"
					>
						Change
					</DiscoButton>
				</section>
			</>
		);
	})
);

const EditModeToggle = memo(
	({ editMode, setEditMode, refresh, className = "" }) => {
		return (
			<FormStatus className={`edit-mode-toggle ${className}`}>
				<h2>
					Changes Under Review |{" "}
					{editMode ? "Edit Mode" : "Live Mode"}
				</h2>
				<p>
					Your changes will go live once we approve them
					<br />
					{editMode
						? "You are in editing mode, to see live data, enter Live mode"
						: "To enter see these changes now, enter edit mode."}
					<br />
					<BadgeButton
						small
						onClick={() => {
							setEditMode((editMode) => !editMode);
							if (typeof refresh === "function") {
								refresh();
							}
						}}
					>
						Enter {editMode ? "Live" : "Edit"} mode
					</BadgeButton>
				</p>
			</FormStatus>
		);
	}
);

const Collapsable = memo(
	({
		heading,
		className = "",
		children,
		open,
		setOpen,
		dynamicList = false,
		add = false,
		leftOpen = false,
		reorderable = false,
		collapseIcon = false,
		hideIcon = false,
		...rest
	}) => {
		const handleClick = ({ target }) => {
			if (dynamicList) {
				return;
			}
			if (
				target.classList.contains("collapsable") ||
				target.closest(".collapsable-head") ||
				target.closest(".collapsable-toggle")
			) {
				setOpen((open) => !open);
			}
		};

		const secondaryClick = () => {
			if (!dynamicList) {
				return;
			}
			setOpen((open) => !open);
		};

		return (
			<section
				className={`collapsable ${open ? "collapsable-open" : ""} ${
					leftOpen ? "collapsable-left" : ""
				} ${className}`}
				onClick={handleClick}
				{...rest}
			>
				<motion.div
					className={`collapsable-toggle ${
						leftOpen ? "collapsable-toggle-left" : ""
					}`}
					animate={{
						rotate: open
							? add
								? 45
								: 0
							: collapseIcon
							? 0
							: leftOpen
							? -90
							: 180,
					}}
					onClick={secondaryClick}
				>
					{/* {open ? <DownIcon /> : <UpIcon />} */}
					{hideIcon ? null : add ? (
						<PlusIcon />
					) : (
						collapseIcon || <DownIcon />
					)}
				</motion.div>
				{reorderable && (
					<div className="collapsable-grabber">
						<BiMenu />
					</div>
				)}
				<h2
					className={`collapsable-head ${
						leftOpen ? "collapsable-head-left" : ""
					}`}
					onClick={secondaryClick}
				>
					{heading}
				</h2>
				<AnimatePresence>
					{open && (
						<motion.section
							className={`collapsable-content ${
								leftOpen ? "collapsable-content-left" : ""
							}`}
							variants={anim.verticalSlide}
							initial="initial"
							animate="animate"
							exit="exit"
						>
							{open ? children : null}
						</motion.section>
					)}
				</AnimatePresence>
			</section>
		);
	}
);

const AccordionBanner = memo(({ children, className = "" }) => (
	<motion.section className={`accordion-banner ${className}`}>
		{children}
	</motion.section>
));

const Accordion = memo(
	({
		open,
		icon,
		heading,
		closeText,
		openText,
		loading,
		onOpen,
		onClose,
		onSave,
		children,
		className = "",
		buttonControl = false,
		dashedBorder = false,
		headerContent = null,
		disabled = false,
		buttonControlProps,
		buttonControlRef,
		openEvent,
		closeEvent,
		eventProperties,
		banner = null,
	}) => {
		const track = useTrack();

		const actuallyOpen = open && !disabled;

		const handleToggle = () => {
			if (disabled) return;
			if (open && typeof onClose === "function") {
				if (closeEvent) track(closeEvent, eventProperties);
				onClose();
			} else if (!open && typeof onOpen === "function") {
				if (openEvent) track(openEvent, eventProperties);
				onOpen();
			}
		};

		const handleClick = (e) => {
			if (actuallyOpen && typeof onSave === "function") {
				e.stopPropagation();
				onSave();
			}
		};

		return (
			<motion.section
				layout
				variants={anim.verticalSlide}
				initial="initial"
				animate="animate"
				exit="exit"
				className={`accordion ${
					dashedBorder ? "accordion-dashed" : ""
				} ${actuallyOpen ? "accordion-open" : ""} ${className}`}
			>
				{banner}
				<motion.header layout onClick={handleToggle}>
					<main>
						<img src={icon} alt="" className="accordion-icon" />
						{heading}
					</main>
					{loading ? (
						<Loader small2 />
					) : buttonControl ? (
						<Button
							className={`accordion-toggle-btn ${
								disabled ? "accordion-toggle-btn-disabled" : ""
							}`}
							onClick={handleClick}
							rounded
							skinny
							large
							gradient={!actuallyOpen}
							grayStroke={actuallyOpen}
							{...(buttonControlProps ? buttonControlProps : {})}
							ref={buttonControlRef}
						>
							{actuallyOpen ? openText : closeText}
						</Button>
					) : (
						<span
							className="accordion-toggle"
							onClick={handleClick}
						>
							{actuallyOpen ? openText : closeText}
						</span>
					)}
					{headerContent}
				</motion.header>
				{actuallyOpen && <main>{children}</main>}
			</motion.section>
		);
	}
);

export const SteppedAccordion = memo(
	({
		steps,
		bodyHeading,
		className = "",
		children,
		buttonText,
		buttonAction,
		buttonProps,
		designSystem,
		...rest
	}) => {
		if (!Array.isArray(steps)) return null;
		const Component = designSystem ? DiscoAccordion : Accordion;
		return (
			<Component
				className={`stepped-accordion ${className}`}
				buttonControl
				{...rest}
			>
				<>
					{bodyHeading && (
						<Text
							size={FONT_SIZE.LG_BODY}
							marginTop={SPACING.MEDIUM}
							marginBottom={SPACING.MEDIUM}
						>
							{bodyHeading}
						</Text>
					)}
					<PaddedContainer
						Element="main"
						className="stepped-accordion-steps"
					>
						<PaddedContainer
							marginLeft={SPACING.SMALL}
							marginRight={SPACING.SMALL}
							className="stepped-accordion-line"
						/>
						{steps.map((step, index) => (
							<PaddedContainer
								marginTop={SPACING.REGULAR}
								marginBottom={SPACING.LARGE}
								className="stepped-accordion-step"
							>
								{step.hideLine && (
									<PaddedContainer className="stepped-accordion-line-eraser" />
								)}
								<span className="stepped-accordion-step-index">
									{index + 1}
								</span>
								<Text
									size={FONT_SIZE.BODY}
									marginBottom={SPACING.TINY}
								>
									{step.heading}
								</Text>
								{step.content}
								{step.loading ? (
									<PaddedContainer
										marginTop={SPACING.REGULAR}
									>
										<Loader />
									</PaddedContainer>
								) : (
									step.buttonAction && (
										<DiscoButton
											rounded
											{...(step.buttonProps || {})}
											marginTop={SPACING.REGULAR}
											className={
												step.buttonProps?.className ||
												""
											}
											onClick={step.buttonAction}
										>
											{step.buttonText}
										</DiscoButton>
									)
								)}
								{step.error && (
									<StatusLabel marginTop={SPACING.REGULAR}>
										{parseError(step.error)}
									</StatusLabel>
								)}
							</PaddedContainer>
						))}
					</PaddedContainer>
					{children}
				</>
			</Component>
		);
	}
);

const SectionHeading = memo(
	({
		title = "",
		tooltip = false,
		tooltipTitle = "",
		className = "",
		icon,
		children,
		...props
	}) => {
		return (
			<h2 className={`core-section-heading ${className}`} {...props}>
				{icon && (
					<span className="core-section-heading-icon">{icon}</span>
				)}
				{title}
				{tooltip && (
					<QuestionTooltip heading={tooltipTitle}>
						{children}
					</QuestionTooltip>
				)}
			</h2>
		);
	}
);

const Tooltip = memo(
	({
		heading = "",
		children,
		open,
		independant = false,
		className,
		variants,
		...props
	}) => {
		const [active, setActive] = useState(false);
		return (
			<AnimatePresence>
				{(open || active) && (
					<motion.section
						className={`tooltip-content ${
							independant ? "independant-tooltip-content" : ""
						} ${className}`}
						variants={variants || anim.tooltip}
						initial="initial"
						animate="animate"
						exit="exit"
						onMouseEnter={() => setActive(true)}
						onMouseLeave={() => setActive(false)}
						{...props}
					>
						{heading !== "" && (
							<h2 className="tooltip-head">{heading}</h2>
						)}
						<p>{children}</p>
					</motion.section>
				)}
			</AnimatePresence>
		);
	}
);

const QuestionTooltip = memo(
	({
		heading = "What does this mean?",
		children,
		className = "",
		tooltipClassName = "",
		variants,
		minimal,
		filledIcon = false,
		...props
	}) => {
		const [onIcon, setOnIcon] = useState(false);

		return (
			<>
				<section
					className={`tooltip ${
						minimal ? "tooltip-minimal" : ""
					} ${className}`}
					{...props}
					onMouseEnter={() => setOnIcon(true)}
					onMouseLeave={() => setOnIcon(false)}
				>
					{filledIcon ? <QuestionIconFilled /> : <QuestionIcon />}
				</section>
				<Tooltip
					className={tooltipClassName}
					heading={heading}
					open={onIcon}
					variants={variants}
				>
					{children}
				</Tooltip>
			</>
		);
	}
);

const SubscriptionFeatureLine = memo(() => (
	<motion.div
		className="subscription-feature-line"
		initial={{ width: "0px", x: "40" }}
		animate={{
			width: "30px",
			x: "0",
			transition: { type: "springy", delay: 0.1 },
		}}
	/>
));

const SubscriptionFeatureItem = memo(({ children }) => (
	<div>
		<span
			className="subscription-features-features-icon"
			role="img"
			aria-label="Included"
		>
			<AiFillCheckCircle />
		</span>
		{children}
	</div>
));

const SubscriptionFeatures = memo(({ user }) => {
	return (
		<section className="subscription-features">
			<section className="subscription-feature-slabs">
				<div className="subscription-feature-slab">
					<span className="subscription-feature-price-highlight">
						$50 <sup>/ month</sup>
					</span>
					<SubscriptionFeatureLine />
					<section className="subscription-features-features">
						<SubscriptionFeatureItem>
							Disco membership
						</SubscriptionFeatureItem>
						<SubscriptionFeatureItem>
							Cross-promotion across our network of shops
						</SubscriptionFeatureItem>
						<SubscriptionFeatureItem>
							Slack community access
						</SubscriptionFeatureItem>
					</section>
				</div>
				<div className="subscription-separator">
					<PlusCircle />
				</div>
				<div className="subscription-feature-slab">
					<span className="subscription-feature-price-highlight">
						$0.05 <sup>/ order</sup>
					</span>
					<SubscriptionFeatureLine />
					<section className="subscription-features-features">
						<SubscriptionFeatureItem>
							Disco Pro
						</SubscriptionFeatureItem>
						<SubscriptionFeatureItem>
							Unlimited post-purchase survey responses
						</SubscriptionFeatureItem>
						<SubscriptionFeatureItem>
							Same store post-purchase upsells (PRO)
						</SubscriptionFeatureItem>
						<SubscriptionFeatureItem>
							Advanced Analytics
						</SubscriptionFeatureItem>
					</section>
					<h5>Max. Charge Limit: $1000</h5>
				</div>
			</section>
			<p>
				To be part of the Disco network, there is a monthly subscription
				fee. All memberships include a <b> 14 day trial</b>.
			</p>
		</section>
	);
});

const Preview = ({ heading, children, className = "", ...props }) => {
	return (
		<motion.div className={`modal-group ${className}`} {...props}>
			<h2>{heading}</h2>
			{children}
		</motion.div>
	);
};

const BrandPreview = memo(({ previewItem, previewTitle }) => {
	const { hero_url, name, single_liner, logo_url, products, categories } =
		previewItem ? previewItem : {};

	const handleOpen = useCallback(
		(e) => {
			e.preventDefault();
			const productWindow = window.open();
			productWindow.opener = null;
			productWindow.location = hero_url;
		},
		[hero_url]
	);

	return (
		<Preview
			heading={`${previewTitle} Preview`}
			className="modal-preview-group"
			layout
		>
			<AnimatePresence>
				{!previewItem && (
					<motion.div
						className="modal-preview-empty"
						variants={anim.preview}
						initial="initial"
						animate="animate"
						exit="exit"
						layout
					>
						Please hover over a {previewTitle} to see it's preview
					</motion.div>
				)}
				{previewItem && (
					<>
						<motion.div
							className="modal-preview-body"
							variants={anim.preview}
							initial="initial"
							animate="animate"
							exit="exit"
						>
							<div className="modal-preview-body-split">
								<motion.a
									href={hero_url}
									target="_blank"
									rel="noopener noreferrer"
									className="modal-preview-link"
								>
									<h1>{name}</h1>
								</motion.a>
								<motion.div className="modal-preview-body-text">
									{single_liner}
								</motion.div>
								<Button
									className="modal-preview-cta"
									onClick={handleOpen}
								>
									View Shop
								</Button>
							</div>
							<div className="modal-preview-body-split modal-preview-body-split-center">
								<motion.img
									src={logo_url}
									className="modal-preview-image"
								/>
							</div>
						</motion.div>
						<motion.div
							// layout
							className="modal-preview-footer"
						>
							{products.length > 0 &&
								products.map((previewItemProduct) => (
									<div
										className="modal-preview-footer-item-container"
										onClick={() => {
											window.open(
												previewItemProduct.landing_page,
												"_blank"
											);
										}}
									>
										<img
											src={previewItemProduct.photo_url}
											alt="product preview"
											className="modal-preview-footer-item-image"
										/>
										<div className="modal-preview-footer-item-overlay">
											<div className="modal-preview-footer-item-name">
												{previewItemProduct.name}
											</div>
											{previewItemProduct.discounted_price && (
												<div className="modal-preview-footer-item-price">
													$
													{
														previewItemProduct.discounted_price
													}
												</div>
											)}
										</div>
									</div>
								))}
							{products.length <= 0 && (
								<div className="modal-preview-footer-empty">
									No products found
								</div>
							)}
						</motion.div>
						<motion.div
							// layout
							className="modal-preview-footer model-preview-footer-expandable"
						>
							{categories.length > 0 &&
								categories.map((previewItemCategory, idx) => (
									<MiniBadge
										theme
										key={`${previewItemCategory.name}_${idx}`}
									>
										{previewItemCategory.name}
									</MiniBadge>
								))}
						</motion.div>
					</>
				)}
			</AnimatePresence>
		</Preview>
	);
});

const TabSwitcher = memo(
	forwardRef(
		(
			{
				label = "",
				className = "",
				tabActive,
				data,
				onChange,
				dynamicWidth = false,
				curved = false,
				dark = false,
				onFocus,
				name,
				small = false,
				loading = false,
				...props
			},
			ref
		) => {
			const parentRef = useRef();
			const parentRefProp = useRef();
			const selectedRef = useRef();
			const [dimUpdate, setDimUpdate] = useState(false);
			const [style, setStyle] = useState({
				left: "-2px",
			});

			const handleTabClick = (tabIndex) => {
				if (typeof onChange === "function") {
					onChange(tabIndex);
				}
			};

			useEffect(() => {
				if (!parentRef || !parentRef.current) {
					return;
				}
				parentRefProp.current =
					parentRef.current.getBoundingClientRect();
				setStyle((style) => ({
					...style,
					left:
						dynamicWidth && selectedRef?.current
							? `${selectedRef.current.offsetLeft - 1}px`
							: `${tabActive * (100 / data.length) - 1}%`,
					width:
						dynamicWidth && selectedRef?.current
							? `${
									selectedRef.current.getBoundingClientRect()
										.width + 2
							  }px`
							: `${(100 + 2.5 * data.length) / data.length}%`,
				}));
			}, [
				parentRef,
				data,
				tabActive,
				dynamicWidth,
				selectedRef,
				dimUpdate,
			]);

			useEffect(() => {
				if (!dynamicWidth) {
					return;
				}
				setTimeout(() => {
					setDimUpdate(true);
				}, 300);
			}, [dynamicWidth]);

			useEffect(() => {
				if (!dynamicWidth) {
					return;
				}
				setTimeout(() => {
					setDimUpdate(true);
				}, 200);
			}, [dynamicWidth]);

			return (
				<section
					className={`tab-switcher-container ${className} ${
						curved ? "tab-switcher-container-curved" : ""
					} ${dark ? "tab-switcher-container-dark" : ""} ${
						small ? "tab-switcher-container-small" : ""
					}`}
					ref={ref}
					onMouseEnter={() => {
						return typeof onFocus === "function"
							? onFocus({ target: { name } })
							: undefined;
					}}
				>
					{label && <Label>{label}</Label>}
					<section
						className="tab-switcher-background"
						ref={parentRef}
						{...props}
					>
						<div className="tab-switcher-switch" style={style} />
						{data.map((dataItem, index) => {
							const handleClick = () => {
								handleTabClick(index);
							};
							return (
								<div
									className={`tab-switcher-tab ${
										index === tabActive
											? "tab-switcher-tab-active"
											: ""
									} ${
										index === tabActive && loading
											? "tab-switcher-tab-loading"
											: ""
									}`}
									ref={
										index === tabActive ? selectedRef : null
									}
									{...dataItem.props}
									key={dataItem.name}
									onClick={handleClick}
								>
									{dataItem.before && (
										<div className="tab-switcher-tab-before">
											{dataItem.before}
										</div>
									)}
									{index === tabActive && loading ? (
										<Loader small2 />
									) : (
										dataItem.name
									)}
									{dataItem.after && (
										<div className="tab-switcher-tab-after">
											{dataItem.after}
										</div>
									)}
								</div>
							);
						})}
					</section>
				</section>
			);
		}
	)
);

const RemoteTabSwitcher = memo(
	({ remoteUrl, setUser, data, onChange, tabActive, ...rest }) => {
		const [reqOptions, setReqOptions] = useState({});
		const [{ data: remoteData, loading }, save, reset] = useResource(
			reqOptions,
			false
		);
		const handleChange = useCallback(
			(tabIndex) => {
				if (tabIndex === tabActive) return;
				if (typeof data?.[tabIndex]?.validator === "function") {
					const validation = data[tabIndex].validator();
					if (!validation) {
						return;
					}
				}
				if (data?.[tabIndex]?.remoteOptions) {
					setReqOptions({
						...data[tabIndex].remoteOptions,
						tabIndex,
					});
					save();
				} else {
					if (typeof onChange === "function") {
						onChange(tabIndex);
					}
				}
			},
			[onChange, data, save, tabActive]
		);

		useEffect(() => {
			if (!remoteData) {
				return;
			}
			if (typeof onChange === "function") {
				onChange(reqOptions.tabIndex);
			}
			setReqOptions({});
			reset();
		}, [reset, remoteData, reqOptions, onChange]);

		return (
			<TabSwitcher
				setUser={setUser}
				data={data}
				onChange={handleChange}
				tabActive={tabActive}
				loading={loading}
				{...rest}
			/>
		);
	}
);

const WidgetModuleBadge = memo(
	({
		type = "",
		className = "",
		rounded = false,
		filled = false,
		inactive = false,
		draft = false,
		children,
		...props
	}) => {
		// type = success, error
		return (
			<div
				className={`widget-module-badge  ${className} ${
					rounded ? "widget-module-badge-rounded" : ""
				} ${filled ? "widget-module-badge-filled" : ""} ${
					type ? `widget-module-badge-${type}` : ""
				} ${inactive ? "widget-module-badge-inactive" : ""} ${
					draft ? "widget-module-badge-draft" : ""
				}`}
				{...props}
			>
				{children}
			</div>
		);
	}
);

export const NewReviewTooltip = memo(({ ...rest }) => {
	return (
		<InformationTooltip
			activator={<ClockIcon />}
			activatorClassName="new-review-tooltip-activator"
			activatorSize={24}
			type={TOOLTIP_TYPE.REVIEW}
			{...rest}
			activatorProps={{
				hPadding: SPACING.MICRO,
				vPadding: SPACING.MICRO,
				...(rest?.activatorProps || {}),
			}}
		>
			<Text size={"body"} thick>
				Pending Approval
			</Text>
			<Text size={"label"} color="mid" marginTop={SPACING.MICRO}>
				Your changes will go live once they have gone through our
				approval process. This can take up to 24 hours.
			</Text>
		</InformationTooltip>
	);
});
const ReviewTooltip = memo(
	({
		draftTooltipName = "",
		name = "",
		list = false,
		inline = true,
		withSecondaryTooltip = false,
		handleEnter,
		handleLeave,
		pendingApproval = false,
	}) => {
		const [open, setOpen] = useState(false);
		const selfRef = useRef(null);
		const handleMouseEnter = () => {
			setOpen(true);
			if (handleEnter && typeof handleEnter === "function") {
				handleEnter();
			}
		};

		const handleMouseLeave = () => {
			setOpen(false);
			if (handleLeave && typeof handleLeave === "function") {
				handleLeave();
			}
		};

		return (
			<>
				<motion.section
					layout
					className={`review-indicator ${
						withSecondaryTooltip !== ""
							? "review-indicator-with-secondary"
							: ""
					} ${pendingApproval ? "review-indicator-large" : ""} ${
						open && pendingApproval
							? "review-indicator-large-open"
							: ""
					}`}
					ref={selfRef}
					onMouseEnter={handleMouseEnter}
					onMouseLeave={handleMouseLeave}
				>
					<motion.span layout>
						<ClockIcon />
					</motion.span>

					{open && inline && (
						<motion.section
							className={`review-indicator-content`}
							layout
							variants={anim.dialog}
							enter="enter"
							exit="exit"
							animate="animate"
						>
							{pendingApproval ? (
								<>
									<h3>Pending Approval</h3>
									<div>
										Your changes will go live once they have
										gone through our approval process. This
										can take up to 24 hours.
									</div>
								</>
							) : (
								<>
									Your new{" "}
									<div>
										{draftTooltipName !== ""
											? draftTooltipName
											: name}
									</div>{" "}
									{list ? "are" : "is"} under review.{" "}
								</>
							)}
						</motion.section>
					)}
				</motion.section>
				{!inline && (
					<GuidedTooltip
						highlighted={selfRef}
						active={open}
						className="review-indicator-content-large"
						relativeProps={{ y: -58, widthScale: 1, x: 2 }}
						persistent
						type={GUIDED_TOOLTIP_TYPES.REVIEW}
						initialPosition={{ y: -58, widthScale: 1, x: 20 }}
					>
						<h3>Pending Approval</h3>
						<div>
							Your changes will go live once they have gone
							through our approval process. This can take up to 24
							hours.
						</div>
					</GuidedTooltip>
				)}
			</>
		);
	}
);

const TranslucentButton = memo(
	({ children, light = false, white = false, className = "", ...props }) => {
		return (
			<motion.button
				className={`btn translucent-btn ${
					light ? "translucent-btn-light" : ""
				} ${white ? "translucent-btn-white" : ""} ${className}`}
				{...props}
			>
				{children}
			</motion.button>
		);
	}
);

const FilledButton = memo(
	forwardRef(
		(
			{
				children,
				light = false,
				back = false,
				green = false,
				icon,
				forward = false,
				className = "",
				...props
			},
			ref
		) => {
			return (
				<button
					className={`${className} filled-btn ${
						light ? "filled-btn-light" : ""
					} ${green ? "filled-btn-green" : ""}`}
					{...props}
					ref={ref}
				>
					{back && (
						<span className="filled-btn-icon filled-btn-icon-left">
							<BackFilledIcon />
						</span>
					)}
					<span className="filled-btn-text">{children}</span>
					{(icon || forward) && (
						<span className="filled-btn-icon">
							{icon ? (
								icon
							) : forward ? (
								<ForwardFilledIcon />
							) : null}
						</span>
					)}
				</button>
			);
		}
	)
);

const MiniBadge = memo(
	({
		children,
		small = false,
		theme = false,
		red = false,
		yellow = false,
		gradient = false,
		discoGradient = false,
		icon = "",
		className = "",
		...props
	}) => {
		return (
			<div
				className={`mini-badge ${className} ${
					small ? "mini-badge-small" : ""
				} ${theme ? "mini-badge-theme" : ""} ${
					gradient ? "mini-badge-gradient" : ""
				} ${discoGradient ? "mini-badge-disco-gradient" : ""} ${
					red ? "mini-badge-red" : ""
				}  ${yellow ? "mini-badge-yellow" : ""}`}
				{...props}
			>
				{icon === "add" && (
					<span className="mini-badge-icon mini-badge-icon-add">
						<PlusIcon />
					</span>
				)}
				{icon === "minus" && (
					<span className="mini-badge-icon mini-badge-icon-minus">
						<HiMinus />
					</span>
				)}
				{children}
				{icon === "cross" && (
					<span className="mini-badge-icon mini-badge-icon-cross">
						<CrossIcon />
					</span>
				)}
				{icon === "addAfter" && (
					<span className="mini-badge-icon mini-badge-icon-add-after">
						<PlusIcon />
					</span>
				)}
			</div>
		);
	}
);

const ProgressBarElement = memo(
	({
		initial = false,
		final = false,
		active = false,
		className = "",
		children,
		...props
	}) => {
		return (
			<div
				className={`progress-bar-element ${className} ${
					initial ? "progress-bar-element-initial" : ""
				} ${final ? "progress-bar-element-final" : ""} ${
					active ? "progress-bar-element-active" : ""
				}`}
				{...props}
			>
				{children}
			</div>
		);
	}
);

const ProgressBar = memo(
	({
		stepData,
		activeStep,
		setActiveStep,
		className = "",
		darkTitle = false,
		title = "",
		noFinalize = false,
		noContraction = false,
		rounded = false,
		wide = false,
		...props
	}) => {
		const renderableStepData = useMemo(() => {
			const renderableData = [];
			if (stepData.length > 3 && !noContraction) {
				stepData.forEach((stepDataItem, id) => {
					if (
						id >= Math.min(activeStep, stepData.length - 2) - 1 &&
						id <= Math.max(activeStep, 1)
					) {
						renderableData.push({
							...stepDataItem,
							active: id === activeStep,
						});
					}
				});
				const rem = stepData.length - 1 - Math.max(activeStep, 1);
				if (rem > 0) {
					if (rem === 1) {
						renderableData.push({
							...stepData[activeStep + 1],
							active: false,
						});
					} else {
						renderableData.push({
							name: ` + ${rem} more`,
							active: false,
						});
					}
				}
			} else {
				stepData.forEach((stepDataItem, id) => {
					renderableData.push({
						...stepDataItem,
						active: id === activeStep,
					});
				});
			}
			if (noFinalize) {
				return renderableData;
			}
			renderableData.push({
				name: "Finalize",
				active: activeStep === stepData.length,
			});
			return renderableData;
		}, [activeStep, stepData, noContraction, noFinalize]);

		const lastDoneIndex = renderableStepData.findIndex(
			(renderableStep) => renderableStep.active
		);

		if (!renderableStepData) {
			return;
		}
		return (
			<section
				className={`progress-bar ${rounded && "progress-bar-rounded"} ${
					wide && "progress-bar-wide"
				} ${className}`}
				{...props}
			>
				{title && (
					<section
						className={`progress-bar-title ${
							darkTitle ? "progress-bar-title-dark" : ""
						}`}
					>
						{title}
					</section>
				)}
				{renderableStepData.map((renderableStepDataItem, id) => {
					return (
						<ProgressBarElement
							initial={id === 0}
							final={id === renderableStepData.length - 1}
							key={`${renderableStepDataItem.name}_progress_item`}
							active={renderableStepDataItem.active}
						>
							{wide && id < lastDoneIndex && (
								<span className="progress-bar-element-done-icon">
									<FiCheckCircle />
								</span>
							)}
							{renderableStepDataItem.name}
						</ProgressBarElement>
					);
				})}
			</section>
		);
	}
);

const ModalSelectHLine = memo(
	({ className = "", full = false, width = "96%" }) => (
		<div
			className={`modal-select-h-line ${className}`}
			style={{ width: full ? "100%" : width }}
		/>
	)
);

const ModalSelectBottomNavigator = memo(
	({
		children,
		stepped,
		currSubStep,
		currStep,
		stepProps,
		handleBack,
		handleNext,
		subStepData,
		buttonText,
		loading = false,
		showNext = true,
		interStepBack = true,
	}) => {
		usePing({ key: PING_TYPES.MODAL_SELECT_NEXT, callback: handleNext });
		usePing({ key: PING_TYPES.MODAL_SELECT_BACK, callback: handleBack });

		return (
			<div className="modal-select-main-btn-container">
				{loading ? (
					<Loader center className="modal-select-main-btn-loader" />
				) : (
					<>
						{((stepped && currSubStep !== 0) ||
							(currStep !== 0 &&
								stepProps.length > 1 &&
								interStepBack)) && (
							<FilledButton
								light
								back
								className="modal-select-main-btn-back"
								onClick={handleBack}
							>
								Back
							</FilledButton>
						)}
						{children}
						{showNext && (
							<FilledButton
								forward
								onClick={handleNext}
								className="modal-select-main-btn-next"
							>
								{!stepped || currSubStep === subStepData.length
									? buttonText || "Proceed"
									: "Next"}
							</FilledButton>
						)}
					</>
				)}
			</div>
		);
	}
);

const ModalSelectInput = memo(
	({
		stepProp,
		setStepProps,
		stepId,
		editMode,
		disabled,
		handleToggle,
		setSpecificOpen,
		onboarding,
		className,
		designSystem = false,
	}) => {
		const { user, setUser } = useIdentity();
		const {
			label,
			remoteKey,
			emptyPlaceholder,
			navToStart,
			hideInput,
			draftable,
			disabledText,
			inlineBadgeModifier,
			liveValueFilterModifier, // modifier filter is used as an "OR" operator with the original filter
			liveValueFilter, // filter is used as an "AND" operator with the original filter
			currValueFilterModifier,
			currValueFilter,
			fetchValueUrl,
			valueLoaded,
			required,
			labelRequired,
			inputProps,
			inlineName,
			inputWrapperProps: {
				card = false,
				className: inputWrapperClassName = "",
				children,
				actionText = "",
			} = {},
		} = stepProp;

		const [{ data, loading }, fetchValue] = useResource(
			{
				url: fetchValueUrl,
			},
			false
		);

		const liveValue = user?.publisher[remoteKey];
		const currValue = (
			editMode && user?.draft[remoteKey] ? user.draft : user.publisher
		)[remoteKey];

		const hasDataLoaded = !fetchValueUrl || (valueLoaded && !loading);

		// Removing support for draft mode so editable is always true
		const editable = true;

		const isDisabled = useMemo(() => {
			if (!disabled || !isFunction(disabled)) {
				return false;
			}
			if (isFunction(disabled)) {
				return disabled(user);
			}
			return disabled;
		}, [disabled, user]);

		const handleSpecificOpen = useCallback(
			(item) => {
				if (!editable) {
					return;
				}
				setSpecificOpen({
					currStep: stepId,
					searchValue: item.remote_id,
				});
				handleToggle();
			},
			[handleToggle, editable, stepId, setSpecificOpen]
		);

		const handleOpen = useCallback(() => {
			if (isFunction(navToStart) && navToStart(currValue)) {
				return handleToggle();
			}
			setSpecificOpen({
				currStep: stepId,
				searchValue: false,
			});
			handleToggle();
		}, [handleToggle, stepId, navToStart, currValue, setSpecificOpen]);

		const isPresentInList = ({ list, value }) =>
			list.findIndex((item) => item.remote_id === value?.remote_id) !==
			-1;

		const renderMiniBadge = useCallback(
			({ option, stepId, draft = false, inlineBadgeModifier }) => {
				const { remote_id, name } = option;
				const handleClick = () => handleSpecificOpen(option);
				const renderContent = isFunction(inlineBadgeModifier)
					? inlineBadgeModifier({
							option,
							list: currValue,
							draft,
					  })
					: name;

				if (designSystem) {
					return (
						<Tag
							small
							className="new-modal-list-inp-tag"
							// className="list-inp-badge"
							key={remote_id}
							onClick={handleClick}
							editable
							type={draft ? TAG_TYPE.INFO : TAG_TYPE.DEFAULT}
						>
							{renderContent}
						</Tag>
					);
				}

				return (
					<MiniBadge
						className="list-inp-badge"
						key={remote_id}
						onClick={handleClick}
						theme={!draft && !card}
						yellow={draft}
					>
						{renderContent}
					</MiniBadge>
				);
			},
			[card, currValue, handleSpecificOpen, designSystem]
		);

		const inDraft =
			editMode &&
			draftable &&
			user?.draft[remoteKey] &&
			JSON.stringify(user?.draft?.[remoteKey]) !==
				JSON.stringify(user?.publisher?.[remoteKey]);

		const isListNone =
			(Array.isArray(currValue) &&
				currValue.length === 0 &&
				(!editable || card)) || // !editable without card because button shown up so no need of text
			(isDisabled && disabledText);

		const canEdit = editable && !isDisabled;

		useEffect(() => {
			if (!fetchValueUrl || valueLoaded) {
				return;
			}
			fetchValue();
		}, [fetchValueUrl, valueLoaded, fetchValue]);

		useEffect(() => {
			if (!data || valueLoaded) {
				return;
			}
			setUser((user) => {
				let newUser = { ...user };
				if ("draft" in data) {
					newUser.draft[remoteKey] = data.draft;
				}
				if ("publisher" in data) {
					newUser.publisher[remoteKey] = data.publisher;
				}
				return newUser;
			});
			setStepProps((stepProps) => [
				...stepProps.slice(0, stepId),
				{ ...stepProps[stepId], valueLoaded: true },
				...stepProps.slice(stepId + 1),
			]);
		}, [
			setUser,
			data,
			inDraft,
			remoteKey,
			setStepProps,
			stepId,
			valueLoaded,
		]);

		const renderContent = useMemo(
			() => (
				<>
					{!hasDataLoaded ? (
						<Loader middle />
					) : isListNone ? (
						<span className="modal-list-inp-empty-text">
							{isDisabled
								? disabledText
								: emptyPlaceholder
								? emptyPlaceholder
								: "None"}
						</span>
					) : !inDraft || !liveValue || !Array.isArray(liveValue) ? (
						Array.isArray(currValue) &&
						currValue.map((option) =>
							renderMiniBadge({
								option,
								stepId,
								inlineBadgeModifier,
							})
						)
					) : (
						<>
							{liveValue
								.filter(
									(value) =>
										isPresentInList({
											list: currValue,
											value,
										}) ||
										(isFunction(liveValueFilterModifier)
											? liveValueFilterModifier({
													value,
													currValue,
											  })
											: false)
								)
								.filter((value) =>
									isFunction(liveValueFilter)
										? liveValueFilter({ value, currValue })
										: true
								)
								.map((option) =>
									renderMiniBadge({
										option,
										stepId,
										inlineBadgeModifier,
									})
								)}
							{currValue
								.filter(
									(value) =>
										!isPresentInList({
											list: liveValue,
											value,
										}) ||
										(isFunction(currValueFilterModifier)
											? currValueFilterModifier({
													value,
													liveValue,
											  })
											: false)
								)
								.filter((value) =>
									isFunction(currValueFilter)
										? currValueFilter({ value, liveValue })
										: true
								)
								.map((option) =>
									renderMiniBadge({
										option,
										stepId,
										inlineBadgeModifier,
										draft: true,
									})
								)}
						</>
					)}
					{canEdit && !card && (
						<MiniBadge
							className="list-inp-badge list-inp-icon-badge"
							onClick={handleOpen}
						>
							{/* <span>
								{Array.isArray(currValue) &&
								currValue.length > 0 ? (
									<MdEdit />
								) : (
									<PlusCircle />
								)}
							</span> */}
							{Array.isArray(currValue) && currValue.length > 0
								? `Edit ${inlineName}`
								: `Add ${inlineName}`}
						</MiniBadge>
					)}
				</>
			),
			[
				canEdit,
				card,
				currValue,
				currValueFilter,
				currValueFilterModifier,
				disabledText,
				emptyPlaceholder,
				handleOpen,
				hasDataLoaded,
				inDraft,
				inlineBadgeModifier,
				isDisabled,
				isListNone,
				liveValue,
				liveValueFilter,
				liveValueFilterModifier,
				renderMiniBadge,
				stepId,
				inlineName,
			]
		);

		if (hideInput) {
			return null;
		}

		if (designSystem) {
			return (
				<PaddedContainer
					key={`${remoteKey}_${stepId}`}
					className={genClassName({
						base: "new-modal-list-inp-container-parent",
						additional: className,
					})}
				>
					{label && (
						<DiscoLabel inReview={inDraft} flexContent>
							{isFunction(label)
								? label({
										value: currValue,
										loading: !hasDataLoaded,
										designSystem,
								  })
								: label}
							{inDraft && (
								<NewReviewTooltip
									activatorProps={{
										marginLeft: SPACING.TINY,
									}}
								/>
							)}
						</DiscoLabel>
					)}
					<InputContainer flexWrap vPadded expandable>
						{renderContent}
					</InputContainer>
					{isFunction(children)
						? children({ user, setUser })
						: children}
				</PaddedContainer>
			);
		}

		return (
			<section
				className={`modal-list-inp-container-parent ${
					card ? "modal-list-inp-container-parent-card" : ""
				} ${inputWrapperClassName}`}
				key={`${remoteKey}_${stepId}`}
			>
				{label && (
					<Label inDraft={inDraft}>
						{isFunction(label)
							? label({
									value: currValue,
									loading: !hasDataLoaded,
							  })
							: label}
						{inDraft && (
							<ReviewTooltip pendingApproval inline={false} />
						)}
						{canEdit && card && (
							<Button small onClick={handleOpen}>
								Edit
							</Button>
						)}
					</Label>
				)}

				<section
					className={`list-inp-container modal-list-inp-container ${
						onboarding ? "modal-list-inp-container-onboarding" : ""
					} ${className}`}
					{...inputProps}
				>
					{renderContent}
				</section>
				{isFunction(children) ? children({ user, setUser }) : children}
				{card && (
					<section className="modal-list-inp-container-parent-actions">
						{actionText}{" "}
						{/* {canEdit && <span onClick={handleOpen}>Edit</span>} */}
					</section>
				)}
			</section>
		);
	}
);

const ModalSelectListInput = memo(
	forwardRef(
		(
			{
				className = "",
				user,
				setUser,
				stepProps,
				setStepProps,
				editMode = false,
				onboarding = false,
				disabled,
				onlyModal = false,
				inlineModal = false,

				explicitOpen = false,
				explicitHandleClose,
				designSystem,
				...props
			},
			ref
		) => {
			const { open, handleClose, handleToggle } = useModal(false);
			const [specificOpen, setSpecificOpen] = useState(false);

			const explicitModalLoading = useMemo(() => {
				let loading = false;
				stepProps.forEach(({ fetchValueUrl, valueLoaded }) => {
					if (fetchValueUrl && !valueLoaded) {
						loading = true;
					}
				});
				return loading;
			}, [stepProps]);

			if (!stepProps || !Array.isArray(stepProps)) {
				return null;
			}

			return (
				<>
					{!onlyModal &&
						stepProps.map((stepProp, stepId) => (
							<ModalSelectInput
								setStepProps={setStepProps}
								stepProp={stepProp}
								stepId={stepId}
								editMode={editMode}
								disabled={disabled}
								handleToggle={handleToggle}
								setSpecificOpen={setSpecificOpen}
								onboarding={onboarding}
								className={className}
								designSystem={designSystem}
							/>
						))}
					<ModalSelect
						explicitLoading={explicitModalLoading}
						open={open || inlineModal || explicitOpen}
						closeModal={
							inlineModal
								? null
								: explicitHandleClose || handleClose
						}
						stepProps={stepProps}
						setStepProps={setStepProps}
						specificOpen={specificOpen}
						setSpecificOpen={setSpecificOpen}
						user={user}
						setUser={setUser}
						onboarding={onboarding}
						inlineModal={inlineModal}
						{...props}
					/>
				</>
			);
		}
	)
);

const ModalSelectWarningOverlay = memo(
	({
		className = "",
		warningTitle,
		warningBody,
		handleDismiss,
		handleExit,
		...props
	}) => {
		return (
			<motion.section
				className={`modal-select-overlay ${className}`}
				variants={anim.modalOverlay}
				initial="initial"
				animate="animate"
				exit="exit"
				{...props}
			>
				<motion.h2 variants={anim.modalOverlayItem}>
					{warningTitle || "Are you sure you want to exit?"}
				</motion.h2>
				<motion.div
					className="modal-select-overlay-body"
					variants={anim.modalOverlayItem}
				>
					{warningBody || "You have unsaved changes"}
				</motion.div>
				<motion.div
					className="modal-select-overlay-btn-container"
					variants={anim.modalOverlayItem}
				>
					<Button dark onClick={handleDismiss}>
						Cancel
					</Button>
					<FilledButton onClick={handleExit}>
						Yes, I'm sure
					</FilledButton>
				</motion.div>
			</motion.section>
		);
	}
);

const Pagination = memo(({ onPageChange, pages }) => {
	return (
		<section className="pagination">
			{pages.map((page, index) => (
				<>
					<section
						className={`pagination-page ${
							typeof page.page === "string"
								? "pagination-page-btn"
								: ""
						} ${page.active ? "pagination-page-active" : ""}`}
						onClick={() => onPageChange(page.page)}
						key={index}
					>
						{typeof page.page !== "string" ? (
							page.page
						) : page.page === "<" ? (
							<span>
								<MdChevronLeft />
							</span>
						) : (
							<span>
								<MdChevronRight />
							</span>
						)}
					</section>
				</>
			))}
		</section>
	);
});

const InlineHelperTooltip = memo(
	({
		open,
		heading = "",
		children,
		className = "",
		type = "information",
		rounded = false,
		hideBorder = false,
		...props
	}) => {
		return (
			<AnimatePresence>
				{open && (
					<motion.section
						variants={anim.staggeredFade}
						initial="initial"
						animate="animate"
						exit="exit"
						className={`inline-helper-tooltip ${
							type === "success"
								? "inline-helper-tooltip-success"
								: ""
						} ${
							type === "red" ? "inline-helper-tooltip-red" : ""
						} ${
							type === "light-red"
								? "inline-helper-tooltip-light-red"
								: ""
						} ${
							rounded ? "inline-helper-tooltip-rounded" : ""
						} ${className} ${
							hideBorder
								? "inline-helper-tooltip-hide-border"
								: ""
						}`}
						{...props}
					>
						{heading && <h2>{heading}</h2>}
						<section className="inline-helper-tooltip-children">
							{children}
						</section>
					</motion.section>
				)}
			</AnimatePresence>
		);
	}
);

const InlineProductTile = memo(({ product, className = "" }) => {
	if (!product) {
		return null;
	}

	return (
		<section className={`inline-product-tile ${className}`}>
			<img
				className="inline-product-tile-img"
				src={product.photo_url}
				alt=""
			></img>
			<section className="inline-product-tile-details">
				<h2>{product.name}</h2>
			</section>
		</section>
	);
});

const CompletionBar = memo(({ done, className = "" }) => {
	return (
		<section className={`completion-bar ${className}`}>
			<section
				className="completion-bar-progress"
				style={{ width: `${done <= 1 ? done * 100 : done}%` }}
			></section>
		</section>
	);
});

const VerticalSeperator = memo(({ className = "", ...props }) => (
	<div className={`vertical-seperator ${className}`} {...props}></div>
));

const Dot = memo(({ className = "", ...rest }) => {
	return <div className={`dot ${className}`} {...rest}></div>;
});

const DotSwitcher = memo(
	({
		className = "",
		vertical = false,
		dots = 1,
		activeDot = 0,
		setActiveDot,
		...props
	}) => {
		const handleDotClick = useCallback(
			(idx) => {
				if (typeof setActiveDot !== "function") {
					return;
				}
				setActiveDot(idx);
			},
			[setActiveDot]
		);

		const dummyDotArray = useMemo(() => [...Array(dots)], [dots]);

		return (
			<section
				className={`dot-switcher-container ${
					vertical ? "dot-switcher-container-vertical" : ""
				} ${className}`}
				{...props}
			>
				{dummyDotArray.map((_, idx) => (
					<Dot
						className={`dot-switcher-dot ${
							activeDot === idx ? "dot-switcher-dot-active" : ""
						}`}
						onClick={() => handleDotClick(idx)}
						key={`dot_${idx}`}
					/>
				))}
			</section>
		);
	}
);

const Slider = memo(({ className = "", ...props }) => {
	return (
		<ReactSlider
			className={`slider ${className}`}
			thumbClassName="slider-thumb"
			trackClassName="slider-track"
			renderThumb={(props, state) => (
				<div {...props}>
					<span>
						<MdChevronLeft />
					</span>
					<span>
						<MdChevronRight />
					</span>
				</div>
			)}
			{...props}
		/>
	);
});

const GenericProductCard = memo(({ product, className = "" }) => {
	const { modified_image: productImgUrl, name } = useMemo(
		() => product || {},
		[product]
	);

	if (!product) {
		return null;
	}

	return (
		<section className={`generic-product-card ${className || ""}`}>
			<img src={productImgUrl} alt=""></img>
			<h3>{name}</h3>
		</section>
	);
});

const CSVGenerator = memo(
	({
		requestUrl,
		requestPayload,
		heading = "",
		downloadedFileName = "Results",
		method = "GET",
		className = "",
		buttonText = "Download CSV",
	}) => {
		const [
			{ loading: csvLoading, data: csv, headers: csvHeaders },
			loadCsv,
			resetCsv,
		] = useResource(
			{
				url: requestUrl,
				method: method,
				data: requestPayload,
			},
			false
		);

		useEffect(() => {
			if (csv) {
				const temp = document.createElement("a");
				temp.href =
					"data:text/csv;charset=utf-8," + encodeURIComponent(csv);
				temp.download = `${
					(csvHeaders && extractFilenameFromReqHeaders(csvHeaders)) ||
					downloadedFileName
				}.export.csv`;
				temp.click();
				resetCsv();
			}
		}, [csv, csvHeaders, resetCsv, downloadedFileName]);

		return (
			<section className={`csv-generator-container ${className}`}>
				<h3>{heading}</h3>
				{!csvLoading ? (
					<FilledButton onClick={loadCsv} type="button" forward>
						{buttonText}
					</FilledButton>
				) : (
					<Loader />
				)}
			</section>
		);
	}
);

export const ColumnView = memo(({ children, className = "", ...props }) => {
	return (
		<section className={`column-view ${className}`} {...props}>
			{children}
		</section>
	);
});

export const ColumnViewContent = memo(
	({ children, className = "", ...props }) => {
		return (
			<section className={`column-view-content ${className}`} {...props}>
				{children}
			</section>
		);
	}
);

export const ColumnCard = memo(
	({
		children,
		className = "",
		tagline = "",
		heading = "",
		description = "",
		error = "",
		aside,
		...props
	}) => {
		return (
			<motion.section
				className={`column-card ${className} ${
					aside ? "column-card-split" : ""
				}`}
				variants={anim.horizontalSlide}
				initial="initial"
				animate="animate"
				exit="exit"
				{...props}
			>
				<section>
					{tagline && (
						<div className="column-card-tagline">{tagline}</div>
					)}
					{heading && (
						<h2 className="column-card-heading">{heading}</h2>
					)}
					{description && (
						<div className="column-card-description">
							{description}
						</div>
					)}
					{children && (
						<div className="column-card-children">{children}</div>
					)}
					{error && <FormStatus>{error}</FormStatus>}
				</section>
				{aside && <section>{aside}</section>}
			</motion.section>
		);
	}
);

export const ColumnViewAction = memo(
	({
		className = "",
		children,
		error = false,
		loading = false,
		middleLoader = false,
		...props
	}) => {
		return (
			<>
				{loading ? (
					<Loader middle={middleLoader} />
				) : (
					<section
						className={`column-view-action ${className}`}
						{...props}
					>
						{children}
					</section>
				)}

				{error && !loading && <ModuleNavigationError />}
			</>
		);
	}
);

export const ColumnViewAside = memo(
	({ className = "", children, ...props }) => {
		return (
			<section className={`column-view-aside ${className}`} {...props}>
				<section className="column-view-aside-content">
					{children}
				</section>
			</section>
		);
	}
);

export const ColumnViewAsideBanner = memo(
	({ className = "", children, type = "", icon, ...props }) => {
		return (
			<section
				className={`column-view-aside-banner ${
					type ? `column-view-aside-banner-${type}` : ""
				} ${className}`}
				{...props}
			>
				{icon && (
					<b className="column-view-aside-banner-icon">{icon}</b>
				)}
				{children}
			</section>
		);
	}
);

export const ModuleNavigationError = memo(
	({
		className = "",
		errorMessage = "",
		fit = false,
		padded = false,
		...props
	}) => {
		return (
			<FormStatus
				className={`module-navigation-error ${
					fit ? "module-navigation-error-fit" : ""
				} ${
					padded ? "module-navigation-error-padded" : ""
				} ${className}`}
				{...props}
			>
				{errorMessage || NAV_ERROR_MESSAGE}
			</FormStatus>
		);
	}
);

export const UnitInput = memo(
	forwardRef(({ units, initial, onChange, value, ...rest }, ref) => {
		const handleUnitChange = ({ target: { value: unit } }) => {
			if (typeof onChange === "function") onChange({ unit, value: "" });
		};
		const handleValueChange = ({ target: { value: _value } }) => {
			if (typeof onChange === "function")
				onChange({
					unit: value.unit,
					value:
						_value === ""
							? _value
							: Math.min(
									units[value.unit]?.max === undefined
										? Infinity
										: units[value.unit]?.max,
									Math.max(
										units[value.unit]?.min === undefined
											? -Infinity
											: units[value.unit]?.min,
										Number(_value)
									)
							  ),
				});
		};

		const options = useMemo(() => {
			const options = {};
			for (const unit in units) {
				options[unit] = units[unit].name;
			}
			return options;
		}, [units]);

		return (
			<section className="unit-inp" ref={ref}>
				<Select
					value={value.unit}
					options={options}
					onChange={handleUnitChange}
				/>
				<input
					{...rest}
					type="number"
					max={units[value.unit]?.max}
					min={units[value.unit]?.min}
					value={value.value}
					placeholder={units[value.unit]?.placeholder}
					onChange={handleValueChange}
				/>
			</section>
		);
	})
);

const CalloutBanner = memo(({ className = "", children, heading, ...rest }) => {
	return (
		<section className={`callout-banner ${className}`}>
			<img src={calloutImage} alt="" />
			<main className="callout-banner-hero">
				<h3>{heading}</h3>
				<section className="callout-banner-hero-content">
					{children}
				</section>
			</main>
		</section>
	);
});
export const ActionMessage = memo(
	({ children, className = "", theme = false, text }) => {
		return (
			<section
				className={`action-message ${
					theme ? "action-message-theme" : ""
				} ${className}`}
			>
				<section className="action-message-text">{text}</section>
				<section className="action-message-action">{children}</section>
			</section>
		);
	}
);

// Auto formatted components
export const LargeHeading = memo(({ className = "", children, ...rest }) => {
	return (
		<Text
			size={FONT_SIZE.HEADER}
			className={`large-heading ${className}`}
			{...rest}
		>
			{children}
		</Text>
	);
});

export const FeaturesList = memo(
	({
		className = "",
		small = false,
		children,
		features,
		featureKey,
		bold = true,
		...rest
	}) => {
		return (
			<PaddedContainer
				motionElement
				className={genClassName({
					base: "features-list",
					conditionals: {
						"features-list-small": small,
						"features-list-bold": bold,
					},
					additional: className,
				})}
				{...rest}
			>
				{Array.isArray(features) &&
					features.map((feature) => (
						<PaddedContainer className="features-list-feature">
							<img src={checkIcon} alt="" />{" "}
							<Text>
								{featureKey ? feature[featureKey] : feature}
							</Text>
						</PaddedContainer>
					))}
				{children}
			</PaddedContainer>
		);
	}
);

export const LargeText = memo(
	({
		className = "",
		compact = false,
		xCompact = false,
		children,
		...rest
	}) => {
		return (
			<Text
				size={xCompact ? undefined : FONT_SIZE.HEADER}
				className={`large-text ${compact ? "large-text-compact" : ""} ${
					xCompact ? "large-text-x-compact" : ""
				} ${className}`}
				{...rest}
			>
				{children}
			</Text>
		);
	}
);

export const BorderedCard = memo(
	({
		className = "",
		header,
		preHeading,
		subHeading,
		headerImg = null,
		showLogo = false,
		heading,
		footer,
		children,
		centered,
		showMobileHeading,
		aside = null,
		...rest
	}) => {
		const { isMobile } = useDimensions();
		return (
			<PaddedContainer
				className={`bordered-card ${
					centered ? "bordered-card-centered" : ""
				} ${aside ? "bordered-card-split" : ""} ${className}`}
				variants={isMobile && anim.sheet}
				{...rest}
			>
				{header}
				<main>
					{aside && (
						<aside className="bordered-card-aside">{aside}</aside>
					)}
					<section className="bordered-card-body">
						{!isMobile && showLogo && (
							<img
								src={colorLogo}
								className="bordered-card-logo"
								alt=""
							/>
						)}
						{!isMobile && preHeading && (
							<Text
								size={FONT_SIZE.SUB_TITLE}
								className="bordered-card-narrow bordered-card-pre-heading"
								paddingTop={SPACING.LARGE}
								marginBottom={SPACING.SMALL}
							>
								{preHeading}
							</Text>
						)}
						{headerImg}
						{(showMobileHeading || !isMobile) && heading && (
							<Text
								className="bordered-card-narrow bordered-card-heading"
								size={FONT_SIZE.HEADER}
							>
								{heading}
							</Text>
						)}
						{subHeading && (
							<Text
								className="bordered-card-narrow bordered-card-sub-heading"
								size={FONT_SIZE.BODY}
								marginBottom={SPACING.REGULAR}
							>
								{subHeading}
							</Text>
						)}
						{children}
					</section>
				</main>
				{footer && <footer>{footer}</footer>}
			</PaddedContainer>
		);
	}
);

export const GradientBorderedCard = memo(
	({ className = "", children, ...rest }) => {
		return (
			<motion.section
				className={`gradient-bordered-card-container ${className}`}
				{...rest}
			>
				<section className="gradient-bordered-card">{children}</section>
			</motion.section>
		);
	}
);

export const PrivacyPolicyCard = memo(
	({ header = null, className = "", policy, children, ...rest }) => {
		const policyRef = useRef();
		const track = useTrack();

		const copyText = useCallback(() => {
			track(PLATFORM_PRIVACY_CLICK_ON_COPY, {});
			policyRef.current.select();
			document.execCommand("copy");
		}, [track]);

		return (
			<section className={`privacy-policy-card ${className}`} {...rest}>
				{header}
				{policy && (
					<Input textarea readOnly ref={policyRef} value={policy} />
				)}
				{children}
				{policy && (
					<section className="privacy-policy-card-action">
						<div className="privacy-policy-card-action-text">
							Consult your legal counsel before using this text
						</div>
						<FilledButton onClick={copyText}>
							Copy to Clipboard
						</FilledButton>
					</section>
				)}
			</section>
		);
	}
);

// A better way to render a form!
export const HForm = memo(
	({
		fields,
		data,
		setData,
		children,
		error,
		errorTestId,
		focusEvent,
		...rest
	}) => {
		const { isMobile } = useDimensions();
		const [fieldsInfo, setFieldsInfo] = useState({});
		const handleChange = useCallback(
			(e, poorImplementationName) => {
				if (!isFunction(setData)) return;
				if (typeof e === "number") {
					setData((data) => ({
						...data,
						[poorImplementationName]: e,
					}));
					return;
				}

				setData((data) => ({
					...data,
					[e.target.name]: e.target.value,
				}));
				const rules = fields.find(
					({ props: { name: fieldName } = {} }) =>
						e.target.name === fieldName
				)?.rules;
				if (!Array.isArray(rules)) return;
				const info = rules.map((rule) => ({
					result: rule.validator(e.target.value),
					text: rule.text,
				}));
				setFieldsInfo((fieldsInfo) => ({
					...fieldsInfo,
					[e.target.name]: { ruleBased: true, text: info },
				}));
			},
			[setData, fields]
		);

		const handleBlur = useCallback(
			({ target: { name, value } = {} } = {}) => {
				const validator = fields.find(
					({ props: { name: fieldName } = {} }) => name === fieldName
				)?.validator;
				if (!isFunction(validator)) return;
				const { error } = validator(value);
				if (error)
					setFieldsInfo((fieldsInfo) => ({
						...fieldsInfo,
						[name]: { ruleBased: false, text: error },
					}));
				else
					setFieldsInfo((fieldsInfo) => ({
						...fieldsInfo,
						[name]: null,
					}));
			},
			[fields]
		);

		const track = useTrack();

		const handleFocus = useCallback(
			({ target: { name, value } = {} } = {}) => {
				if (focusEvent) track(focusEvent, { field: name, value });
			},
			[track]
		);

		return (
			<BaseForm {...rest} className="h-form ">
				<main className="h-form-fields">
					{fields.map((field, idx) => {
						if (field.hidden) return null;
						const info = fieldsInfo[field.props.name];
						const fieldErrorClassName =
							info && !info.ruleBased ? "h-form-field-error" : "";
						return (
							<React.Fragment key={idx}>
								<span className="h-form-label">
									{field.label}
								</span>
								{field?.props?.type === "radio" ? (
									isMobile ? (
										<MobileRadioInput
											{...field.props}
											label={field.label}
											selected={
												data?.[field.props.name] ??
												field?.value
											}
											onChange={handleChange}
											onBlur={handleBlur}
											onFocus={handleFocus}
										/>
									) : (
										<RadioInput
											{...field.props}
											selected={
												data?.[field.props.name] ??
												field?.value ??
												""
											}
											onChange={handleChange}
											onBlur={handleBlur}
											onFocus={handleFocus}
										/>
									)
								) : field?.props?.type === "select" ? (
									<DiscoSelect
										{...field.props}
										padded
										value={
											data?.[field.props.name] ??
											field?.value ??
											""
										}
										options={field.props.options}
										onChange={(...args) => {
											handleChange(...args);
											handleBlur(...args);
										}}
										onBlur={handleBlur}
										onFocus={handleFocus}
										className={fieldErrorClassName}
									/>
								) : (
									<DiscoInput
										{...field.props}
										value={
											data?.[field.props.name] ??
											field?.value ??
											""
										}
										onChange={handleChange}
										onBlur={handleBlur}
										onFocus={handleFocus}
										className={fieldErrorClassName}
									/>
								)}
								{info && (
									<>
										<section />
										<section
											className={`h-form-field-info ${
												info.ruleBased
													? "h-form-field-info-rules"
													: ""
											}`}
										>
											{info.ruleBased ? (
												<>
													{field.label} must contain -
													{info.text.map(
														({ text, result }) => (
															<section
																className={`h-form-field-info-rule ${
																	result
																		? "h-form-field-info-rule-done"
																		: ""
																}`}
															>
																<Dot />
																{text}
															</section>
														)
													)}
												</>
											) : (
												info.text
											)}
										</section>
									</>
								)}
							</React.Fragment>
						);
					})}
				</main>
				{error && (
					<FormStatus
						{...(errorTestId ? { "data-testid": errorTestId } : {})}
					>
						{error}
					</FormStatus>
				)}
				{children}
			</BaseForm>
		);
	}
);

const MobileComingSoonModal = memo(() => {
	const { open, handleClose } = useModal(true);
	const [sending, setSending] = useState(false);
	const [sent, setSent] = useState(false);
	const track = useTrack();
	const location = useLocation();
	const navigate = useNavigate();

	const sendRequest = useCallback(() => {
		setSending(true);
		setTimeout(() => {
			track(PLATFORM_REQUEST_MOBILE_CLICK, { page: location.pathname });
			setSending(false);
			setSent(true);
		}, 500);
	}, [location.pathname, track]);

	const handleBtnClose = useCallback(() => {
		handleClose();
		navigate(`/performance`);
	}, [handleClose, navigate]);

	const handleBtnClick = useCallback(() => {
		if (sent) {
			handleBtnClose();
		} else {
			sendRequest();
		}
	}, [sent, handleBtnClose, sendRequest]);

	return (
		<Modal
			open={open}
			sheet
			closerHidden
			customModal
			className="mobile-coming-soon-modal"
			onClose={handleBtnClose}
		>
			<section className="mobile-coming-soon-modal-hero">
				<div
					className="mobile-coming-soon-modal-close"
					onClick={handleBtnClose}
				>
					Close
				</div>
				<img
					src={comingSoon}
					alt="Coming Soon"
					className="mobile-coming-soon-modal-img"
				/>
			</section>
			<section className="mobile-coming-soon-modal-content">
				<h3>
					{sent
						? "Thanks for letting us know!"
						: "Mobile Friendly Coming Soon"}
				</h3>
				<span>
					{sent
						? "Your request has been sent. We’ll let you know when we launch for mobile."
						: "Unfortunately this page is not available currently on mobile devices."}
				</span>
				<div className="mobile-coming-soon-modal-footer">
					{sending ? (
						<Loader />
					) : (
						<Button
							onClick={handleBtnClick}
							large
							grayStroke={sent}
							gradient={!sent}
						>
							{sent
								? "Close"
								: "I would love to view this page on mobile"}
						</Button>
					)}
				</div>
			</section>
		</Modal>
	);
});

const SearchInput = memo(
	React.forwardRef(function (
		{ value, placeholder, onChange, className },
		ref
	) {
		const [focused, setFocused] = useState(false);
		const inputRef = useRef();

		const handleClick = () => {
			setFocused(true);
			inputRef.current.focus();
		};

		const onBlur = () => {
			setFocused(false);
		};

		return (
			<div className={`search-inp-container ${className}`}>
				<Input
					ref={inputRef}
					value={value}
					onChange={onChange}
					onBlur={onBlur}
				/>
				{!focused && !value && (
					<div
						className="search-inp-container-placeholder"
						onClick={handleClick}
					>
						<BsSearch className="search-inp-icon" />
						{placeholder}
					</div>
				)}
			</div>
		);
	})
);

export const BrandLogos = memo(() => {
	const brands = useMemo(
		() => [
			{ img: haus, className: "" },
			{ img: madeIn, className: "" },
			{ img: rhone, className: "" },
			{ img: lovevery, className: "brand-logo-lovevery" },
			{
				img: trueBotanicals,
				className: "brand-logo-true-botanicals",
			},
			{ img: parade, className: "" },
		],
		[]
	);

	return (
		<section className="brand-logos">
			{brands.map(({ img, className }, index) => (
				<img
					src={img}
					alt={""}
					key={index}
					className={`brand-logo ${className}`}
				/>
			))}

			<section className="brand-logo">
				600+ <br /> more
			</section>
		</section>
	);
});

export {
	Accordion,
	AccordionBanner,
	ActionInput,
	AuthPage,
	Badge,
	BadgeButton,
	BadgeImageInput,
	BaseForm,
	BaseTooltip,
	Boolean,
	BrandPreview,
	Button,
	CSVGenerator,
	CalloutBanner,
	Card,
	Checkbox,
	Collapsable,
	ColorInput,
	CompletionBar,
	CopyBlock,
	CopyPasteBlock,
	CreditsView,
	Dot,
	DotSwitcher,
	DropDown,
	DropDownOption,
	DropdownSelect,
	EditImage,
	EditModeToggle,
	EmbeddedPreviewPage,
	FacebookButton,
	FileInput,
	FilledButton,
	FlexBr,
	FloatingCard,
	FormStatus,
	FullCard,
	GenericProductCard,
	GradientCard,
	GridForm,
	GuidedForm,
	GuidedTooltip,
	GuidedView,
	GuidedViewTooltip,
	IconButton,
	ImageInput,
	InfoArea,
	InlineHelperTooltip,
	InlineProductTile,
	Input,
	Label,
	LabeledInput,
	LargeList,
	LargeListItem,
	Loader,
	MiniBadge,
	MobileNavHeader,
	MobileNavHeaderSpecial,
	MobileRadioInput,
	ModalSelectBottomNavigator,
	// Specialised Elements
	ModalSelectHLine,
	ModalSelectListInput,
	ModalSelectWarningOverlay,
	MotionCard,
	MotionLabel,
	MultiSelect,
	OtherSelect,
	Page,
	PageContent,
	PageHeader,
	Pagination,
	PhoneSkeleton,
	Preview,
	PreviewButton,
	Product,
	ProductBadge,
	ProgressBar,
	ProgressView,
	PublishButton,
	QuestionTooltip,
	Radio,
	RadioInput,
	RemoteButton,
	RemoteInput,
	RemoteTabSwitcher,
	ReviewTooltip,
	SearchInput,
	SectionHeading,
	Select,
	SetupSteps,
	ShopifyDot,
	ShopifyPayButton,
	SlackButton,
	Slider,
	StatusToggle,
	SubscriptionFeatures,
	TabSwitcher,
	Table,
	Tabs,
	TagButton,
	TitledCard,
	Toggle,
	ToggleButton,
	ToggleInput,
	Tooltip,
	TranslucentButton,
	UniversalPreview,
	VerticalSeperator,
	Widget,
	WidgetModuleBadge,
};
