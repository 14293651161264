import React, { memo, useCallback, useMemo } from "react";
import LookerDashPerformance from "../../components/PerformanceModules/LookerDashPerformance";
import SurveyPerformanceModule from "../../components/PerformanceModules/SurveyPerformanceModule";
import getPlatformPath from "../../utils/getPlatformPath";
import hasMigratedToExtensions from "../../utils/hasMigratedToExtensions";
import TopLevelPage, { TopLevelPageHeader } from "../TopLevelPage";
import "./performance-page.css";
import { useNavigate, useParams } from "react-router-dom";
import getPerformanceSubheadings, {
	isPerformanceInMaintenance,
} from "../../utils/getPerformanceSubheadings";
import useIdentity from "../../hooks/useIdentity";
import {
	Button,
	Card,
	FONT_SIZE,
	PaddedContainer,
	SPACING,
	Text,
} from "@disco/disco_core";
import { AuthPage, PageHeader } from "../../components/CoreUI";

const PerformancePageActionButtons = memo(({ urlParam, btnText }) => {
	const { user } = useIdentity();

	const shouldShowExtensions =
		urlParam === "crossSell" &&
		hasMigratedToExtensions({ user, considerOverride: true });

	const navigate = useNavigate();

	const baseRedirectUrl = getPlatformPath(
		shouldShowExtensions ? "integrations" : "discoFeed",
		user
	);

	const handleRedirect = useCallback(
		(overridePath = null) => {
			navigate(overridePath || baseRedirectUrl);
		},
		[navigate, baseRedirectUrl]
	);

	const handlePrimaryBtn = useCallback(() => {
		if (shouldShowExtensions) {
			navigate(baseRedirectUrl);
			return;
		}
		handleRedirect();
	}, [navigate, handleRedirect, shouldShowExtensions, baseRedirectUrl]);

	const handleSecondaryBtn = useCallback(() => {
		handleRedirect(getPlatformPath("products", user));
	}, [user, handleRedirect]);

	if (!btnText || !baseRedirectUrl) return null;

	return (
		<>
			{shouldShowExtensions && (
				<Button
					small
					secondary
					marginRight={SPACING.SMALL}
					onClick={handleSecondaryBtn}
				>
					{btnText}
				</Button>
			)}
			<Button small onClick={handlePrimaryBtn}>
				{shouldShowExtensions
					? "Setup Shopify Checkout Extensions"
					: btnText}
			</Button>
		</>
	);
});

const PerformancePageMaintenance = () => {
	const { user } = useIdentity();
	const navigate = useNavigate();
	const handleClick = () => {
		navigate(getPlatformPath("discoFeed", user));
	};
	return (
		<AuthPage
			active="performance"
			className="performance-page performance-page-maintenance"
		>
			<PageHeader heading="Performance" />
			<Card
				vPadding={SPACING.REGULAR}
				hPadding={SPACING.LARGE}
				className="performance-page-maintenance-content"
				marginTop={SPACING.REGULAR}
			>
				<PaddedContainer>
					<iframe src="https://lottie.host/embed/e82436f5-8035-4f03-9f35-1b1ed3371d6f/hrdqyxg9Su.json"></iframe>
					<Text
						size={FONT_SIZE.HEADER}
						marginTop={SPACING.LARGE}
						center
					>
						Scheduled maintenance
					</Text>
					<Text
						size={FONT_SIZE.SUB_TITLE}
						marginTop={SPACING.REGULAR}
						center
					>
						We are working on your dashboards
					</Text>
					<Button
						onClick={handleClick}
						hMarginAuto
						marginTop={SPACING.LARGE}
					>
						Customize my DiscoFeed
					</Button>
				</PaddedContainer>
			</Card>
		</AuthPage>
	);
};

const PerformancePage = memo(() => {
	const { user } = useIdentity();

	const subPages = {
		crossSell: LookerDashPerformance,
		surveyPerformance: SurveyPerformanceModule,
	};

	const pageProps = useCallback((subPage) => {
		const props = {
			crossSell: {
				type: "cross_sell",
				btnText: "Customize my DiscoFeed",
			},
			surveyPerformance: {},
		};
		return props[subPage];
	}, []);

	const { subPage: urlParam } = useParams();

	const headerProps = useMemo(
		() => ({
			tabOptions: getPerformanceSubheadings({
				user,
				activeIdx: urlParam,
				sidebar: false,
			}),
			heading: "Performance",
		}),
		[urlParam, user]
	);

	const renderPageHeader = useMemo(() => {
		return (
			<TopLevelPageHeader
				{...headerProps}
				actionChildren={
					<PerformancePageActionButtons
						urlParam={urlParam}
						btnText={pageProps(urlParam)?.btnText}
					/>
				}
			/>
		);
	}, [headerProps, urlParam, pageProps]);

	if (isPerformanceInMaintenance({ user })) {
		return <PerformancePageMaintenance />;
	}

	return (
		<TopLevelPage
			subPages={subPages}
			pageHeader={renderPageHeader}
			pageProps={pageProps}
			className="performance-page"
		></TopLevelPage>
	);
});

export default PerformancePage;
