import {
	Asset,
	Label,
	LabeledInput,
	PaddedContainer,
	parseError,
	PlusIcon,
	SPACING,
	StatusLabel,
} from "@disco/disco_core";
import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import {
	DEFAULT_BRAND_LOGO_IMG,
	ONBOARDING_SETUP_STATUSES,
	PROFILE_IMAGE_UPLOAD_URL,
	SAVE_BRAND_URL,
} from "../../conf";
import useResource from "../../hooks/useResource";
import OnboardingStep from "./OnboardingStep";

import "./profile-step.css";

import {
	PLATFORM_ONBOARDING_CLICK_CONTINUE_PROFILE,
	PLATFORM_ONBOARDING_CLICK_SAVE_LOG_OUT_PROFILE,
	PLATFORM_ONBOARDING_PAGE_VIEW_PROFILE,
} from "../../events";
import useIdentity from "../../hooks/useIdentity";
import NewEditImage from "../NewEditImage";

const ProfileStepLogoSelector = () => {
	const { user, setUser } = useIdentity();

	const handleImgUpdate = (imgUrl) =>
		setUser((user) => ({
			...user,
			publisher: { ...user.publisher, logo_url: imgUrl },
		}));

	const getBrandImg = (user) => user?.publisher?.logo_url;

	const customImgRenderer = ({ value, onOpen, defaultImgRender }) => {
		const isDefaultImg = value === DEFAULT_BRAND_LOGO_IMG;
		if (isDefaultImg) {
			return (
				<PaddedContainer
					className="new-edit-image-img profile-step-logo-selector-thumb"
					centerContent
					onClick={onOpen}
					clickable
				>
					<Asset
						size={40}
						color={"--disco-theme-mid"}
						style={{
							backgroundColor: "var(--disco-bg-light)",
							borderRadius: "100px",
							padding: "var(--disco-spacing-tn)",
						}}
					>
						<PlusIcon />
					</Asset>
				</PaddedContainer>
			);
		}
		return defaultImgRender;
	};

	return (
		<>
			<Label>Brand Logo</Label>
			<NewEditImage
				url={PROFILE_IMAGE_UPLOAD_URL}
				remoteKey="logo_url"
				user={user}
				updateMethod="POST"
				selector={getBrandImg}
				customImgRenderer={customImgRenderer}
				onDone={handleImgUpdate}
				heading="Upload brand logo"
				editText="Upload Logo"
				className="profile-step-logo-selector"
			/>
		</>
	);
};

const NEXT = 0,
	LOGOUT = 1;
const ProfileStep = memo(() => {
	const nextType = useRef(NEXT);

	const [next, setNext] = useState(false);
	const [logout, setLogout] = useState(false);

	const { user, setUser } = useIdentity();

	const [shortDescription, setShortDescription] = useState(
		user?.publisher?.single_liner || ""
	);
	const [formError, setFormError] = useState("");
	const handleShortDescriptionChange = ({ target: { value } }) => {
		setFormError("");
		setShortDescription(value);
	};

	const [{ data: brandData, loading, error }, setupBrand, resetBrand] =
		useResource(
			{
				url: SAVE_BRAND_URL,
				method: "PUT",
				data: {
					single_liner: shortDescription,
				},
			},
			false
		);

	const save = useCallback(() => {
		setFormError("");
		if (nextType.current !== LOGOUT) {
			if (!shortDescription)
				return setFormError(
					"Please enter a short description for your shop"
				);
		}
		setupBrand();
	}, [setupBrand, shortDescription]);

	const handleLogout = useCallback(() => {
		nextType.current = LOGOUT;
		save();
	}, [save]);

	// To go back anyway
	useEffect(() => {
		if (!error || nextType.current !== LOGOUT) {
			return;
		}
		setLogout(true);
		resetBrand();
	}, [error, resetBrand]);

	useEffect(() => {
		if (!brandData) {
			return;
		}
		const { single_liner = "" } = brandData?.brand || {};
		setUser((user) => ({
			...user,
			publisher: {
				...user.publisher,
				single_liner,
			},
		}));
		resetBrand();

		if (nextType.current === LOGOUT) {
			setLogout(true);
			return;
		}
		setNext(true);
	}, [brandData, resetBrand, setUser, user]);

	return (
		<OnboardingStep
			heading="Build your brand profile"
			subText="Showcase your brand to potential customers and partners. Add your logo and a shop description to start."
			nextText="Continue"
			user={user}
			setUser={setUser}
			next={next}
			nextLoading={loading && nextType.current === NEXT}
			onNext={save}
			parseErrors={true}
			onLogout={handleLogout}
			logout={logout}
			setLogout={setLogout}
			requiredLegend
			setNext={setNext}
			aside={{
				heading: "Profile",
				imageUp: true,
				logo: true,
			}}
			className="profile-step"
			showBack={false}
			status={ONBOARDING_SETUP_STATUSES.PROFILE_SETUP}
			noPad
			nextEvent={PLATFORM_ONBOARDING_CLICK_CONTINUE_PROFILE}
			logoutEvent={PLATFORM_ONBOARDING_CLICK_SAVE_LOG_OUT_PROFILE}
			viewEvent={PLATFORM_ONBOARDING_PAGE_VIEW_PROFILE}
			nextDisabled={shortDescription === ""}
		>
			<PaddedContainer vPadding={SPACING.REGULAR}>
				<ProfileStepLogoSelector />
				<PaddedContainer marginTop={SPACING.MEDIUM} />
				<LabeledInput
					label="Shop description"
					placeholder="Provide a concise, engaging description that highlights what makes your shop unique to shoppers"
					maxLength={55}
					textarea
					value={shortDescription}
					disabled={loading}
					onChange={handleShortDescriptionChange}
				/>
				{(formError || error) && (
					<StatusLabel
						type="error"
						noBorder
						noShadow
						showIcon={false}
						marginTop={SPACING.MEDIUM}
					>
						{parseError(formError || error)}
					</StatusLabel>
				)}
			</PaddedContainer>
		</OnboardingStep>
	);
});

export default ProfileStep;
