import React, { memo, useState } from "react";
import { Link } from "react-router-dom";
import CentralView, { REGISTER_TAB } from "./CentralView";

import "./register-view.css";
import { GA_CODE, PLATFORM_IDS, SHOPIFY_APP_STORE_URL } from "../../conf";
import { GET } from "../../utils/GET";
import fromShopify from "../../utils/fromShopify";
import ReactGA from "react-ga";
import windowRedirect from "../../utils/windowRedirect";

import background from "../../img/register-background.png";
import PlatformViews from "./PlatformViews";
import RegistrationForm from "./RegistrationForm";

// Google Analytics
ReactGA.initialize(GA_CODE, { testMode: process.env.NODE_ENV === "test" });
ReactGA.pageview(window.location.pathname + window.location.search);

const RegisterView = memo(({ setAuth }) => {
	const store_hash = GET("store_hash");

	const [step, setStep] = useState(!store_hash && !fromShopify() ? 0 : 1);
	const [platform, setPlatform] = useState();

	const handlePlatformSubmit = (platform) => {
		if (PLATFORM_IDS.shopify === platform) {
			windowRedirect(SHOPIFY_APP_STORE_URL);
		} else {
			setStep(1);
		}
	};

	return (
		<CentralView
			tabActive={REGISTER_TAB}
			background={background}
			className="register-view"
			showLogo
			showMobileLogo
			heading={step == 0 ? "Let's Get Started" : "Create your account"}
		>
			{step == 0 ? (
				<PlatformViews
					platform={platform}
					setPlatform={setPlatform}
					handleSubmit={handlePlatformSubmit}
				/>
			) : (
				<RegistrationForm setAuth={setAuth} platformId={platform} />
			)}

			<section className="register-view-login">
				Already have an account? <Link to={"/login"}>Sign in</Link>
			</section>
		</CentralView>
	);
});

export default RegisterView;
