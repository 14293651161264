import {
	PLATFORM_CLICK_ON_DISCO_FEED_FEED,
	PLATFORM_CLICK_ON_DISCO_FEED_PRODUCTS,
} from "../events";

const getDiscofeedSidebarOptions = (user) => {
	if (!user) return null;
	const subPages = [
		["discoFeed", "Manage", PLATFORM_CLICK_ON_DISCO_FEED_FEED, true],
		["products", "Products", PLATFORM_CLICK_ON_DISCO_FEED_PRODUCTS, true],
	];

	return subPages
		.filter(([, , , shouldDisplay]) => shouldDisplay)
		.map(([name, title, event]) => ({
			title,
			name,
			to: `/manage/${name}`,
			event,
		}));
};

export default getDiscofeedSidebarOptions;
