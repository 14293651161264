import React, {
	createRef,
	memo,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";

import { AnimatePresence, motion } from "framer-motion";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import { PRODUCT_BULK_UPDATE_URL, PREVIEW_TYPES_IDX } from "../../conf";
import useProductSave from "../../hooks/useProductSave";
import anim from "../../utils/anim";
import { Pagination, Table } from "../CoreUI";
import {
	Button,
	Loader,
	PaddedContainer,
	DropdownSelect,
	Toggle,
	Checkbox,
	Text,
	SPACING,
	genClassName,
	Asset,
	PencilIcon,
	PlusIcon,
	MinusIcon,
	FONT_COLOR,
	FONT_SIZE,
} from "@disco/disco_core";
import "./products-view.css";

import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";

import { BsFillCaretDownFill } from "react-icons/bs";
import useModal from "../../hooks/useModal";
import useResource from "../../hooks/useResource";
import { ProductDisplay, ProductPrice } from "../ProductComponents";
import Confirmation from "../modals/Confirmation";

import isShopifyShop from "../../utils/isShopifyShop";

// Providing considerations for non-Shopify brand products
const nonShopifySources = ["BigCommerce", "WooCommerce"];

const UPSELL_TYPE = 1,
	CROSS_SELL_TYPE = 2;

// const bulkActions = {
// 	enableUpsell: "Enable upsell",
// 	disableUpsell: "Disable upsell",
// 	enableCrossSell: "Enable Cross-sell",
// 	disableCrossSell: "Disable Cross-sell",
// 	delete: "Delete",
// };

const bulkActionData = {
	delete: { warn: true, payload: { deleted: true } },
	enableUpsell: { payload: { upsell: true } },
	disableUpsell: { payload: { upsell: false } },
	enableCrossSell: { payload: { active: true } },
	disableCrossSell: { payload: { active: false } },
};

const headerSortingClasses = (column, sortOrder, isLastSorting, colIndex) => {
	return sortOrder === "asc"
		? "lib-table-sort"
		: "lib-table-sort lib-table-sort-desc";
};

const MAX_ROW_SIZE = 35;

const CollectionHeader = memo(
	({
		collection,
		selectedNum,
		onSelectAll,
		onAdd,
		expanded = false,
		collapsable,
		loading,
		onBulkAction,
		onExpandChange,
		products,
		onProductOpen,
		selectable = true,
	}) => {
		const total = useMemo(() => {
			if (!Array.isArray(products)) {
				return 0;
			}

			if (!collection?.products) {
				return products.filter((product) => !product.deleted).length;
			}

			return collection.products.filter(
				(remoteId) =>
					products.findIndex(
						({ remote_id }) => remote_id === remoteId
					) !== -1
			).length;
		}, [collection, products]);

		const bulkActions = useMemo(
			() => ({
				enableCrossSell: {
					text: "Enable Cross-sell",
				},
				disableCrossSell: {
					text: "Disable Cross-sell",
				},
				delete: {
					text: "Delete",
				},
			}),
			[]
		);

		const renderOptions = useCallback(
			({ options }) => {
				return (
					<PaddedContainer
						vPadding={SPACING.MICRO}
						tightTop
						tightBottom
					>
						{Object.entries(options).map(([key, option]) => (
							<Text
								className="collection-header-select-option"
								key={key}
								onClick={() => onBulkAction(key)}
								hPadding={SPACING.TINY}
								thin
							>
								{option}
							</Text>
						))}
					</PaddedContainer>
				);
			},
			[onBulkAction]
		);

		return (
			<motion.header
				className={`collection-header${
					expanded ? " collection-header-expanded" : ""
				}`}
				variants={anim.rowItem}
				initial="initial"
				animate="animate"
				onClick={onExpandChange}
			>
				<PaddedContainer
					className="collection-header-select-row"
					marginLeft={SPACING.TINY}
					flexContent
				>
					<PaddedContainer
						onClick={(e) => e.stopPropagation()}
						hPadding={SPACING.TINY}
						vPadding={SPACING.TINY}
						alignContentCenter
						flexContent
					>
						{selectable && (
							<Checkbox
								indeterminate={
									selectedNum > 0 && selectedNum < total
								}
								onChange={onSelectAll}
							/>
						)}
						{collection ? (
							<Text
								thick
								marginRight={SPACING.MICRO}
								marginLeft={SPACING.REGULAR}
								color={"var(--theme)"}
							>
								{collection.name}
							</Text>
						) : (
							<Text
								thick
								marginRight={SPACING.MICRO}
								marginLeft={SPACING.REGULAR}
								color={"var(--theme)"}
							>
								All Products{" "}
							</Text>
						)}
						<Text thin color={"var(--theme)"}>
							{selectedNum > 0
								? ` (${selectedNum} of ${total} selected)`
								: `  (${total} products)`}
						</Text>
					</PaddedContainer>
					{selectable && (
						<DropdownSelect
							placeholder="Actions"
							className={genClassName({
								base: "collection-header-select",
								conditionals: {
									"collection-header-select-disabled":
										selectedNum == 0,
								},
							})}
							options={bulkActions}
							renderCustomOptions={renderOptions}
							disabled={selectedNum == 0}
							expandable={false}
							initialAnimation={false}
						/>
					)}
				</PaddedContainer>
				{loading && <Loader flex marginRight={SPACING.REGULAR} />}
				{!expanded && (
					<motion.section
						className="collection-header-products"
						variants={anim.rowItem}
						initial="initial"
						animate="animate"
					>
						{products.slice(0, MAX_ROW_SIZE).map((product) => (
							<motion.img
								key={product.remote_id + "i"}
								src={product.display.product.photo_url}
								alt="Product"
								onClick={(e) => {
									onExpandChange();
									onProductOpen(product.remote_id);
									e.stopPropagation();
								}}
								variants={anim.rowItem}
							/>
						))}
					</motion.section>
				)}
				<PaddedContainer marginRight={SPACING.SMALL}>
					{collapsable ? (
						<Button
							darkText
							icon={expanded ? <MinusIcon /> : <PlusIcon />}
							onClick={(e) => {
								e.stopPropagation();
								onExpandChange();
							}}
						>
							{expanded ? "Collapse" : "Expand"}
						</Button>
					) : (
						<Button
							className="collection-header-add-btn"
							icon={<PlusIcon />}
							onClick={onAdd}
							rounded={false}
							small
							secondary
						>
							Add Product
						</Button>
					)}
				</PaddedContainer>
			</motion.header>
		);
	}
);

const nameFilter = textFilter({
	placeholder: "Start typing to search...", //
	className: "inp lib-table-search-inp",
	comparator: (a, b) => true,
	delay: 200,
	id: "id",
});
const ProductsView = memo(
	({
		collection,
		products,
		onUpdate,
		initiallyExpanded = false,
		setProducts,
		onboarding,
		onAdd,
		rowEvents,
		onProductOpen,
		onEdit,
		setRefresh,
		setPreviewType,
		user,
		setUser,
		collapsable = true,
	}) => {
		const [selected, setSelected] = useState(
			(collection?.name?.toLowerCase?.() === "home" ||
				collection?.name?.toLowerCase?.() === "home page") &&
				Array.isArray(collection?.products)
				? [...collection.products]
				: []
		);
		const [expanded, setExpanded] = useState(initiallyExpanded);
		const [bulkPayload, setBulkPayload] = useState();
		const safeSetPreviewType = useCallback(
			(...args) => {
				if (typeof setPreviewType === "function") {
					setPreviewType(...args);
				}
			},
			[setPreviewType]
		);
		const {
			open: deleteOpen,
			handleClose: handleDeleteClose,
			handleOpen: handleDeleteOpen,
		} = useModal();

		const bulkType = useRef();

		const safeOnEdit = useCallback(
			(...args) => {
				if (typeof onEdit === "function") {
					onEdit(...args);
				}
			},
			[onEdit]
		);

		const [
			{ loading: bulkLoading, error: bulkError, data: bulkData },
			bulkSave,
			bulkReset,
		] = useResource(
			{ url: PRODUCT_BULK_UPDATE_URL, method: "PUT", data: bulkPayload },
			false
		);

		const doBulkAction = useCallback(
			(key) => {
				if (
					bulkLoading ||
					selected.length < 1 ||
					!bulkActionData[key]
				) {
					return;
				}
				bulkType.current = key;
				setBulkPayload({
					products: selected,
					...bulkActionData[key].payload,
				});
				bulkSave();
			},
			[bulkLoading, selected, bulkSave]
		);

		const handleBulkAction = useCallback(
			(value) => {
				if (value !== "delete") {
					doBulkAction(value);
				} else {
					handleDeleteOpen();
				}
			},
			[doBulkAction, handleDeleteOpen]
		);

		useEffect(() => {
			if (!bulkData) {
				return;
			}

			setProducts((products) => {
				return products
					.map((product) => {
						if (selected.indexOf(product.remote_id) === -1) {
							return product;
						}
						return {
							...product,
							...bulkActionData[bulkType.current].payload,
						};
					})
					.filter((product) => !product.deleted);
			});

			if (bulkType.current === "delete") {
				setSelected([]);
			}
			setRefresh(true);
			safeOnEdit();
			bulkReset();
		}, [
			bulkData,
			bulkReset,
			setProducts,
			selected,
			safeOnEdit,
			setRefresh,
		]);

		const [
			{ loading: saveLoading, data: saveData, error: saveError },
			save,
			resetSave,
		] = useProductSave();
		const saveType = useRef();
		const [activeProductId, setActiveProductId] = useState(null);

		const handleNameChange = useCallback(
			(remoteId, name) => {
				setRefresh(true);
				setProducts((products) => {
					const idx = products.findIndex(
						(product) => product.remote_id === remoteId
					);

					if (idx === -1) {
						return products;
					}
					return [
						...products.slice(0, idx),
						{ ...products[idx], name, draft: !onboarding },
						...products.slice(idx + 1),
					];
				});
				safeOnEdit();
			},
			[setProducts, onboarding, safeOnEdit, setRefresh]
		);

		const handleSave = useCallback(
			(remoteId, changes, type) => {
				if (saveLoading) {
					return;
				}

				const product = products.find(
					(product) => product.remote_id === remoteId
				);
				setActiveProductId(remoteId);
				saveType.current = type;
				save(product, changes);
			},
			[products, save, saveLoading]
		);

		const selectRow = useMemo(
			() => ({
				mode: "checkbox",
				classes: "lib-table-row-selected",
				clickToSelect: false,
				selected,
				hideSelectAll: true,
				hideSelectColumn: true,
				onSelect({ remote_id }, add) {
					if (add) {
						setSelected((selected) => [...selected, remote_id]);
					} else {
						setSelected((selected) => [
							...selected.filter(
								(product) => product !== remote_id
							),
						]);
					}
				},
				onSelectAll(add, rows) {
					if (add) {
						setSelected(rows.map((row) => row.remote_id));
					} else {
						setSelected([]);
					}
				},
			}),
			[selected]
		);

		const productsData = useMemo(() => {
			return (
				collection
					? products.filter(
							(product) =>
								collection.products.indexOf(
									product.remote_id
								) !== -1
					  )
					: products
			).map((product, idx) => {
				return {
					// ...product,
					remote_id: product.remote_id,

					upsell: {
						toggle: product.upsell,
						setToggle: (value) => {
							handleSave(
								product.remote_id,
								{ upsell: value },
								UPSELL_TYPE
							);
						},
						loading:
							activeProductId === product.remote_id &&
							saveType.current === UPSELL_TYPE &&
							saveLoading,
						disabled: saveLoading,
						error:
							activeProductId === product.remote_id &&
							saveError &&
							saveType.current === UPSELL_TYPE,
						stopPropagation: true,
					},
					active: {
						toggle: product.active,
						setToggle: (value) =>
							handleSave(
								product.remote_id,
								{ active: value },
								CROSS_SELL_TYPE
							),
						loading:
							activeProductId === product.remote_id &&
							saveType.current === CROSS_SELL_TYPE &&
							saveLoading,
						disabled: saveLoading,
						error:
							activeProductId === product.remote_id &&
							saveError &&
							saveType.current === CROSS_SELL_TYPE,
						stopPropagation: true,
					},
					priceGroup: {
						price: product.price,
						discountedPrice: product.discounted_price
							? product.discounted_price
							: nonShopifySources.includes(product.source)
							? product.price
							: product.discounted_price,
					},
					display: {
						product,
						remoteId: product.remote_id,
						onNameChange: handleNameChange,
						selected: selected.indexOf(product.remote_id) !== -1,
						onSelect: selectRow.onSelect,
						variantRef: createRef(),
						inStock:
							(user.publisher.inventory_manager &&
								user.publisher.inventory_manager !==
									"Shopify") ||
							!isShopifyShop(user) ||
							!product.shopify_product_id ||
							(product.linked_variant
								? product.linked_variant.quantity > 0
								: !Array.isArray(product.variants)
								? true
								: product.variants.reduce(
										(inStock, variant) =>
											inStock ||
											variant.sell_if_zero ||
											variant.quantity > 0,
										false
								  )),
					},
				};
			});
		}, [
			products,
			saveLoading,
			saveError,
			activeProductId,
			handleSave,
			handleNameChange,
			collection,
			selected,
			selectRow,
			user,
		]);

		const rowClasses = useCallback(
			(product) =>
				`lib-table-row${
					!product.display.inStock ? " lib-table-row-red" : ""
				}`,
			[]
		);

		const handleExpandChange = useCallback(() => {
			if (!collapsable) {
				return;
			}

			setExpanded((expanded) => {
				return !expanded;
			});
		}, [collapsable]);

		const columns = useMemo(
			() => [
				{
					sort: true,
					text: (
						<>
							Product{" "}
							<PaddedContainer className="lib-table-sort-icon">
								<BsFillCaretDownFill />
							</PaddedContainer>
						</>
					),
					dataField: "display",
					attrs: (cell, row) => ({
						"data-testid": `product-display-${row.remote_id}`,
					}),
					headerAttrs: {
						"data-testid": "products-view-display-header",
					},
					headerClasses: "lib-table-display-header",
					filter: nameFilter,
					filterValue: (display) => display.product.name,
					formatter: (display) => {
						return (
							<ProductDisplay
								onboarding={onboarding}
								user={user}
								{...display}
								editName={false}
								showVariantModal={false}
							/>
						);
					},
					headerSortingClasses,

					sortValue(display) {
						return display.product.name;
					},
				},
				{
					text: onboarding ? (
						<></>
					) : (
						<>
							Cross-sell{" "}
							<PaddedContainer className="lib-table-sort-icon">
								<BsFillCaretDownFill />
							</PaddedContainer>
						</>
					),
					dataField: "active",
					formatter: (cell, product) => (
						<Toggle marked={onboarding} {...product.active} />
					),
					attrs: (cell, row) => ({
						"data-testid": `product-active-${row.remote_id}`,
					}),

					sort: true,
					headerSortingClasses,
					headerClasses: "lib-table-thin-2",
					sortValue(active) {
						return active.toggle;
					},
				},
				...(onboarding
					? []
					: [
							{
								text: (
									<>
										{`Price ${
											!onboarding ? "(Original)" : ""
										} `}
										<PaddedContainer className="lib-table-sort-icon">
											<BsFillCaretDownFill />
										</PaddedContainer>
									</>
								),
								dataField: "priceGroup",
								align: "right",
								attrs: (cell, row) => ({
									"data-testid": `product-price-${row.remote_id}`,
								}),

								formatter: (priceGroup) => (
									<ProductPrice {...priceGroup} />
								),
								sort: true,
								headerSortingClasses,
								headerClasses: "lib-table-thin lib-table-right",
								sortValue(price) {
									return Number(price.discountedPrice);
								},
							},
							{
								text: "",
								dataField: "edit",
								attrs: (cell, row) => ({
									"data-testid": `product-edit-${row.remote_id}`,
								}),

								headerClasses:
									"lib-table-thin-3 lib-table-right",
								formatter: (priceGroup) => (
									<Asset
										className={"products-view-table-edit"}
										size={17}
										color={FONT_COLOR.MID}
									>
										<PencilIcon />
									</Asset>
								),
							},
					  ]),
			],
			[onboarding, user]
		);

		useEffect(() => {
			if (!saveData) {
				return;
			}
			setProducts((products) =>
				products.map((product) =>
					product.remote_id === activeProductId
						? { ...product, ...saveData }
						: product
				)
			);
			setRefresh(true);
			if (saveType.current === UPSELL_TYPE) {
				safeSetPreviewType(PREVIEW_TYPES_IDX.UPSELL);
			} else if (saveType.current === CROSS_SELL_TYPE) {
				safeSetPreviewType(PREVIEW_TYPES_IDX.CROSS_SELL);
			}

			setActiveProductId(null);
			safeOnEdit();
			resetSave();
		}, [
			saveData,
			resetSave,
			onUpdate,
			activeProductId,
			setRefresh,
			setProducts,
			safeSetPreviewType,
			safeOnEdit,
		]);

		useEffect(() => {
			const hasUpsell = products.reduce(
				(acc, product) => acc || product.upsell,
				false
			);

			const hasCrossSell = products.reduce(
				(acc, product) => acc || product.active,
				false
			);

			setUser((user) => ({
				...user,
				publisher: {
					...user.publisher,
					has_upsell_products: hasUpsell,
					has_cross_sell_products: hasCrossSell,
				},
			}));
		}, [products, setUser]);

		const handleSelectAll = useCallback(
			({ target: { checked } }) => {
				if (checked) {
					selectRow.onSelectAll(true, productsData);
				} else {
					selectRow.onSelectAll(false, []);
				}
			},
			[selectRow, productsData]
		);

		const paginationOptions = useMemo(
			() => ({
				sizePerPage: 20,
				alwaysShowAllBtns: true,
				sizePerPageRenderer: () => null,
				pageListRenderer: ({ pages, onPageChange }) => {
					const last = pages.length - 4;
					const pagesShow = pages.filter(
						(page) => page.page !== ">>" && page.page !== "<<"
					);
					// const active = pagesShow.find((page) => page.active)?.page;
					return (
						<Pagination
							pages={pagesShow}
							onPageChange={onPageChange}
						/>
					);
				},
			}),
			[]
		);

		return (
			<PaddedContainer
				className={`products-view ${
					onboarding ? "products-view-onboarding" : ""
				}`}
				data-testid="products-view"
				marginTop={SPACING.REGULAR}
			>
				<AnimatePresence mode="wait">
					<>
						<CollectionHeader
							collection={collection}
							selectedNum={selected.length}
							onProductOpen={onProductOpen}
							loading={bulkLoading}
							onSelectAll={handleSelectAll}
							onBulkAction={handleBulkAction}
							products={productsData}
							expanded={expanded}
							onAdd={onAdd}
							setProducts={setProducts}
							collapsable={collapsable}
							onExpandChange={handleExpandChange}
							selectable={!onboarding}
						/>
						{expanded && (
							<motion.section
								className="section"
								variants={anim.table}
								initial="initial"
								animate="animate"
								exit="exit"
								// layout
							>
								<Table
									rowEvents={rowEvents}
									keyField="remote_id"
									className="lib-table"
									selectRow={selectRow}
									rowClasses={rowClasses}
									columns={columns}
									data={productsData}
									filter={filterFactory()}
									filterPosition="inline"
									pagination={paginationFactory(
										paginationOptions
									)}
								/>
							</motion.section>
						)}
					</>
				</AnimatePresence>
				<Confirmation
					open={deleteOpen}
					onClose={handleDeleteClose}
					onCancel={handleDeleteClose}
					onConfirmation={() => doBulkAction("delete")}
					title={`Delete ${selected.length} product${
						selected.length !== 1 ? "s" : ""
					}`}
					cancelButtonText="Cancel"
					confirmationButtonText="Delete"
				>
					<PaddedContainer>
						<PaddedContainer flexContent>
							<Text
								size={FONT_SIZE.BODY}
								color={FONT_COLOR.MID}
								hPadding={SPACING.MICRO}
							>
								Are you sure you want to{" "}
							</Text>
							<Text thick size={FONT_SIZE.BODY}>
								delete {selected.length} product
								{selected.length !== 1 ? "s" : ""}
							</Text>
							<Text size={FONT_SIZE.BODY} color={FONT_COLOR.MID}>
								? This change is not reversible.
							</Text>
						</PaddedContainer>
					</PaddedContainer>
				</Confirmation>
			</PaddedContainer>
		);
	}
);

export default ProductsView;
