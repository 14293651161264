const copyLegacy = (text) => {
	return navigator.clipboard.writeText(text).catch((err) => {
		console.log("copy failed :>> ", err);
	});
};

const copyClipboard = (text) => {
	if (window.ClipboardItem) {
		const type = "text/plain";
		const blob = new Blob([text], { type });
		const data = [new ClipboardItem({ [type]: blob })];

		return navigator.clipboard
			.write(data)
			.catch((err) => console.log("Copy to clipboard  failed :>> ", err));
	} else {
		console.log("called legacy copy with :>> ", text);
		return copyLegacy(text);
	}
};

export default copyClipboard;
