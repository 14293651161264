import React, { memo, useState } from "react";
import useIdentity from "../../hooks/useIdentity";
import { BorderedCard, LargeText } from "../CoreUI";
import "./completion-screen.css";

import ItemsCarousel from "react-items-carousel";

import { useCallback } from "react";

import { Button } from "@disco/disco_core";
import { motion } from "framer-motion";
import { useRef } from "react";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import { SAMPLE_BRANDS } from "../../conf";
import {
	PLATFORM_ONBOARDING_CLICK_CTA_SUCCESS_SETUP,
	PLATFORM_ONBOARDING_PAGE_VIEW_SUCCESS,
} from "../../events";
import useConfetti from "../../hooks/useConfetti";
import useTrack from "../../hooks/useTrack";
import anim from "../../utils/anim";
import isFunction from "../../utils/isFunction";
import SuccessConfetti from "../SuccessConfetti";
import isShopifyShop from "../../utils/isShopifyShop";
import hasMigratedToExtensions from "../../utils/hasMigratedToExtensions";

const CompletionModal = memo(({ onClose }) => {
	const track = useTrack(PLATFORM_ONBOARDING_PAGE_VIEW_SUCCESS);

	const { user } = useIdentity();

	const { running, runConfetti } = useConfetti();

	const handleClose = useCallback(async () => {
		track(PLATFORM_ONBOARDING_CLICK_CTA_SUCCESS_SETUP, {});
		await runConfetti();
		if (isFunction(onClose)) onClose();
	}, [onClose, runConfetti, track]);

	const [activeItemIndex, setActiveItemIndex] = useState(0);

	const btnRef = useRef();

	const [largeText, buttonText] =
		isShopifyShop(user) && hasMigratedToExtensions(user)
			? [
					"Start growing your customer base with DiscoFeed after a quick setup on Shopify's Checkout Pages.",
					"View setup instructions",
			  ]
			: isShopifyShop(user)
			? [
					"Next, configure the DiscoFeed to start growing your customer base.",
					"Let’s get started",
			  ]
			: [
					"First let’s connect your store to unlock Disco’s tools and grow your business. Let’s get set up!",
					"Connect your store",
			  ];

	return (
		<motion.section
			className="completion-screen"
			variants={anim.page}
			initial={"initial"}
			animate={"animate"}
			exit={"exit"}
		>
			<SuccessConfetti running={running} target={btnRef.current} />

			<BorderedCard centered heading="Welcome to Disco">
				<img
					src={user.publisher.logo_url}
					alt=""
					className="completion-screen-user-img"
				/>

				<section className="completion-screen-brands">
					<ItemsCarousel
						requestToChangeActive={setActiveItemIndex}
						activeItemIndex={activeItemIndex}
						numberOfCards={(window.innerWidth * 0.37) / 100}
						gutter={0}
						infiniteLoop
						leftChevron={
							<span className="completion-screen-chevron">
								<MdChevronLeft />
							</span>
						}
						rightChevron={
							<span className="completion-screen-chevron completion-screen-chevron-right">
								{" "}
								<MdChevronRight />
							</span>
						}
						chevronWidth={45}
					>
						{SAMPLE_BRANDS.map(({ logo_url: img }, idx) => (
							<img
								src={img}
								alt=""
								className={`completion-screen-brand`}
								key={idx}
							/>
						))}
					</ItemsCarousel>
				</section>

				<LargeText>{largeText}</LargeText>
				<Button
					rounded
					onClick={handleClose}
					ref={btnRef}
					disabled={running}
				>
					{buttonText}
				</Button>
			</BorderedCard>
		</motion.section>
	);
});

export default CompletionModal;
