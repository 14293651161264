import {
	Button,
	FONT_COLOR,
	FONT_SIZE,
	LinkButton,
	Loader,
	SPACING,
	StatusLabel,
	Text,
} from "@disco/disco_core";
import React, { memo, useCallback, useEffect } from "react";

import { motion } from "framer-motion";
import { GET_BACK_URL, GET_NEXT_URL } from "../../conf";
import {
	PLATFORM_SETUP_BACK,
	PLATFORM_SETUP_LOGOUT,
	PLATFORM_SETUP_NEXT,
	PLATFORM_SETUP_VIEW,
} from "../../events";
import useResource from "../../hooks/useResource";
import useTrack from "../../hooks/useTrack";
import { BaseTooltip, FormStatus, ProgressBar } from "../CoreUI";

import bulbIcon from "../../img/bulb.png";
import logoWhite from "../../img/logo-new-white.png";
import anim from "../../utils/anim";
import { logoutSegment } from "../../utils/segment";
import tokenStorage from "../../utils/tokenStorage";

import { useNavigate } from "react-router-dom";
import useIdentity from "../../hooks/useIdentity";
import checkShopifyReinstall from "../../utils/checkShopifyReinstall";
import parseError from "../../utils/parseError";
import "./onboarding-step.css";

import background from "../../img/onboarding/profile.png";
import useOnboardingSteps from "../../hooks/useOnboardingSteps";

export const FEED_ONBOARDING_STEPS = [
	{ name: "Products" },
	{ name: "Exclusions" },
	{ name: "Activate" },
];

const EMPTY_OBJECT = {};

const OnboardingStepMain = memo(
	({
		heading,
		subText,
		children,
		error,
		showNext = true,
		showLogout = true,
		logoutText = null,
		onLogout,
		showBack = false,
		backText = "Back",
		showFooter = true,
		nextText,
		parseErrors = false,
		footerExtra = null,
		footerExtraPreError = null,
		steps,
		activeStep = 0,
		extraContent = null,
		bright = false,
		nextGrayed = false,
		noPad,

		extraButtons = null,

		nextLoading,
		backLoading,
		onBack,
		onNext,
		allowNext,
		onReinstall,
		backError,
		nextError,
		...rest
	}) => {
		return (
			<main
				className={`onboarding-step-main ${
					bright ? "onboarding-step-main-bright" : ""
				} ${noPad ? "onboarding-step-main-no-pad" : ""}`}
				{...rest}
			>
				<header className="onboarding-step-main-header">
					{showLogout && (
						<Text className="onboarding-step-logout">
							<a
								href="#"
								onClick={onLogout}
								disabled={nextLoading || backLoading}
							>
								{logoutText || "Save and logout"}
							</a>
						</Text>
					)}

					{!!steps && (
						<ProgressBar
							stepData={steps}
							activeStep={activeStep}
							wide
							noContraction
							noFinalize
							darkTitle
						/>
					)}
				</header>
				<main className="onboarding-step-main-content">
					<section className="onboarding-step-main-content-padded">
						<section className="onboarding-step-main-hero">
							<Text size={FONT_SIZE.HEADER}>{heading}</Text>
							<Text
								size={FONT_SIZE.SUB_TITLE}
								color={FONT_COLOR.MID}
								marginTop={SPACING.REGULAR}
								marginBottom={SPACING.REGULAR}
								className="onboarding-step-main-subheading"
							>
								{subText}
							</Text>
						</section>
						{children}
					</section>
					{extraContent}
				</main>
				{showFooter && (
					<footer className="onboarding-step-main-footer">
						<section>
							{showBack && !backLoading && (
								<Button
									rounded
									className="onboarding-step-back"
									onClick={onBack}
									disabled={backLoading || nextLoading}
									secondary
								>
									{backText}
								</Button>
							)}
							{backLoading && <Loader />}
						</section>

						{footerExtraPreError && (
							<span className="onboarding-step-footer-extra">
								{footerExtraPreError}
							</span>
						)}

						{(backError || nextError || error) && (
							<StatusLabel type="error" noBorder noShadow>
								{nextError
									? parseErrors
										? parseError(nextError)
										: "Could not proceed. Try again later."
									: backError
									? parseErrors
										? parseError(backError)
										: "Could not go back. Try again later."
									: error}
							</StatusLabel>
						)}
						{!nextLoading && showNext && (
							<>
								{error &&
									((parseErrors &&
										checkShopifyReinstall(
											parseError(error)
										)) ||
										checkShopifyReinstall(error)) && (
										<Button
											large
											light
											onClick={onReinstall}
											className="onboarding-step-main-footer-reinstall"
										>
											Re-Install App
										</Button>
									)}
							</>
						)}
						{footerExtra && (
							<span className="onboarding-step-footer-extra">
								{footerExtra}
							</span>
						)}
						{!footerExtra && !footerExtraPreError && (
							<section>
								{!nextLoading && showNext && (
									<section className="onboarding-step-main-footer-next-bar">
										<Button
											onClick={onNext}
											rounded
											grayGradient={
												!allowNext || nextGrayed
											}
											disabled={!allowNext}
										>
											{nextText}
										</Button>
										{extraButtons}
									</section>
								)}
								{nextLoading && <Loader />}
							</section>
						)}
					</footer>
				)}
			</main>
		);
	}
);

const OnboardingStepAside = memo(
	({
		heading,
		image = background,
		imageWidth,
		imageHeight,
		imageUp,
		logo,
		children,
		tooltip = null,
		heroProps,
	}) => {
		return (
			<aside
				className="onboarding-step-aside"
				style={{ zIndex: imageUp ? 2 : 0 }}
			>
				{tooltip && (
					<BaseTooltip
						type="dark"
						className={`onboarding-step-aside-tooltip ${
							tooltip.image
								? "onboarding-step-aside-tooltip-img"
								: ""
						}`}
					>
						{tooltip.image && <img alt="" src={tooltip.image} />}
						{tooltip.text}
					</BaseTooltip>
				)}
				{image && (
					<motion.img
						src={image}
						alt={heading}
						{...(heroProps ? heroProps : {})}
						style={{
							width: imageWidth
								? imageWidth
								: imageHeight
								? ""
								: "65%",
							height: imageHeight ? imageHeight : "",
						}}
						className="onboarding-step-aside-hero"
					/>
				)}
				{logo && (
					<img
						className="onboarding-step-aside-logo"
						src={logoWhite}
						alt=""
					/>
				)}
				{heading && <h1>{heading}</h1>}
				{children}
			</aside>
		);
	}
);

const OnboardingStep = memo(
	({
		aside: { content = null, right, ...asideProps } = EMPTY_OBJECT,
		children,
		heading,
		subText,
		className = "",
		customRenderer,
		next,
		nextDisabled,
		onNext,
		setNext,
		logout,
		setLogout,
		onLogout,
		onBack,
		backLoading,
		nextLoading,

		logoutEvent = PLATFORM_SETUP_LOGOUT,
		viewEvent = PLATFORM_SETUP_VIEW,
		nextEvent = PLATFORM_SETUP_NEXT,
		backEvent = PLATFORM_SETUP_BACK,

		status,

		...rest
	}) => {
		const { user, setUser } = useIdentity();
		const track = useTrack(viewEvent);
		const navigate = useNavigate();
		const steps = useOnboardingSteps();

		const [
			{ loading: backLoadingInternal, error: backError, data: backData },
			goBack,
			resetBack,
		] = useResource({ url: GET_BACK_URL(user), method: "PUT" }, false);
		const [
			{ loading: nextLoadingInternal, error: nextError, data: nextData },
			goNext,
			resetNext,
		] = useResource({ url: GET_NEXT_URL(user), method: "PUT" }, false);

		const { logout: logoutUser } = useIdentity();

		const doLogout = useCallback(() => {
			if (
				backLoading ||
				backLoadingInternal ||
				nextLoading ||
				nextLoadingInternal
			) {
				return;
			}
			tokenStorage.clear();
			logoutSegment();
			navigate("/login");
			logoutUser();
		}, [
			navigate,
			backLoading,
			backLoadingInternal,
			nextLoading,
			nextLoadingInternal,
			logoutUser,
		]);

		const handleLogout = useCallback(() => {
			if (typeof onLogout === "function") {
				onLogout();
			} else {
				doLogout();
			}
			track(logoutEvent, {});
		}, [doLogout, onLogout]);

		useEffect(() => {
			if (!logout) {
				return;
			}

			if (logout) {
				doLogout();
				setLogout(false);
			}
		}, [logout, setLogout, doLogout]);

		useEffect(() => {
			if (backData && backData.setup_status) {
				setUser((user) => ({
					...user,
					setup_status: backData.setup_status,
				}));
				resetBack();
				resetNext();
			}
		}, [backData, setUser, resetBack, resetNext, track]);

		const handleBack = useCallback(() => {
			track(backEvent, {});
			if (typeof onBack === "function") {
				onBack();
			} else {
				goBack();
			}
		}, [onBack, goBack]);

		const handleNext = useCallback(() => {
			track(nextEvent, {});
			if (typeof onNext === "function") {
				onNext();
			} else {
				goNext();
			}
		}, [onNext, goNext]);

		const handleReinstall = useCallback(() => {
			window.location.href = process.env.REACT_APP_SHOPIFY_APP_LINK;
		}, []);

		useEffect(() => {
			if (next && typeof setNext === "function") {
				goNext();
				setNext(false);
			}
		}, [next, setNext, goNext]);

		useEffect(() => {
			if (nextData) {
				setUser((user) => ({
					...user,
					setup_status: nextData.setup_status,
				}));
				resetNext();
				resetBack();
			}
		}, [nextData, resetNext, setUser, resetBack, track]);

		const allowNext =
			!backLoadingInternal &&
			!backLoading &&
			!nextLoadingInternal &&
			!nextLoading &&
			!nextDisabled;

		return typeof customRenderer === "function" ? (
			customRenderer({
				backLoading: backLoadingInternal || backLoading,
				nextLoading: nextLoadingInternal || nextLoading,
				onNext: handleNext,
				onBack: handleBack,
				allowNext: allowNext,
				onReinstall: handleReinstall,
				backError: backError,
				nextError: nextError,
				onLogout: handleLogout,
			})
		) : (
			<motion.section
				variants={anim.setupCard}
				initial="initial"
				animate="animate"
				exit="exit"
				className={`onboarding-step ${
					right ? "onboarding-step-right" : ""
				} ${className}`}
			>
				<OnboardingStepAside right={right} {...asideProps}>
					{content}
				</OnboardingStepAside>
				<OnboardingStepMain
					heading={heading}
					subText={subText}
					backLoading={backLoadingInternal || backLoading}
					nextLoading={nextLoadingInternal || nextLoading}
					onNext={handleNext}
					onBack={handleBack}
					allowNext={allowNext}
					onReinstall={handleReinstall}
					backError={backError}
					nextError={nextError}
					onLogout={handleLogout}
					steps={steps}
					activeStep={steps.findIndex(
						(step) => step.status === status
					)}
					{...rest}
				>
					{children}
				</OnboardingStepMain>
			</motion.section>
		);
	}
);

export const CalculatorInfo = React.memo(
	({ heading, text, subtext, icon, minimal = false, className = "" }) => {
		return (
			<section
				className={`calculator-info ${
					minimal ? "calculator-info-minimal" : ""
				} ${className}`}
			>
				<img src={icon ? icon : bulbIcon} alt="Calculator" />
				<main>
					{heading && <h3>{heading}</h3>}
					<div>
						{text && (
							<h2 className={subtext ? "has-subtext" : ""}>
								{text}
							</h2>
						)}
						{subtext && <h4>{subtext}</h4>}
					</div>
				</main>
			</section>
		);
	}
);

export default OnboardingStep;
