import React, {
	forwardRef,
	memo,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from "react";
import WEBHOOK_LOGO_IMG from "../../img/webhook-logo.png";
import SettingsModule, { SettingsModuleSection } from "./SettingsModule";

import {
	Asset,
	Button,
	Card,
	CodeSnippet,
	FONT_COLOR,
	FONT_SIZE,
	FileInput,
	Loader,
	Modal,
	PaddedContainer,
	SPACING,
	STATUS,
	StatusLabel,
	TabSwitcher,
	Text,
	TitleRow,
	UploadIcon,
	useModal,
} from "@disco/disco_core";
import {
	DOWNLOAD_PRIVACY_HISTORICAL_SAMPLE_CSV,
	UPLOAD_PRIVACY_HISTORICAL_DATA_CSV,
} from "../../conf";
import useResource from "../../hooks/useResource";
import {
	INTEGRATIONS_MODULE_PRIVACY_WEBHOOK_URL,
	INTEGRATIONS_MODULE_WEBHOOK_CODE,
	INTEGRATIONS_MODULE_WEBHOOK_CODE_LANGUAGES,
} from "../../utils/getIntegrationsPrivacyWebhookCode";
import isFunction from "../../utils/isFunction";
import parseError from "../../utils/parseError";
import "./privacy-module.css";

const INTEGRATION_MODULE_WEBHOOK_INSTRUCTIONS = (user) => {
	const {
		publisher: {
			api_key: { private_key: privateKey, public_key: publicKey },
			remote_id: publisherId,
		},
	} = user;
	return [
		{
			heading: "URL",
			description: `${INTEGRATIONS_MODULE_PRIVACY_WEBHOOK_URL}`,
		},
		{ heading: "Content-Type", description: "application/json" },
		{ heading: "X-Public-Key", description: publicKey },
		{ heading: "X-Private-Key", description: privateKey },
		{ heading: "X-publisher", description: publisherId },
	];
};

const PrivacyModuleWebhook = memo(({ user }) => {
	const [activeTab, setActiveTab] = useState(0);
	const tabData = useMemo(
		() => [
			{
				text: "Javascript",
				language: INTEGRATIONS_MODULE_WEBHOOK_CODE_LANGUAGES.JAVASCRIPT,
			},
			{
				text: "Python",
				language: INTEGRATIONS_MODULE_WEBHOOK_CODE_LANGUAGES.PYTHON,
			},
			{
				text: "Node",
				language: INTEGRATIONS_MODULE_WEBHOOK_CODE_LANGUAGES.NODE,
			},
			// {
			// 	text: "PHP",
			// 	language: INTEGRATIONS_MODULE_WEBHOOK_CODE_LANGUAGES.PHP,
			// },
		],
		[]
	);

	const codeDetails = useMemo(
		() =>
			INTEGRATIONS_MODULE_WEBHOOK_CODE({
				user,
			})[tabData[activeTab].language],
		[user, tabData, activeTab]
	);

	const handleTabChange = useCallback(
		({ target: { value } }) => setActiveTab(value),
		[]
	);

	return (
		<SettingsModule>
			<SettingsModuleSection
				title={
					<PaddedContainer centerContent>
						<Asset
							size={20}
							color={FONT_COLOR.DARK}
							marginRight={SPACING.TINY}
						>
							<img src={WEBHOOK_LOGO_IMG} alt="" />
						</Asset>
						Webhook
					</PaddedContainer>
				}
				tightTop
				headerChildren={
					<PaddedContainer
						className="privacy-module-header-children"
						flexContent
					>
						<TabSwitcher
							options={tabData}
							curved
							dynamicWidth
							value={activeTab}
							onChange={handleTabChange}
							resizeOnMount
							small
						/>
					</PaddedContainer>
				}
			>
				<Text
					size={FONT_SIZE.BODY}
					color={FONT_COLOR.MID}
					vPadding={SPACING.REGULAR}
					thin
					className="privacy-module-description"
				>
					Disco provides you webhooks to automatically communicate
					opt-outs from your website in order to honour your
					customers’ privacy. Please follow the instructions below to
					setup webhooks that feed information to Disco
				</Text>
				<Card
					vPadding={SPACING.MEDIUM}
					hPadding={SPACING.MEDIUM}
					noShadow
				>
					<TitleRow
						title="Your script"
						tooltip="This is the script that will be executed when a webhook is triggered"
					/>
					<PaddedContainer vPadding={SPACING.REGULAR} tightBottom>
						<CodeSnippet
							alwaysOpen
							heightParams={{ open: 320, close: 160 }}
							value={codeDetails.code.trim()}
						/>
					</PaddedContainer>
					{INTEGRATION_MODULE_WEBHOOK_INSTRUCTIONS(user).map(
						({ heading, description }) => (
							<PaddedContainer
								vPadding={SPACING.MEDIUM}
								tightBottom
							>
								<TitleRow
									title={heading}
									subtitle={description}
								/>
							</PaddedContainer>
						)
					)}
				</Card>
			</SettingsModuleSection>
		</SettingsModule>
	);
});

const IntegrationModuleHistoricalModal = memo(
	({ user, setUser, onClose, onDone, ...rest }) => {
		const [addData, setAddData] = useState(null);
		const [fileError, setFileError] = useState("");
		const [file, setFile] = useState(null);
		const [{ loading, data, error }, startAdd, reset] = useResource(
			{
				url: UPLOAD_PRIVACY_HISTORICAL_DATA_CSV,
				method: "POST",
				headers: {
					"Content-Type": "multipart/form-data",
					"Content-Disposition": "name=file; filename=report.csv",
					filename: "historical_data.csv",
				},
				data: addData,
			},
			false
		);
		const isCSV = useCallback((name) => {
			const parts = name.split(".");
			return parts[parts.length - 1].toLowerCase() === "csv";
		}, []);

		const add = useCallback(() => {
			if (!file) {
				return setFileError("Please select a CSV file to upload");
			}
			if (!isCSV(file.name)) {
				return setFileError(
					"You can only use CSV files. Please select a CSV file"
				);
			}
			setFileError("");
			const formData = new FormData();
			formData.append("users", file);
			setAddData(formData);
			startAdd();
		}, [file, isCSV, startAdd]);

		useEffect(() => {
			if (data) {
				onClose();
				if (isFunction(onDone)) {
					onDone();
				}
				reset();
			}
		}, [data, onClose, reset, onDone]);

		return (
			<Modal title="Upload CSV" onClose={onClose} {...rest}>
				<PaddedContainer marginTop={SPACING.SMALL}>
					<FileInput
						accept=".csv"
						onChange={(e) => setFile(e.target.files[0])}
					/>
				</PaddedContainer>
				{!loading && (
					<Button middle marginTop={SPACING.REGULAR} onClick={add}>
						Add
					</Button>
				)}
				{loading && <Loader middle marginTop={SPACING.REGULAR} />}
				{(fileError || error) && (
					<StatusLabel marginTop={SPACING.REGULAR}>
						{fileError || parseError(error)}
					</StatusLabel>
				)}
			</Modal>
		);
	}
);

const PrivacyModuleHistorical = memo(() => {
	const { open, handleOpen, handleClose } = useModal(false);
	const [succ, setShowSucc] = useState(false);
	const handleDone = useCallback(() => setShowSucc(true), []);
	return (
		<SettingsModule>
			<SettingsModuleSection
				title={
					<PaddedContainer centerContent>
						<Asset
							size={20}
							color={FONT_COLOR.DARK}
							marginRight={SPACING.TINY}
						>
							<UploadIcon />
						</Asset>
						Historical imports
					</PaddedContainer>
				}
				tightTop
			>
				<Text
					size={FONT_SIZE.BODY}
					color={FONT_COLOR.MID}
					vPadding={SPACING.REGULAR}
					className="privacy-module-description"
				>
					Have a list of users that have already opted out? Upload a
					CSV to keep us up to date
				</Text>
				<PaddedContainer
					flexContent
					className="privacy-module-history-buttons"
				>
					<Button onClick={handleOpen}>Upload CSV</Button>
					<PaddedContainer
						Element="a"
						marginLeft={10}
						href={DOWNLOAD_PRIVACY_HISTORICAL_SAMPLE_CSV}
						target="_blank"
						rel="noopener noreferrer"
					>
						<Button secondary>View Sample CSV</Button>
					</PaddedContainer>
				</PaddedContainer>
				{succ && (
					<StatusLabel
						type={STATUS.SUCCESS}
						marginTop={SPACING.REGULAR}
					>
						We have received your historical opt out requests. It
						may take upto 24 hours for us to process them.
					</StatusLabel>
				)}
			</SettingsModuleSection>

			<IntegrationModuleHistoricalModal
				open={open}
				onDone={handleDone}
				onClose={handleClose}
			/>
		</SettingsModule>
	);
});

const PrivacyModule = memo(
	forwardRef(({ user, setUser }, parentRef) => {
		return (
			<section ref={parentRef}>
				<PrivacyModuleWebhook user={user} setUser={setUser} />
				<PrivacyModuleHistorical user={user} setUser={setUser} />
			</section>
		);
	})
);

export default PrivacyModule;
