import React, { memo, useCallback } from "react";
// import useTrack from "../../hooks/useTrack";
import LOGO_IMG from "../../img/logo-light.svg";
// import MobilePageBanner from "../../img/mobileBanner.png";
import { motion } from "framer-motion";
import { Button, ModalSelectHLine } from "../../components/CoreUI";
import MobilePageImg from "../../img/mobilePage.png";
import "./mobile-page.css";
// import { PLATFORM_REQUEST_MOBILE_CLICK } from "../../events";
import useIdentity from "../../hooks/useIdentity";
import anim from "../../utils/anim";
// import { useNavigate } from "react-router-dom";
import { logoutSegment } from "../../utils/segment";
import tokenStorage from "../../utils/tokenStorage";

const MobilePage = memo(({ maintenance = false }) => {
	// const [hasRequestedMobile, setHasRequestedMobile] = useState(
	// 	!!localStorage.getItem("hasRequestedMobile")
	// );

	// const handleFacebookModalClose = useCallback(() => {
	// 	setFacebookModalOpen(false);
	// }, []);
	// const handleFacebookInfoModalClose = useCallback(() => {
	// 	setFacebookInfoModalOpen(false);
	// }, []);
	// const track = useTrack();

	// const { logout } = useIdentity();
	const handleButtonClick = useCallback(() => {
		// if (hasRequestedMobile) {
		// 	return;
		// }
		// track(PLATFORM_REQUEST_MOBILE_CLICK, {});
		// localStorage.setItem("hasRequestedMobile", "true");
		// setHasRequestedMobile(true);
		tokenStorage.clear();
		// logout();
		logoutSegment();
	}, []);

	return (
		<section className="mobile-page-root">
			<div className="mobile-page-banner">
				<img src={LOGO_IMG} alt="" />
			</div>
			<motion.div
				className="mobile-page-content"
				variants={anim.staggeredFade}
				initial="initial"
				animate="animate"
				exit="exit"
			>
				<img src={MobilePageImg} alt="" className="mobile-page-img" />
				<h2>
					{maintenance
						? "We're under maintenance"
						: "Mobile Friendly Coming Soon"}
				</h2>
				<div className="mobile-page-content-subheading">
					{maintenance
						? "We'll be back shortly"
						: "In the meantime, try a laptop or desktop"}
				</div>
				{/* {!maintenance && hasRequestedMobile && (
					<motion.div
						className="mobile-page-click-success"
						variants={anim.staggeredFade}
						initial="initial"
						animate="animate"
						exit="exit"
					>
						✅ Thanks for the feedback!
					</motion.div>
				)} */}
				{/* {!maintenance && !hasRequestedMobile && ( */}
				{!maintenance && (
					<Button type="button" light onClick={handleButtonClick}>
						Back to sign in page
					</Button>
				)}
			</motion.div>

			<div className="mobile-page-footer">
				<ModalSelectHLine />
				<div className="mobile-page-footer-content">
					I need to talk to{" "}
					<a href="mailto:support@disconetwork.com">
						support@disconetwork.com
					</a>
				</div>
			</div>
		</section>
	);
});

export default MobilePage;

// import React, { memo, useState, useCallback } from "react";
// import useTrack from "../../hooks/useTrack";
// import MobilePageBannerLogo from "../../img/mobileBannerLogo.png";
// import MobilePageBanner from "../../img/mobileBanner.png";
// import MobilePageImg from "../../img/mobilePage.png";
// import "./mobile-page.css";
// import { Button, ModalSelectHLine } from "../../components/CoreUI";
// import { motion } from "framer-motion";
// import { PLATFORM_REQUEST_MOBILE_CLICK } from "../../events";
// import anim from "../../utils/anim";

// const MobilePage = memo(({ isMobile }) => {
// 	const [hasRequestedMobile, setHasRequestedMobile] = useState(
// 		!!localStorage.getItem("hasRequestedMobile")
// 	);
// 	const track = useTrack();

// 	const handleButtonClick = useCallback(() => {
// 		if (hasRequestedMobile) {
// 			return;
// 		}
// 		track(PLATFORM_REQUEST_MOBILE_CLICK, {});
// 		localStorage.setItem("hasRequestedMobile", "true");
// 		setHasRequestedMobile(true);
// 	}, [track, hasRequestedMobile]);

// 	return (
// 		<section className="mobile-page-root">
// 			<div
// 				className="mobile-page-banner"
// 				style={{ backgroundImage: `url(${MobilePageBanner})` }}
// 			>
// 				<img src={MobilePageBannerLogo} alt="" />
// 			</div>
// 			<motion.div
// 				className="mobile-page-content"
// 				variants={anim.simpleFade}
// 				initial="initial"
// 				animate="animate"
// 				exit="exit"
// 			>
// 				<img src={MobilePageImg} alt="" className="mobile-page-img" />
// 				<h2>
// 					{isMobile
// 						? "Mobile Friendly Coming Soon"
// 						: "We're under maintenance!"}
// 				</h2>
// 				<div className="mobile-page-content-subheading">
// 					{/* In the meantime, try a laptop or desktop */}
// 					We'll be back shortly.
// 				</div>
// 				{/* {hasRequestedMobile && (
// 					<motion.div
// 						className="mobile-page-click-success"
// 						variants={anim.simpleFade}
// 						initial="initial"
// 						animate="animate"
// 						exit="exit"
// 					>
// 						✅ Thanks for the feedback!
// 					</motion.div>
// 				)}
// 				{!hasRequestedMobile && (
// 					<Button type="button" onClick={handleButtonClick}>
// 						I really, really want mobile
// 					</Button>
// 				)} */}
// 			</motion.div>

// 			<div className="mobile-page-footer">
// 				<ModalSelectHLine />
// 				<div className="mobile-page-footer-content">
// 					I need to talk to{" "}
// 					<a href="mailto:support@disconetwork.com">
// 						support@disconetwork.com
// 					</a>
// 				</div>
// 			</div>
// 		</section>
// 	);
// });

// export default MobilePage;
