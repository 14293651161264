import { useCallback, useEffect } from "react";
import { useResource } from "@disco/disco_core";
import { FETCH_USER_OSP_STATUS } from "../conf";
import { updateUser } from "../utils/userManipulators";
import useIdentity from "./useIdentity";

export const TRAIT_STATUS = {
	WAITING: "WAITING",
	LOADING: "LOADING",
	ERROR: "ERROR",
	SUCCESS: "SUCCESS",
};
export const PUBLISHER_TRAIT = {
	IS_OSP_CHECKOUT_ACTIVE: "is_osp_checkout_active",
};
const traitGenerator = ({
	remoteKey = "",
	status = TRAIT_STATUS.WAITING,
	value = undefined,
	getDirectly = false,
} = {}) => {
	const trait = {
		status,
		value,
	};
	if (getDirectly) return trait;
	return {
		[remoteKey]: trait,
	};
};

const useTraitConfig = ({ traitKey = null, options = {} } = {}) => {
	const [{ data, error, loading }, fetchTraitConfig] = useResource(
		options,
		false
	);

	const { setUser } = useIdentity();

	// TODO: add support for updating root level traits later

	useEffect(() => {
		if (!loading) return;

		updateUser({
			setUser,
			key: traitKey,
			dataMapper: () =>
				traitGenerator({
					remoteKey: traitKey,
					status: TRAIT_STATUS.LOADING,
					getDirectly: true,
				}),
		});
	}, [loading, traitKey, setUser]);

	useEffect(() => {
		if (!data) return;

		updateUser({
			setUser,
			key: traitKey,
			dataMapper: () =>
				traitGenerator({
					remoteKey: traitKey,
					status: TRAIT_STATUS.SUCCESS,
					getDirectly: true,
					value: data[traitKey],
				}),
		});
	}, [data, traitKey, setUser]);

	useEffect(() => {
		if (!error) return;

		updateUser({
			setUser,
			key: traitKey,
			dataMapper: () =>
				traitGenerator({
					remoteKey: traitKey,
					status: TRAIT_STATUS.ERROR,
					getDirectly: true,
					value: error,
				}),
		});
	}, [error, traitKey, setUser]);

	return { fetchTraitConfig };
};

const useTrait = () => {
	const { setUser } = useIdentity();

	const { fetchTraitConfig: fetchOspCheckout } = useTraitConfig({
		traitKey: PUBLISHER_TRAIT.IS_OSP_CHECKOUT_ACTIVE,
		options: {
			url: FETCH_USER_OSP_STATUS,
		},
	});

	const initTraits = useCallback(() => {
		if (typeof setUser !== "function") return;
		Object.values(PUBLISHER_TRAIT).forEach((remoteKey) => {
			setUser((user) => ({
				...user,
				publisher: {
					...user.publisher,
					...traitGenerator({
						remoteKey,
						status: TRAIT_STATUS.WAITING,
					}),
				},
			}));
		});
	}, [setUser]);

	const fetchTrait = useCallback(
		({ trait } = {}) => {
			if (!trait)
				return console.error("Please pass a valid trait to fetch");
			switch (trait) {
				case PUBLISHER_TRAIT.IS_OSP_CHECKOUT_ACTIVE:
					fetchOspCheckout();
			}
		},
		[fetchOspCheckout]
	);

	return {
		initTraits,
		fetchTrait,
	};
};
export default useTrait;
