import React, {
	createRef,
	memo,
	useState,
	forwardRef,
	useCallback,
} from "react";
import { useEffect } from "react";
import {
	BRAND_INFO_URL_2,
	FETCH_TAGGED_SUB_CATEGORIES_BLACKLIST_URL,
	SAVE_SUB_CATEGORY_BLACKLIST_URL,
} from "../../conf";
import useResource from "../../hooks/useResource";
import {
	Checkbox,
	Loader,
	ModalSelectListInput,
	QuestionTooltip,
} from "../CoreUI";
import "./sub-category-opt-out-modal-selector.css";

export const SensitiveSubCategoryExclusion = memo(
	({ user, setUser, remoteKey, url, className = "" }) => {
		const [payload, setPayload] = useState(
			user.publisher.auto_exclude_sensitive_subcategories
		);
		const [{ loading, data }, update, reset] = useResource(
			{
				url: url || BRAND_INFO_URL_2,
				data: {
					[remoteKey]: payload,
				},
				method: "PUT",
			},
			false
		);

		const handleChange = useCallback(
			({ target: { checked } }) => {
				if (loading) {
					return;
				}
				setPayload(checked);
				update();
			},
			[loading, update]
		);

		useEffect(() => {
			if (!data) {
				return;
			}

			const remoteData = data?.brand || data;
			const dependantStates = {
				subcategory_blacklist: "",
				email_subcategory_blacklist: "",
				offer_subcategory_blacklist: "",
				blacklist: "",
				email_blacklist: "",
				offer_blacklist: "",
			};
			const newValues = Object.entries(remoteData)
				.filter(([key]) => key in dependantStates)
				.reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

			setUser((user) => ({
				...user,
				draft: {
					...user.draft,
					...newValues,
				},
				publisher: {
					...user.publisher,
					[remoteKey]:
						remoteKey in remoteData
							? remoteData[remoteKey]
							: user?.publisher?.[remoteKey],
					...newValues,
				},
			}));
			reset();
		}, [data, setUser, reset, remoteKey]);

		return (
			<section
				className={`sensitive-sub-category-exclusion ${className}`}
			>
				{loading ? (
					<Loader small2 />
				) : (
					<Checkbox
						checked={user.publisher[remoteKey]}
						onChange={handleChange}
					/>
				)}
				Auto-exclude sensitive subcategories
				<QuestionTooltip>
					We define sensitive subcategories as that some brands may
					view as controversial such as alcohol, CBD related products,
					and others. By enabling auto-exclude we automatically add
					all the subcategories that match this criteria to your
					exclusion list.
				</QuestionTooltip>
			</section>
		);
	}
);

const SubCategoryOptOutModalSelector = memo(
	forwardRef(
		(
			{
				user,
				setUser,
				focusFunction,
				parentRef,
				onboarding = false,
				onClose,
				customStepProps,
				...props
			},
			ref
		) => {
			const [stepProps, setStepProps] = useState([
				{
					label: ({ value }) => (
						<>
							{value.length} Subcategories{" "}
							<span style={{ "--highlight": "var(--red)" }}>
								Excluded
							</span>
							:
						</>
					),
					inlineName: "subcategories",
					inputProps: {
						ref: parentRef || createRef(),
						onMouseEnter: focusFunction,
						"data-name": "subcategoryOptOpt",
						"data-customid": 0,
					},
					remoteKey: "subcategory_blacklist",
					emptyPlaceholder: "No subcategories excluded",
					disabledText: "Please select your categories first",

					title: "Subcategory Exclusions",
					hideAsideTitle: true,
					generateAsideTitles: ({ option, idx, list }) => {
						if (
							idx === 0 ||
							list[idx].category_id !== list[idx - 1].category_id
						) {
							const { category_name } = option;
							return category_name;
						}
					},

					badgeModifier: ({ source, option, idx, list }) => {
						const { name, category_name } = option;
						if (
							source ||
							!Array.isArray(list) ||
							list.length === 0
						) {
							return name;
						}
						let count = 0;
						for (const { name: otherName } of list) {
							if (
								otherName.toLowerCase() === name.toLowerCase()
							) {
								count += 1;
							}
						}
						if (count > 1) {
							return (
								<>
									<b>{category_name}:</b> {name}
								</>
							);
						}
						return name;
					},

					mainTitle: "Exclude the following subcategories",
					buttonText: "Done! This all looks correct",
					badgeColor: { red: true, icon: "minus" },
					mainFooter: ({ value }) => {
						if (!Array.isArray(value)) {
							return null;
						}
						const num = value.length;
						return (
							<>
								{num > 0 && (
									<div
										style={{
											color: "var(--text)",
											fontWeight: 400,
										}}
									>
										Important — You are excluding{" "}
										<span style={{ color: "var(--red)" }}>
											{" "}
											{num}{" "}
											{num > 1
												? "subcategories"
												: "subcategory"}
										</span>
									</div>
								)}
								<br />
								These exclusions are one-way and pertain to the
								types of brands that can show up on your order
								confirmation page. Excluding a subcategory does
								not mean you will not appear on those sites.
							</>
						);
					},
					// sorter: ({ a, b, source }) => {
					// 	const { name: aName } = a;
					// 	const { name: bName } = b;
					// 	return aName
					// 		.toLowerCase()
					// 		.localeCompare(bName.toLowerCase());
					// },

					searchable: true,
					defaultFilter: "PRIMARY_CATEGORIES",
					filters: {
						PRIMARY_CATEGORIES: {
							name: "By primary category",
							filter: ({ option: { primary } }) => primary,
						},
						MY_CATEGORIES: {
							name: "By my categories",
							filter: ({ option: { current_categories } }) =>
								current_categories,
						},
						ALL: {
							name: "Show all categories",
							filter: () => true,
						},
					},

					requestValueMapper: ({ value }) =>
						value.map((subCategory) => subCategory.remote_id),
					split: true,
					stepped: false,
					required: false,

					fetchUrl: FETCH_TAGGED_SUB_CATEGORIES_BLACKLIST_URL,
					saveUrl: SAVE_SUB_CATEGORY_BLACKLIST_URL,
					saveRequestMethod: "PUT",
					requestBody: {},
					dependantStates: {
						blacklist: "",
						auto_exclude_sensitive_subcategories: "",
					},
					bypassDraftDependantStates: {
						auto_exclude_sensitive_subcategories: "",
					},
					exitWarning: true,

					onSave: onClose,
					...(customStepProps?.[0] || {}),
					inputWrapperProps: {
						card: true,
						children: ({ user, setUser }) => (
							<SensitiveSubCategoryExclusion
								user={user}
								setUser={setUser}
								remoteKey={
									customStepProps?.[0]
										.sensitiveSubcategoriesRemoteKey ||
									"auto_exclude_sensitive_subcategories"
								}
							/>
						),
						actionText: onboarding
							? "Not quite right? That’s okay. Lets make some changes now based on your preferences."
							: "Not quite right? That’s okay. Lets make some changes now based on your preferences. ",
						...(customStepProps?.[0]?.inputWrapperProps || {}),
					},
				},
			]);

			return (
				<>
					<ModalSelectListInput
						stepProps={stepProps}
						user={user}
						setUser={setUser}
						setStepProps={setStepProps}
						onboarding={onboarding}
						{...props}
					/>
				</>
			);
		}
	)
);

export default SubCategoryOptOutModalSelector;
