import React, { useState } from "react";
import {
	Button,
	SPACING,
	ExternalLinkIcon,
	Text,
	FONT_SIZE,
	PaddedContainer,
	RadioElement,
	SettingsIcon,
} from "@disco/disco_core";
import getPlatformList from "../../utils/getPlatformList";
import { PLATFORM_IDS } from "../../conf";

export default function ({ handleSubmit, platform, setPlatform }) {
	const platformList = getPlatformList();

	return (
		<>
			<Text
				size={FONT_SIZE.SUB_TITLE}
				marginTop={SPACING.REGULAR}
				marginBottom={SPACING.REGULAR}
				className="platforms-view-subheading"
				color="mid"
			>
				{"Select your platform"}
			</Text>
			<PaddedContainer className="platforms-view-platforms">
				{platformList.map((platformData) => (
					<RadioElement
						key={platformData.id}
						className={`platforms-view-platform ${
							platform === platformData.id
								? "platforms-view-platform-selected"
								: ""
						}`}
						selected={platform === platformData.id}
						onClick={() => setPlatform(platformData.id)}
						border={true}
						data-testid={`select-platform-radio-element-${platformData.id}`}
					>
						{platformData.id === PLATFORM_IDS.other ? (
							<>
								<div className="another-platform-icon">
									<SettingsIcon />
								</div>

								<div>
									<Text
										color="default"
										size={FONT_SIZE.SUB_TITLE}
										thick={true}
									>
										Another platform
									</Text>

									<Text>
										Compatible with custom commerce
										platforms
									</Text>
								</div>
							</>
						) : (
							<img
								src={platformData.img}
								alt={platformData.name}
							/>
						)}

						<span className="platforms-view-btn-panel">
							{platform === platformData.id && (
								<Button
									className="platforms-view-external-btn"
									data-testid={`select-platform-btn-${platformData.id}`}
									onClick={() =>
										handleSubmit(platformData.id)
									}
								>
									{platformData.id === PLATFORM_IDS.shopify ||
									platformData.id ===
										PLATFORM_IDS.bigcommerce ? (
										<>
											<span>
												Install Disco on Shopify
											</span>
											<ExternalLinkIcon />
										</>
									) : (
										"Continue"
									)}
								</Button>
							)}
						</span>
					</RadioElement>
				))}
			</PaddedContainer>
		</>
	);
}
