import React, { memo, useCallback, useMemo, useState } from "react";
import {
	PLATFORM_ONBOARDING_CLICK_BACK_CATEGORIES,
	PLATFORM_ONBOARDING_CLICK_CONTINUE_CATEGORIES,
	PLATFORM_ONBOARDING_CLICK_SAVE_LOG_OUT_CATEGORIES,
	PLATFORM_ONBOARDING_PAGE_VIEW_CATEGORIES,
} from "../../events";
import useIdentity from "../../hooks/useIdentity";
import BrandSetupModalSelector from "../BrandSetupModalSelector";
import OnboardingStep from "./OnboardingStep";
import { ONBOARDING_SETUP_STATUSES } from "../../conf";

import "./categorisation-step.css";

const CategorizationStep = memo(() => {
	// const { ping: pingNext } = usePing({ key: PING_TYPES.MODAL_SELECT_NEXT });
	const [next, setNext] = useState(false);
	const { user, setUser } = useIdentity();

	const handleNextStep = useCallback(() => {
		setUser((user) => ({ ...user, firstTime: true }));
		setNext(true);
	}, [setUser]);

	const nextDisabled = useMemo(() => {
		return !(
			Array.isArray(user.publisher.categories) &&
			user.publisher.categories.length > 0 &&
			Array.isArray(user.publisher.subcategories) &&
			user.publisher.subcategories.length > 0
		);
	}, [user]);

	return (
		<OnboardingStep
			user={user}
			setUser={setUser}
			heading={"Define your brand categories"}
			subText={
				"Choose the categories that define your brand and help us find the best partners for you."
			}
			parseErrors={true}
			status={ONBOARDING_SETUP_STATUSES.CATEGORY_SETUP}
			aside={{
				heading: "Categories",
				imageUp: true,
				logo: true,
			}}
			nextText={"Continue"}
			className="categorisation-step"
			onNext={handleNextStep}
			showNext
			showBack
			nextDisabled={nextDisabled}
			next={next}
			setNext={setNext}
			noPad
			nextEvent={PLATFORM_ONBOARDING_CLICK_CONTINUE_CATEGORIES}
			logoutEvent={PLATFORM_ONBOARDING_CLICK_SAVE_LOG_OUT_CATEGORIES}
			backEvent={PLATFORM_ONBOARDING_CLICK_BACK_CATEGORIES}
			viewEvent={PLATFORM_ONBOARDING_PAGE_VIEW_CATEGORIES}
		>
			<BrandSetupModalSelector
				user={user}
				setUser={setUser}
				interStepBack={false}
				className="brand-setup-modal-selector"
				designSystem
				onboarding
			/>
		</OnboardingStep>
	);
});

export default CategorizationStep;
