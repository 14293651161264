import axios from "axios";
import React, { createRef, memo, useCallback, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
	Button,
	FormStatus,
	GuidedForm,
	Loader,
} from "../../components/CoreUI";
import { PASSWORD_RESET_URL } from "../../conf";
import hand from "../../img/hand.png";
import parseError from "../../utils/parseError";
import OnboardingStep from "./OnboardingStep";
import "./reset-step.css";

const ResetStep = memo(() => {
	const [searchParams] = useSearchParams();
	const defaultValueEmail = searchParams.get("email") || "";
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState("");
	const [success, setSuccess] = useState(false);
	const [data, setData] = useState({
		email: {
			tooltip: {
				heading: "Enter your email address",
				text: defaultValueEmail
					? "Enter the email linked to your Disco account, and we’ll send you instructions to reset your password."
					: "Enter the email address that you used while registering for Disco",
			},
			props: {
				value: defaultValueEmail,
				placeholder: "Email",
				type: "email",
				label: "Your Email",
				ref: createRef(),
			},
		},
	});
	const navigate = useNavigate();
	const resetPassword = useCallback(async () => {
		const email = data.email.props.value;
		if (loading) {
			return;
		}

		setLoading(true);
		let resp;
		try {
			resp = await axios.post(PASSWORD_RESET_URL, {
				email,
			});
			setLoading(false);
			if (resp && resp.data) {
				setSuccess(true);
			}
		} catch (error) {
			setLoading(false);
			setSuccess(false);
			return setError(parseError(error, true));
		}
	}, [data.email.props.value, loading]);

	const handleReset = useCallback(
		(e) => {
			if (e) {
				e.preventDefault();
			}
			const email = data.email.props.value;

			if (email.trim().length < 1) {
				return setError("Your email address is required");
			}
			setError(false);
			resetPassword();
		},
		[data.email.props.value, resetPassword]
	);

	const handleRedirect = useCallback(() => {
		navigate({
			pathname: "/login",
		});
	}, [navigate]);

	// useEffect(() => {
	// triggerIntercom();
	// }, []);

	return (
		<>
			<OnboardingStep
				heading="Reset your password"
				subText={"Let us help you get back into your account"}
				aside={{
					heading: "Howdy.",
					image: hand,
					logo: true,
					imageWidth: "95%",
					imageUp: true,
				}}
				showLogout={false}
				showNext={false}
				steps={null}
				className="reset-step"
				showBack={false}
				noPad
			>
				<GuidedForm
					data={data}
					setData={setData}
					initialHighlight="email"
				>
					{!loading && !success && (
						<Button large light onClick={handleReset} theme>
							Reset My Password
						</Button>
					)}
					{error && <FormStatus type="error">{error}</FormStatus>}
					{success && (
						<FormStatus type="success">
							Success! We've sent you a link to your email address
						</FormStatus>
					)}
					{loading && <Loader />}
					<span onClick={handleRedirect} className="reset-step-back">
						Take me back to the login page
					</span>
				</GuidedForm>
			</OnboardingStep>
		</>
	);
});

export default ResetStep;
