import React, { useEffect } from "react";
import {
	Button,
	Checkbox,
	FONT_COLOR,
	FONT_SIZE,
	LabeledInput,
	Loader,
	PaddedContainer,
	SPACING,
	STATUS,
	StatusLabel,
	Text,
	useResource,
} from "@disco/disco_core";
import validateEmail from "../../utils/validEmail";
import { GET, GETAll } from "../../utils/GET";
import useTrack from "../../hooks/useTrack";
import {
	PLATFORM_ONBOARDING_CLICK_ANY_ACCOUNT_FIELD,
	PLATFORM_ONBOARDING_CLICK_ON_REGISTER_INITIATE,
	PLATFORM_ONBOARDING_PAGE_VIEW_CREATE_ACCOUNT,
} from "../../events";

import tokenStorage from "../../utils/tokenStorage";
import {
	SAVE_LEAD_URL,
	PRIVACY_POLICY_URL,
	TOS_URL,
	REGISTER_URL,
	IS_TEST_ENVIRONMENT,
} from "../../conf";
import parseError from "../../utils/parseError";
import { useForm, FormProvider } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { isAxiosError } from "axios";
import EmailExists from "./EmailExists";
import fromShopify from "../../utils/fromShopify";

const utmParams = [
	"utm_medium",
	"utm_source",
	"utm_campaign",
	"utm_content",
	"utm_term",
];

// we should probably use useSearchParams for this...
const searchParams = GETAll();

const utmPayload = Object.entries(searchParams).reduce(
	(acc, [key, val]) =>
		utmParams.includes(key) ? { ...acc, [key]: val } : acc,
	{}
);

let demo = false; // No state as if => true, stay true

export default function RegistrationForm({ setAuth, platformId }) {
	const track = useTrack(PLATFORM_ONBOARDING_PAGE_VIEW_CREATE_ACCOUNT);

	const formMethods = useForm({
		mode: "onTouched",
		defaultValues: {
			full_name: "",
			email: GET("email") ?? GET("signup_email") ?? "",
			brand_name: GET("brand") ?? "",
			hero_url: GET("shop") ?? "",
			password: "",
			existing: GET("existing") === "true",
		},
	});

	const { register, formState, setError, getValues } = formMethods;

	const formValues = getValues();

	const registerUserPayload = {
		...formValues,
		password2: formValues.password,
		privacy: formValues.tos,
		demo,
		via: GET("via"),
		platforms: [fromShopify() ? 2 : platformId],
	};

	if (searchParams.shop && searchParams.code) {
		registerUserPayload.shopify_data = searchParams;
	}

	if (window.Rewardful && window.Rewardful.referral) {
		registerUserPayload.referral = window.Rewardful.referral;
	}

	const [{ loading, error, data }, saveUser, resetUser] = useResource(
		{ url: REGISTER_URL, method: "POST", data: registerUserPayload },
		false,
		false
	);

	const saveLeadPayload = {
		...formValues,
		...utmPayload,
		demo,
		privacy: true,
	};

	const [
		{ loading: leadLoading, error: leadError, data: leadData },
		saveLead,
		resetLead,
	] = useResource(
		{ url: SAVE_LEAD_URL, method: "POST", data: saveLeadPayload },
		false,
		false
	);

	const onSubmit = (e) => {
		e.preventDefault();
		track(PLATFORM_ONBOARDING_CLICK_ON_REGISTER_INITIATE, {});

		if (!formState.isValid) return;

		resetUser();
		resetLead();

		if (IS_TEST_ENVIRONMENT) {
			// We do not want to create hubspot leads for a test environment (STAGING | DEVELOPMENT)
			saveUser();
			return;
		}

		saveLead();
	};

	// This is bypassed if a hubspot lead is not created for test environment
	useEffect(() => {
		if (!leadData) return;

		resetLead();

		saveUser();
	}, [leadData, resetLead, saveUser]);

	const existing = formValues.existing;

	useEffect(() => {
		if (error === false) {
			return;
		}

		if (isAxiosError(error) && error.response?.data?.email) {
			if (existing === true) {
				setError("password", {
					message:
						"An account with this email already exists, but the password you entered doesn’t match. Please try again or click 'Forgot your password?' to reset it.",
				});
			} else {
				setError("email", {
					message: <EmailExists />,
				});

				resetUser();
			}

			return;
		}

		setError("root.serverError", error);
	}, [error, existing, setError, resetUser]);

	useEffect(() => {
		if (!data) {
			return;
		}

		resetUser();

		tokenStorage.set({
			accessToken: data.access,
			refreshToken: data.refresh,
			publisher: data.publisher_id,
		});

		setAuth(2);
	}, [data, setAuth, resetUser]);

	useEffect(() => {
		if (leadError) {
			setError("root.serverError", leadError);
		}
	}, [leadError, setError]);

	const CustomErrorMessage = ({ name }) => (
		<ErrorMessage
			errors={formState.errors}
			name={name}
			render={({ message }) => (
				<Text marginTop={SPACING.TINY} className="error-text">
					{message}
				</Text>
			)}
		/>
	);

	return (
		<FormProvider {...formMethods}>
			<form className="registration-form" onSubmit={onSubmit}>
				<div className="registration-form-fields">
					<div>
						<LabeledInput
							{...register("full_name", {
								required: "Please enter your full name",
								validate: (val) =>
									val.split(" ").length > 1 &&
									val
										.split(" ")
										.every((str) => str.length > 0)
										? true
										: "Please enter both first and last names",
							})}
							type="text"
							label="Full name"
							placeholder="Your first and last name"
							data-testid="register-name"
							className={
								formState.errors["full_name"] != null
									? "has-error"
									: ""
							}
							onFocus={({ currentTarget }) =>
								track(
									PLATFORM_ONBOARDING_CLICK_ANY_ACCOUNT_FIELD,
									{
										field: "full-name",
										value: currentTarget.value,
									}
								)
							}
						/>

						<CustomErrorMessage name="full_name" />
					</div>

					<div>
						<LabeledInput
							{...register("email", {
								required: "Please enter your email",
								validate: (val) =>
									validateEmail(val)
										? true
										: "Please enter a valid email address",
							})}
							type="email"
							label="Email"
							placeholder="you@example.com"
							data-testid="register-email"
							className={
								formState.errors["email"] != null
									? "has-error"
									: ""
							}
							onFocus={({ currentTarget }) =>
								track(
									PLATFORM_ONBOARDING_CLICK_ANY_ACCOUNT_FIELD,
									{
										field: "email",
										value: currentTarget.value,
									}
								)
							}
						/>

						<CustomErrorMessage name="email" />
					</div>

					<div>
						<LabeledInput
							{...register("brand_name", {
								required: "Please enter your brand name",
							})}
							type="text"
							label="Brand name"
							placeholder="Your brand name"
							data-testid="register-brand"
							className={
								formState.errors["brand_name"] != null
									? "has-error"
									: ""
							}
							onFocus={({ currentTarget }) =>
								track(
									PLATFORM_ONBOARDING_CLICK_ANY_ACCOUNT_FIELD,
									{
										field: "brand_name",
										value: currentTarget.value,
									}
								)
							}
						/>

						<CustomErrorMessage name="brand_name" />
					</div>

					<div>
						<LabeledInput
							{...register("hero_url", {
								required: "Please enter your website URL",
							})}
							type="text"
							label="Website"
							placeholder="https://www.yourshopurl.com"
							data-testid="register-url"
							className={
								formState.errors["hero_url"] != null
									? "has-error"
									: ""
							}
							onFocus={({ currentTarget }) =>
								track(
									PLATFORM_ONBOARDING_CLICK_ANY_ACCOUNT_FIELD,
									{
										field: "hero_url",
										value: currentTarget.value,
									}
								)
							}
						/>

						<CustomErrorMessage name="hero_url" />
					</div>

					<div className="col-span-2">
						<LabeledInput
							{...register("password", {
								required: "Please enter your password",
								pattern: {
									// eslint-disable-next-line no-useless-escape
									value: /[~`!#$@%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/g,
									message:
										"Your password must contain one special character",
								},
								minLength: {
									value: 8,
									message:
										"Your password must have a minimum length of 8 characters",
								},
								validate: {
									oneUppercase: (value) =>
										value.toLowerCase() !== value
											? true
											: "Your password must contain one uppercase letter",
									oneLowercase: (value) =>
										value?.toUpperCase() !== value
											? true
											: "Your password must contain one lowercase letter",
								},
							})}
							type="password"
							label="Password"
							placeholder="Create a password (min 8 characters)"
							data-testid="register-password"
							tooltip={{
								children: (
									<PaddedContainer className="password-tooltip">
										<Text thick>
											Password must contain:
										</Text>

										<ul>
											<li>One uppercase letter</li>
											<li>One lowercase letter</li>
											<li>One special character</li>
											<li>Min 8 characters</li>
										</ul>
									</PaddedContainer>
								),
							}}
							className={
								formState.errors["password"] != null
									? "has-error"
									: ""
							}
							onFocus={({ currentTarget }) =>
								track(
									PLATFORM_ONBOARDING_CLICK_ANY_ACCOUNT_FIELD,
									{
										field: "password",
										value: currentTarget.value,
									}
								)
							}
						/>

						<CustomErrorMessage name="password" />
					</div>
				</div>

				<label className="register-view-tos">
					{/* we cannot use CheckboxElement here bc it doesn't relegate props down to the Checkbox component */}
					<PaddedContainer
						vPadding={SPACING.REGULAR}
						hPadding={SPACING.REGULAR}
						className="checkbox-element"
					>
						<Checkbox {...register("tos", { required: true })} />

						<PaddedContainer
							marginLeft={SPACING.REGULAR}
							className="checkbox-element-content"
						>
							<Text
								size={FONT_SIZE.LABEL}
								color={FONT_COLOR.MID}
								thin
								className="checkbox-element-children"
							>
								I agree to the
								<a
									href={TOS_URL}
									target="_blank"
									rel="noopener noreferrer"
								>
									Terms of Service
								</a>{" "}
								and{" "}
								<a
									href={PRIVACY_POLICY_URL}
									target="_blank"
									rel="noopener noreferrer"
								>
									Privacy Policy
								</a>
							</Text>
						</PaddedContainer>
					</PaddedContainer>
				</label>

				<section className="register-view-footer">
					{loading || leadLoading ? (
						<Loader marginTop={SPACING.REGULAR} />
					) : (
						<Button
							data-testid="register-submit"
							className="register-submit"
							marginTop={SPACING.REGULAR}
							type="submit"
							disabled={
								!formState.isValid || formState.isSubmitting
							}
						>
							Create an account
						</Button>
					)}
				</section>

				{formState.errors.root?.serverError && (
					<PaddedContainer vPadding={SPACING.REGULAR}>
						<StatusLabel type={STATUS.ERROR}>
							{parseError(formState.errors.root.serverError)}
						</StatusLabel>
					</PaddedContainer>
				)}
			</form>
		</FormProvider>
	);
}
