import { ONBOARDING_SETUP_STATUSES } from "../conf";
import isShopifyShop from "../utils/isShopifyShop";
import useIdentity from "./useIdentity";

const ONBOARDING_STEPS = [
	{ name: "Platform", status: ONBOARDING_SETUP_STATUSES.PLATFORM_SELECTION },
	{
		name: "Install",
		status: ONBOARDING_SETUP_STATUSES.REGISTRATION_SUCCESS,
		isVisible: (user) => isShopifyShop(user),
	},
	{ name: "Profile", status: ONBOARDING_SETUP_STATUSES.PROFILE_SETUP },
	{ name: "Categories", status: ONBOARDING_SETUP_STATUSES.CATEGORY_SETUP },
];

const useOnboardingSteps = () => {
	const { user } = useIdentity();

	return ONBOARDING_STEPS.filter(
		({ isVisible = () => true }) => user && isVisible(user)
	);
};

export default useOnboardingSteps;
