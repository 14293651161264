import React, { memo } from "react";
import { BiCheck } from "react-icons/bi";
import {
	PLATFORM_ONBOARDING_CLICK_INSTALL_BACK,
	PLATFORM_ONBOARDING_CLICK_INSTALL_CONTINUE,
	PLATFORM_ONBOARDING_CLICK_INSTALL_SAVE_LOG_OUT,
	PLATFORM_ONBOARDING_PAGE_VIEW_INSTALL_SUCCESS,
	PLATFORM_ONBOARDING_PAGE_VIEW_OTHER_PLATFORM_INSTALL,
} from "../../events";
import useIdentity from "../../hooks/useIdentity";
import isOtherShop from "../../utils/isOtherShop";
import OnboardingStep from "./OnboardingStep";
import "./install-step.css";
import { ONBOARDING_SETUP_STATUSES } from "../../conf";

const features = [
	"Find out where your customers shop",
	"Better understand their shopping habits",
	"Lower your CAC and CPA",
	"Increase your average order value",
	"Identify the right brands to partner with",
];

const stepData = {
	[false]: {
		heading: "Success!",
		subText: "With your platform connected to Disco, you're now able to:",
	},
	[true]: {
		heading: "You can connect your platform anytime from the settings menu",
		subText: "When your platform is connected to Disco, you'll be able to:",
	},
};

const InstallStep = memo(() => {
	const { user } = useIdentity();
	return (
		<OnboardingStep
			{...stepData[isOtherShop(user)]}
			aside={{
				heading: "Platform",
				logo: true,
			}}
			className={`install-step`}
			nextText="Continue"
			status={ONBOARDING_SETUP_STATUSES.REGISTRATION_SUCCESS}
			showBack={false}
			nextEvent={PLATFORM_ONBOARDING_CLICK_INSTALL_CONTINUE}
			logoutEvent={PLATFORM_ONBOARDING_CLICK_INSTALL_SAVE_LOG_OUT}
			backEvent={PLATFORM_ONBOARDING_CLICK_INSTALL_BACK}
			viewEvent={
				isOtherShop(user)
					? PLATFORM_ONBOARDING_PAGE_VIEW_OTHER_PLATFORM_INSTALL
					: PLATFORM_ONBOARDING_PAGE_VIEW_INSTALL_SUCCESS
			}
		>
			<section className="install-step-features">
				{features.map((feature, id) => (
					<section className="install-step-feature" key={id}>
						<span>
							<BiCheck />
						</span>
						{feature}
					</section>
				))}
			</section>
		</OnboardingStep>
	);
});

export default InstallStep;
