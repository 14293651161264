import {
	Asset,
	Button,
	Card,
	ChevronDownIcon,
	ExternalLinkIcon,
	FONT_COLOR,
	FONT_SIZE,
	PaddedContainer,
	SPACING,
	Text,
	TitleRow,
	anim,
} from "@disco/disco_core";
import React, { useState } from "react";
import parse from "html-react-parser";

import "./extension-setup-instructions.css";
import windowRedirect from "../../utils/windowRedirect";
import useIdentity from "../../hooks/useIdentity";
import { SHOPIFY_ADMIN_CHECKOUT_SETTINGS_URL } from "../../conf";

const EXTENSION_SETUP_INSTRUCTION_LIST = [
	"Click the <b>'Add Disco to Shopify button</b> below to be taken to your Shopify admin Checkout page.",
	"In the <b>Customize your checkout</b> section, click <b>Customize.</b>",
	"You will need to update both the <b>Thank you page</b> and <b>Order status page.</b> Select each from the top menu bar.",
	"For each page, click <b>Add app block</b> in the lower left of the screen.",
	"In the <b>Apps available for this page section,</b> click the <b>Disco app block</b>. This will automatically add DiscoFeed in our recommended placement for that page.",
	"Click <b>Save.</b> Then repeat steps 4 & 5 for the next page.",
];

const ExtensionSetupInstructionList = ({ ...rest }) => {
	const { user } = useIdentity();

	const handleBtnClick = () => {
		windowRedirect(
			SHOPIFY_ADMIN_CHECKOUT_SETTINGS_URL({
				shopifyShop: user.shopify_shop,
			})
		);
	};

	return (
		<PaddedContainer {...rest}>
			<Text size={FONT_SIZE.LG_BODY} thick>
				Setup Guide
			</Text>
			<Text
				marginTop={SPACING.TINY}
				size={FONT_SIZE.BODY}
				color={FONT_COLOR.DARK}
			>
				{EXTENSION_SETUP_INSTRUCTION_LIST.map((instruction, idx) => (
					<Text key={idx} marginTop={SPACING.MICRO}>
						{idx + 1}. {parse(instruction)}
					</Text>
				))}
			</Text>
			<Button
				marginTop={SPACING.REGULAR}
				onClick={handleBtnClick}
				data-testid="extension-setup-instructions-redirect-btn"
			>
				Add Disco to Shopify
				<Asset marginLeft={SPACING.TINY}>
					<ExternalLinkIcon />
				</Asset>
			</Button>
		</PaddedContainer>
	);
};

const ExtensionSetupInstructions = ({ ...rest }) => {
	const [open, setOpen] = useState(true);
	return (
		<Card
			vPadding={SPACING.MEDIUM}
			hPadding={SPACING.MEDIUM}
			data-testid="extension-setup-instructions"
			{...rest}
		>
			<TitleRow
				title="Setup DiscoFeed on Shopify Checkout Pages"
				headerChildren={
					<Asset
						marginLeft={"auto"}
						size={28}
						clickable
						data-testid="extension-setup-instructions-collapse-btn"
						onClick={() => setOpen((open) => !open)}
					>
						<ChevronDownIcon />
					</Asset>
				}
				subtitle="After upgrading to Checkout Extensibility, DiscoFeed needs to be reconfigured"
			>
				{open && (
					<ExtensionSetupInstructionList
						motionElement
						variants={anim.variantFactory({ y: -16, opacity: 0.5 })}
						marginTop={SPACING.REGULAR}
						initial="initial"
						animate="animate"
					/>
				)}
			</TitleRow>
		</Card>
	);
};

export default ExtensionSetupInstructions;
